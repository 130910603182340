import React from "react";

const Invest_Pixclick_Farms = () => {
  return (
    <>
      <body className="FONT_1_POPPINS TC_BG_4 py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-12 mt-5 col-lg-6 text-center">
              <h1 className="TC_COL_1 fw-bold fw-500">
                Invest in <span className="TC_COL_2">Pixclick Farms,</span>{" "}
                Invest in a <span className="TC_COL_2">Greener Future.</span>
              </h1>
              <p className="TC_COL_1">
                Take a step towards healthier planet. Join us.
              </p>
            </div>
            <div className="col-sm-12 mt-5 col-lg-3 text-center">
              <span className="TC_COL_1">Start Your Eco-Investment</span>

              <a href="/" className="text-decoration-none">
                <div className="p-3">
                  <span
                    href="/"
                    className="TC_BG_2 HEAD_INVEST_NOW_BTN p-3 rounded-3 fw-bold"
                  >
                    Invest Now <i class="bi bi-arrow-right"></i>
                  </span>
                </div>
              </a>
            </div>
            <div className="col-sm-12 mt-5 col-lg-3 text-center">
              <span className="TC_COL_1">Call Us Now</span>

              <a href="/" className="text-decoration-none">
                <div className="p-3">
                  <span
                    href="/"
                    className="TC_BG_2 HEAD_INVEST_NOW_BTN p-3 rounded-3 fw-bold"
                  >
                    +91 9876543210 <i class="bi bi-arrow-right"></i>
                  </span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </body>
    </>
  );
};

export default Invest_Pixclick_Farms;
