import React, { useState } from 'react'
import Main_Logo from "../../../Components/Header/newlogofarms.png";
import './Otp.css'
import { useNavigate } from 'react-router-dom';
import { consumerVerifyOtp } from '../../../api/login';
import { saveToken, getemailId } from "../../../Utils/Storage";
import { isAuthenticated } from '../../../Utils/Auth';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";


const OTP = () => {

  //let emailId = getemailId();
 //console.log('emails', emailId)
 //const decryptedEmailIds = getemailId();
 //console.log('decryptedEmailIdss', decryptedEmailIds)
 const location = useLocation();
 const userEmail = location.state.email;
 console.log('sendemails', userEmail)
 const  initialStateInputs = {
  email: userEmail,
    otp: "",
  };
const initialStateInputsErrors ={
  otp: { required: false},
}
const [inputs, setInputs] = useState(initialStateInputs);
const [errors, setErrors] = useState(initialStateInputsErrors);
const navigate = useNavigate();
const [submitted, setSubmitted] = useState(false);
const handleValidation = (data) => {
  const error = { ...initialStateInputsErrors };
 
  if (data.otp === "") {
    error.otp.required = true;
  }
 
  return error;
};

const handleInputs = (event) => {
  const { name, value } = event.target;
  setInputs({ ...inputs, [name]: value });

  if (submitted) {
    const newError = handleValidation({ ...inputs, [name]: value });
    setErrors(newError);
  }
};
const handleSubmit = (event) => {
  event.preventDefault();
  const newError = handleValidation(inputs);
  setErrors(newError);
  setSubmitted(true);

  if (!newError.otp.required) {
    const data = { email:userEmail, otp: inputs.otp };
    consumerVerifyOtp(data)
      .then((res) => {
        console.log("This is API response", res);
        if (res?.data?.success) {
          const token = res?.data?.result?.token;
          let sellerId = res?.data?.result?.SellerDetails?._id;
        let data = {
          token: token, sellerId: sellerId 
        }
        saveToken(data);
        if (isAuthenticated()) {
          console.log("Navigating to /OTP");
          navigate("/Login");
        }
        toast.success(res?.data?.message);
      }
       toast.error(res?.data?.message);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }
};

// const resendOtp = () => {
//   const data = { email: inputs?.email };
//   resendOTP(data)
//     .then((res) => {
//       toast.success(res?.data?.message);
//     })
//     .catch((err) => {
//       toast.error(err?.response?.data?.message);
//     });
// };

  return (
    <>
     
      <div className="bg_otp ">
        <div className="container  py-5">
          <div className="d-flex justify-content-center  mt-5">
            <form  onSubmit={handleSubmit} className="bg-light px-5 py-5 rounded-5">
           
            <h3 className=" px-3 py-2 rounded-2 text-center  fw-bold ">
                <img src={Main_Logo} className="img-fluid LOGO_SIZE_ADJUST11 " alt="" />
                <br />
              </h3>
                <br />
             
              <h1 className=" px-3 py-2 rounded-2 text-center  fw-bold">
                OTP Verfication
              </h1>
              {/* ........................................................................... */}
              {/* input fields  */}
              <label for="OTP" className="text_color_heading fw-bold mt-4">Enter OTP <span className="text-danger">*</span></label>
              <br />
              <input
                type="number"
                id="phone"
                name="otp"
                placeholder="Enter a OTP"
                className="rounded-3 p-2 mt-2 form-control"
                onChange={handleInputs}
                
              />
              <br />

              <div className="mt-4 d-grid  text-center rounded-1 ">
                <button type="submit" class="btn btn-success px-5 p-2  text-white  fw-bold rounded-5"
          
                 
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default OTP