import "./Ourinvester.css";
import React, { useState, useEffect } from "react";
import { FaLocationDot } from "react-icons/fa6";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { getCreatePlanApi } from "../../../../api/createplan";

const OurInverster = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  const [getPlan, setGetPlan] = useState([]);

  const handleSubmit = async () => {
    try {
      const res = await getCreatePlanApi();
      console.log("create plan", res);
      setGetPlan(res?.data?.result);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);
  return (
    <div className="container mt-5 pt-5">
      <div className="row">
        <div className="col-lg-4 col-12">
          <h3 className="headingcss712">
            A Word From Our <span className="text-warning">Investers</span>{" "}
          </h3>
        </div>
        <div className="col-lg-1"></div>
        <div className="col-lg-7 col-12">
          <Slider {...settings}>
            {getPlan
              .slice()
              .reverse()
              .map((item) => (
                <div key={item.id} className="col-lg-12 col-12">
                  <div
                    className=" p-3 shadow d-flex-column align-items-stretch mb-5    rounded "
                    style={{ margin: "0 10px", height: "91%", width: "100%" }}
                  >
                    <div className="card-body">
                      <div className="position-relative">
                        <img
                          src={item?.imageUrl}
                          alt={item?.imageUrl}
                          className="img-fluid"
                          style={{ width: "100%", height: "200px" }}
                        />
                        <div className="text-right position-absolute top-0 end-0 px-2 py-0 BG_BLUR">
                          <p className="text-white fw-bold mt-3">
                            {item.startDate}
                          </p>
                        </div>
                        <div className="text-center position-absolute  px-3 py-2 bottom-0 BG_BLUR">
                          <span className="text-white fw-bold">
                            {item.name}
                          </span>
                          <br />
                          <small className="text-white fw-bold d-flex align-items-center justify-content-center gap-1">
                            <FaLocationDot />
                            {item.location}
                          </small>
                        </div>
                      </div>
                      <div className="mt-3 ">
                        <div>
                          <div className="d-flex justify-content-between pt-3">
                            <span className="fw-bold">Tenure</span>
                            <p>{item.tenure}</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <span className="fw-bold">Min.Investment</span>
                            <p>₹ {item.minInvesment}</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <span className="fw-bold">Total Investment</span>
                            <p> ₹{item.totalInvesment}</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <span className="fw-bold">Expected Revenue</span>
                            <p> {item.expectedRevenue}%</p>
                          </div>
                        </div>

                        <progress
                          className="w-100"
                          style={{ color: "green" }}
                          value={item.totalInvesment}
                          max="300000"
                        />

                        <div className="row align-items-center">
                          <div className="col-md-3">
                            <img
                              src={item?.imageUrl}
                              alt="tomato"
                              className="img-fluid"
                              style={{ width: "100px", height: "50px" }}
                            />
                          </div>
                          <div className="col-md-9">
                            <h6>{item.name}</h6>
                            <p>
                              {item.description.length > 29
                                ? `${item.description.slice(0, 29)}... `
                                : item.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer border-0 bg-white ">
                      <a
                        href="/enquiry"
                        className="btn btn-success btn-block mb-2"
                      >
                        Invest
                      </a>
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default OurInverster;
