import React, { useState } from "react";
import "./Hire_Worker_List.css";
import Whats_App_Stick from "../../../Components/Common/Whats_App_Stick/Whats_App_Stick";
import Footer from "../../../Components/Footer/Footer";
import Header from "../../../Components/Header/Header";
import { GrUserWorker } from "react-icons/gr";
import { BsPlusCircleFill } from "react-icons/bs";
import { FaCircleMinus } from "react-icons/fa6";
import { HiOutlineCurrencyBangladeshi } from "react-icons/hi2";
const Hire_Worker_List = () => {
  const [count, setCount] = useState(0);

  const increment = () => {
    setCount(count + 1);
  };

  const decrement = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };
  return (
    <div>
      <Header />
      <div className="container-fluid hire_worker_bg py-5">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-12 d-flex justify-content-center  mt-5">
              <h1 className="TC_COL_1 FONT_1_SANS_SERIF TC_BG_3 px-3 py-2 rounded-1 ">
                List of Hire Worker
              </h1>
            </div>
          </div>
          <div className="row  mx-auto mb-3">
            <div class="card mt-5 mx-auto  p-0" style={{ width: "30rem" }}>
              <img
                src="https://images.pexels.com/photos/14025931/pexels-photo-14025931.jpeg?auto=compress&cs=tinysrgb&w=600"
                class="card-img-top img-fluid"
                alt="..."
              />
              <div class="card-body">
                <h5 className="card-title">
                  <p className="fs-4 ">Hire Worker</p>
                </h5>
                <h5 className="d-flex justify-content-between align-items-center">
                  <span className="light_green_color_card fs-6">
                    Service Available
                  </span>
                  <span className="">
                    <span className="fs-4"> ₹ 400</span>{" "}
                    <span className="text-muted fs-6">/ Worker</span>
                  </span>
                </h5>
                <h5 className="d-flex justify-content-between align-items-center">
                  <span className="text-muted fs-5">
                    <span className="text-warning">
                      {" "}
                      <i class="bi bi-star-fill"></i>
                    </span>{" "}
                    4.8(52)
                  </span>
                  <span className="">
                    <button class="btn " type="submit">
                      <span
                        className="fs-4 light_green_color_card "
                        onClick={decrement}
                      >
                        <FaCircleMinus />
                      </span>
                      <span className="px-4 fs-4  ">{count}</span>
                      <span
                        className="fs-4 light_green_color_card  "
                        onClick={increment}
                      >
                        <BsPlusCircleFill />{" "}
                      </span>
                    </button>
                  </span>
                </h5>
                <div>
                  <p className="fs-4 fw-bold ">Information</p>
                  <div class="input-group mb-3">
                    <span
                      class="input-group-text"
                      id="inputGroup-sizing-default"
                    >
                      <i class="bi bi-geo-alt-fill"></i>
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      aria-label="Sizing example input"
                      placeholder="Your location... "
                      aria-describedby="inputGroup-sizing-default"
                    />
                  </div>
                  <div class="input-group mb-3">
                    <span
                      class="input-group-text"
                      id="inputGroup-sizing-default"
                    >
                      <GrUserWorker />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      aria-label="Sizing example input"
                      placeholder="Type of work..."
                      aria-describedby="inputGroup-sizing-default"
                    />
                  </div>
                  <div class="input-group mb-3">
                    <span
                      class="input-group-text"
                      id="inputGroup-sizing-default"
                    >
                      <i class="bi bi-clock-fill"></i>
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      aria-label="Sizing example input"
                      placeholder="Working hours..."
                      aria-describedby="inputGroup-sizing-default"
                    />
                  </div>
                </div>

                <a
                  href="#"
                  className="btn w-100 text-white  text-wrap"
                  id="btn_bg_color"
                >
                  Hire Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Whats_App_Stick />
      <Footer />
    </div>
  );
};

export default Hire_Worker_List;
