import React from "react";
import "./Investment.Harvest.css";
// import headingimg from "../../Assets/heading.png";
import flower from "../../Assets/flowerimg.png";
import tabimg2 from "../../Assets/recursionplant.jpg";
import tabimg3 from "../../Assets/inflationimg.webp";

const InvestmentTab = () => {
  document.addEventListener("DOMContentLoaded", function () {
    let tabs = document.querySelectorAll("#pills-tab button");
    let helloTexts = document.querySelectorAll(".hello-text");
    let currentIndex = 0;

    const showHelloText = (index) => {
      helloTexts.forEach((p, i) => {
        p.style.display = i === index ? "block" : "none";
      });
    };

    const switchTab = () => {
      tabs[currentIndex].classList.remove("active");
      document
        .querySelector(tabs[currentIndex].getAttribute("data-bs-target"))
        .classList.remove("show", "active");
      currentIndex = (currentIndex + 1) % tabs.length;
      tabs[currentIndex].classList.add("active");
      document
        .querySelector(tabs[currentIndex].getAttribute("data-bs-target"))
        .classList.add("show", "active");
      showHelloText(currentIndex);
    };

    showHelloText(currentIndex);
    setInterval(switchTab, 2000); // Change tab every 3 seconds
  });

  return (
    <div className="container mt-5">
      {/*Heading */}
      <div className="row">
        <div className="col-lg-12 text-center">
          <div>
            {/* <img src={headingimg} alt="" className="img-fluid" /> */}
            <h1 className="TC_COL_4 fw-bold">INVEST.Harvest.Reap. </h1>
          </div>
          <div>
            <p className="text-muted mt-2 text-center">
              PixaliveFarms is India’s 1st agri-investment platform that curates{" "}
              institutional quality investments.
            </p>
          </div>
        </div>
      </div>

      {/* Tab */}

      <div class="container mt-5 ">
        <div class="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-5">
            <ul
              class="nav nav-pills flex-column nav-pills"
              id="pills-tab"
              role="tablist"
            >
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link text-black fw-bold active fs-3 position-relative"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  <span>
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/Leaf_icon_15.svg/1003px-Leaf_icon_15.svg.png"
                      alt=""
                      class="img-fluid leavesimgcss"
                    />
                  </span>
                  &nbsp; Great Returns
                </button>
                <p class="hello-text small mx-5">
                  Gain exceptional,tax-free returns <br /> derived from
                  year-long harvests.
                </p>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link text-black fw-bold fs-3 position-relative"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  <span>
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/Leaf_icon_15.svg/1003px-Leaf_icon_15.svg.png"
                      alt=""
                      class="img-fluid leavesimgcss"
                    />
                  </span>
                  &nbsp;Recession-Proof
                </button>
                <p class="hello-text small mx-5">
                  Gain exceptional,tax-free returns <br /> derived from
                  year-long harvests.
                </p>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link text-black fw-bold fs-3 position-relative"
                  id="pills-contact-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-contact"
                  type="button"
                  role="tab"
                  aria-controls="pills-contact"
                  aria-selected="false"
                >
                  <span>
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/Leaf_icon_15.svg/1003px-Leaf_icon_15.svg.png"
                      alt=""
                      class="img-fluid leavesimgcss"
                    />
                  </span>
                  &nbsp;Inflation Hedge
                </button>
                <p class="hello-text small mx-5">
                  Gain exceptional,tax-free returns <br /> derived from
                  year-long harvests.
                </p>
              </li>
            </ul>
          </div>

          <div className="col-lg-5">
            <div class="tab-content  w-100" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <img src={flower} alt="" className="img-fluid" />
              </div>
              <div
                class="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <img src={tabimg2} alt="" className="img-fluid" />
              </div>
              <div
                class="tab-pane fade"
                id="pills-contact"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
              >
                <img src={tabimg3} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestmentTab;
