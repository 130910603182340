
import CryptoJS from "crypto-js";
 
 
export const saveToken =  (data) => {
  localStorage.setItem("token", data?.token);
  localStorage.setItem('loginType', data?.loginType,'pixclickForms')
  if (data?.consumerId) {
    localStorage.setItem('consumerId', (data?.consumerId),'pixclickForms') 
  }
  else if (data?.adminId) {
    localStorage.setItem('adminDetails',CryptoJS.AES.encrypt(data?.adminDetails, "pixclickForms"))
  }
};
 
export const getToken = () => {
  return localStorage.getItem("token");
};
 
export const getconsumerId = () => {
  const consumerId = localStorage.getItem('consumerId');
  
  return consumerId;
};

export const getLoginType = () => {
  const loginType = localStorage.getItem('loginType');
  
  return loginType;
};
export const getAdminId = () => {
  const adminDetails = localStorage.getItem('adminDetails');
  
  return adminDetails;
};
 
 
export const clearStorage = () => {
   localStorage.clear()
};
 