import React, { useState } from "react";
import "./Forget_pass.css";
import Main_Logo from "../../../Components/Header/newlogofarms.png";
import { useNavigate } from "react-router-dom";
import { consumerForgotPassword } from "../../../api/login";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Forget_Password = () => {
  const navigate = useNavigate();

  const [data, setdata] = useState({
    email: "",
    link: "https://farms.pixclick.in/Reset_Password",
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setdata((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const res = await consumerForgotPassword(data);

      if (res.data.success === true) {
        toast.success("Password reset link sent to your EmailId successfully");
      } else {
        toast.error("Mail sending failed");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while sending mail");
    }
  };

  return (
    <>
      <div className="bg_forget">
        <div className="container  py-5">
          <div className="d-flex justify-content-center mt-5">
            <form className="bg-light px-5 py-5 rounded-5 mt-5 ">
              <h3 className=" px-3 py-2 rounded-2 text-center  fw-bold ">
                <img
                  src={Main_Logo}
                  className="img-fluid LOGO_SIZE_ADJUST11 "
                  alt=""
                />
                <br />
              </h3>
              <h1 className="  py-2 rounded-2 text-center  fw-bold">
                Forget a Password
              </h1>
              <p className="text-secondary fw-bold mt-4">
                We'll Send a password reset link to your email
              </p>
              <label for="email" className="text_color_heading fw-bold">
                Email<span className="text-danger">*</span>
              </label>
              <br />
              <input
                type="text"
                id="email"
                name="email"
                placeholder="Enter a Registered Email"
                className=" form-control rounded-3 p-3 mt-2"
                onChange={handleInput}
                value={data.email}
              />
              <br />

              <div className="mt-4   text-center rounded-1 ">
                <a href="/Login">
                  <button
                    type="button"
                    class="btn btn-dark  rounded-5 px-5 p-2 text-black fw-bold"
                    style={{ backgroundColor: "#ffff" }}
                  >
                    Back
                  </button>
                </a>
                <a href="">
                  <button
                    type="button"
                    class="btn btn-success rounded-5 px-4 p-2 mx-2 text-white fw-bold"
                    onClick={handleForgotPassword}
                  >
                    Send Reset email
                  </button>
                </a>
              </div>
            </form>
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>
    </>
  );
};

export default Forget_Password;
