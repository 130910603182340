import React from "react";
import "./Whats_App_Stick.css";

const Whats_App_Stick = () => {
  const phoneNumber = "+918778584566/"; // Replace with your phone number
  const message =
    "Hello RajaSekar, I have some queries to discuss with You. Could You Please Appoint me a time"; // Replace with your default message

  const openWhatsApp = () => {
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(url, "_blank");
  };

  return (
    <>
      <body>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="WHATS_APP_FLOAT mx-1 my-1">
                <img
                  src="https://static-00.iconduck.com/assets.00/whatsapp-icon-2040x2048-8b5th74o.png" // Replace with the path to your WhatsApp icon image
                  alt="WhatsApp Icon"
                  onClick={openWhatsApp}
                  style={{ cursor: "pointer" }}
                  className="mx-3 my-3"
                />
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  );
};

export default Whats_App_Stick;
