import React, { useEffect, useState } from "react";
import "./User_Profile.css";
import { FaRegAddressCard } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { UserProfileAPI, getSingleUser } from "../../api/consumer.js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getconsumerId } from "../../Utils/Storage";
import { uploadFile } from "../../Utils/FileUpload";
import { Link, useNavigate } from "react-router-dom";
const User_Profile = () => {
  const [profilePicture, setProfilePicture] = useState(null);
  const [profileDetails, setProfileDetails] = useState();
  const [data, setData] = useState({
    _id: getconsumerId(),
    name: "",
    address: "",
    postCode: "",
    profileUrl: "",
  });
  const [errors, setErrors] = useState({
    name: {
      required: false,
    },
    address: {
      required: false,
    },
    postCode: {
      required: false,
    },
  });
  const navigate = useNavigate();
  let consumerId = getconsumerId();
  const handleFileChange = (event) => {
    const file = event?.target?.files[0];
    const folder = "pixclick/profile";
    if (file) {
      uploadFile(file, folder)
        .then((res) => {
          event.target.value = null;
          const image = res?.Location;
          setProfilePicture(image);
          setData((prevData) => ({
            ...prevData,
            profileUrl: image,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value.toString(), // Convert value to string
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: { required: false },
    }));
  };

  const validateInput = () => {
    let error = {
      name: { required: false },
      address: { required: false },
      postCode: { required: false },
    };

    if (!data.name || data.name.trim() === "") {
      error.name.required = true;
    }
    if (!data.address || data.address.trim() === "") {
      error.address.required = true;
    }
    if (!data.postCode || data.postCode.toString().trim() === "") {
      error.postCode.required = true;
    }

    setErrors(error);
    return !(
      error.name.required ||
      error.address.required ||
      error.postCode.required
    );
  };

  const addProfile = (e) => {
    e.preventDefault();

    if (!validateInput()) {
      toast.error("Please fill in all the required fields");
      return;
    }

    if (!/^\d+$/.test(data.postCode)) {
      toast.error("Invalid postcode: Postcode must contain only numbers");
      return;
    }

    UserProfileAPI(data)
      .then((res) => {
        // console.log("This is API response", res);
        if (res.data.success === true) {
          toast.success("Profile updated successfully");
          navigate("/");
        } else {
          toast.error("Invalid data: Please ensure all fields are correct");
          console.log("Invalid data received from the server");
        }
      })
      .catch((err) => {
        toast.error("Please try again later");
        console.log("Error occurred while updating profile:", err);
      });
  };

  const getProfileDetails = () => {
    getSingleUser(getconsumerId()).then((res) => {
      if (res.data.success === true) {
        setProfileDetails(res.data.result);
        console.log("This is API response single user", res);
      } else {
        console.log("Status code failed");
      }
    });
  };

  useEffect(() => {
    getProfileDetails();
  }, []);

  useEffect(() => {
    if (profileDetails) {
      setData({
        _id: getconsumerId(),
        name: profileDetails.name || "",
        address: profileDetails.address || "",
        postCode: profileDetails.postCode || "",
        profileUrl: profileDetails.profileUrl || "",
      });
      setProfilePicture(profileDetails.profileUrl || null);
    }
  }, [profileDetails]);

  return (
    <div className="container-fluid user_edit_profile">
      <div className="row">
        <div classname="col-md-12">
          <p className="h1 text-white fw-bold pt-5">User Profile</p>
        </div>
      </div>
      <div className="container profilepage  ">
        <div className="row  bg-white rounded-5  shadow">
          {/* <div className="col-md-4 border-right p-5 mt-5">
            <img
              src={profilePicture}
              alt="Avatar"
              className="img-fluid my-5 img-display-before rounded-circle  mt-5 mb-0"
              style={{ width: "150px", height: "140px" }}
            /><br />
            <b><span>{data.name}</span></b> <br />
            <b><span>{data.address}</span></b> <br />
 
 
          </div> */}
          <div className="col-md-12 p-5">
            <a
              href="/"
              className="text-white"
              style={{ textDecoration: "none" }}
            >
              <button className="btn btn-success  float-end">
                <i class="bi bi-house fw-bold  "></i> Back to Home
              </button>
            </a>

            <div className="position-relative d-inline-block">
              <input
                type="file"
                id="profileImageInput"
                className="d-none"
                accept="image/*"
                onChange={handleFileChange}
              />
              <label htmlFor="profileImageInput" className="position-relative">
                <div className="rounded-circle overflow-hidden mt-3">
                  {profilePicture ? (
                    <img
                      src={profilePicture}
                      alt="Profile"
                      className="img-fluid rounded-circle"
                      style={{
                        width: "150px",
                        height: "150px",
                        border: "5px solid lightgreen",
                      }}
                    />
                  ) : (
                    <div className="bg-light text-center p-4">
                      <i className="fas fa-camera fa-3x text-secondary"></i>
                      <img
                        src={
                          "https://cdn-icons-png.flaticon.com/512/5987/5987462.png"
                        }
                        alt="Avatar"
                        className="img-fluid my-5 img-display-before rounded-circle mt-2 mb-0"
                        style={{
                          width: "150px",
                          height: "140px",
                          border: "5px solid lightgreen",
                        }}
                      />
                    </div>
                  )}
                </div>
              </label>
            </div>
            <div class="input-group mb-3 mt-5">
              <span class="input-group-text" id="basic-addon1">
                <i class="bi bi-person-fill"></i>
              </span>
              <input
                type="text"
                class="form-control"
                name="name"
                placeholder="Full name"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={handleInput}
                value={data.name}
              />
            </div>
            <div class="input-group mb-3">
              <span class="input-group-text" id="basic-addon2">
                <FaRegAddressCard />
              </span>
              <input
                type="text"
                class="form-control"
                name="address"
                placeholder="Address"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={handleInput}
                value={data.address}
              />
            </div>
            <div class="input-group mb-3">
              <span class="input-group-text" id="basic-addon3">
                <FaLocationDot />
              </span>
              <input
                type="text"
                class="form-control"
                name="postCode"
                placeholder="Post Code"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={handleInput}
                value={data.postCode}
              />
            </div>
            <button
              type="button"
              class="btn btn-success mt-2 btn-block"
              onClick={addProfile}
              
            >
              <i class="bi bi-save fs-4  fw-bold"></i> Save Profile
            </button>
          </div>
          <div className="col-md-12 text-center fw-bold">
            <div
              class="modal fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">
                      Modal title
                    </h1>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body"></div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default User_Profile;
