import React from "react";
import "./PersonalInfo.css";
import Header from './../../Components/Header/Header';
import Footer from './../../Components/Footer/Footer';
import {getconsumerId} from './../../Utils/Storage';
import {isValidPhone,isValidEmail} from './../../Utils/Validation';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from "react-router-dom";
import {BecameExpertApi} from '../../api/becameExpert';


const PersonalInfo = () => {

  const initialStateInputs={
    consumerId:getconsumerId(),
    name:'',
    mobileNumber:'',
    email:'',
    selfDescription:'',
  //  profileUrl:'',
   expertise:'',
   currentDesignation:'',
   company:'',
   startDate:'',
   endDate:'',
   workingHere:'',
   state:'',
   city:'',
   degree:'',
   fieldOfStudy:'',
   university:'',
   passingYear:'',
   awardName:'',
   awardYear:'',
   awardingAuthority:'',
   publicationName:'',
   publicationUrl:'',
   uploadPublicationsHere:'',
   faceBookLink:'',
   youTubeLink:'',
  }
  const initialStateErrors={
    name:{required:false},
    mobileNumber:{required:false,valid:false},
    email:{required:false,valid:false},
    selfDescription:{required:false},
    expertise:{required:false},
    currentDesignation:{required:false},
    company:{required:false},
    startDate:{required:false},
    endDate:{required:false},
    workingHere:{required:false},
    state:{required:false},
    city:{required:false},
    degree:{required:false},
    fieldOfStudy:{required:false},
    university:{required:false},
    passingYear:{required:false},
    awardName:{required:false},
    awardYear:{required:false},
    awardingAuthority:{required:false},
    publicationName:{required:false},
    publicationUrl:{required:false},
    uploadPublicationsHere:{required:false},
    faceBookLink:{required:false},
    youTubeLink:{required:false},
  }



  const handleValidation=(data)=>{
    let error=initialStateErrors;
    if(data.name===""){
      error.name.required=true;
    }
    if(data.mobileNumber===""){
      error.mobileNumber.required=true;
    }
    if(!isValidPhone(data.mobileNumber)){
      error.mobileNumber.valid=true;
    }
    if(data.email===""){
      error.email.required=true;
    }
    if(!isValidEmail(data.email)){
      error.email.valid=true;
    }
    if(data.selfDescription===""){
      error.selfDescription.required=true;
    }
    if(data.expertise===""){
      error.expertise.required=true;
    }
    if(data.currentDesignation===""){
      error.currentDesignation.required=true;
    }
    if(data.company===""){
      error.company.required=true;
    }
    if(data.startDate===""){
      error.startDate.required=true;
    }
    if(data.endDate===""){
      error.endDate.required=true;
    }
    if(data.workingHere===""){
      error.workingHere.required=true;
    }
    if(data.state===""){
      error.state.required=true;
    }
    if(data.city===""){
      error.city.required=true;
    }
    if(data.degree===""){
      error.degree.required=true;
    }
    if(data.fieldOfStudy===""){
      error.fieldOfStudy.required=true;
    }
    if(data.university===""){
      error.university.required=true;
    }
    if(data.passingYear===""){
      error.passingYear.required=true;
    }
    if(data.awardName===""){
      error.awardName.required=true;
    }
    if(data.awardYear===""){
      error.awardYear.required=true;
    }
    if(data.awardingAuthority===""){
      error.awardingAuthority.required=true;
    }
    if(data.publicationName===""){
      error.publicationName.required=true;
    }
    if(data.publicationUrl===""){
      error.publicationUrl.required=true;
    }
    if(data.uploadPublicationsHere===""){
      error.uploadPublicationsHere.required=true;
    }
    if(data.faceBookLink===""){
      error.faceBookLink.required=true;
    }
    if(data.youTubeLink===""){
      error.youTubeLink.required=true;
    }
    return error;
  }
  const [inputs, setInputs] = React.useState(initialStateInputs);
  const [errors, setErrors] = React.useState(initialStateErrors);
  const [submitted, setSubmitted] = React.useState(false);

  const handleErrors=(obj)=>{
    for(const key in obj){
      if(obj.hasOwnProperty(key)){
        const prop=obj[key];
        if(prop.required===true || prop.valid===true){
          return false;
        }
      }
    }
    return true;
  }

  const handleInputs = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
    if (submitted) {
      const newError = handleValidation({
        ...inputs,
        [e.target.name]: e.target.value,
      });
      setErrors(newError);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let newerror=handleValidation(inputs);
    setErrors(newerror);
    setSubmitted(true);
    if (handleErrors(newerror)) {
       BecameExpertApi(inputs)
        .then((res) => {
          //console.log("api response for became expert",res);
          toast.success(res?.data?.message);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
    console.log(inputs);
  };
  
  return (
    <>
    <Header/>
      <div className="personal_info FONT_1_POPPINS mt-5 pt-5">
        <div className="container">
          <div className="row">
            <div className="col-12"></div>
            <div className="col-md-6 col-12 col-ld-6">
              <h5 className="TC_COL_1">Personal Information</h5>
              <input
                className="form-control "
                type="text"
                name="name"
                onChange={handleInputs}
                value={inputs.name}
                placeholder="Enter your name"
              />
              {
                errors.name.required ?<p className="text-danger">Name is required *</p> : null
              }
              <br />
              <input
                className="form-control "
                type="number"
                name="mobileNumber"
                onChange={handleInputs}
                value={inputs.mobileNumber}
                placeholder="Enter your mobileNumber"
              />
              {
                errors.mobileNumber.required ?(<p className="text-danger">Mobile Number is required *</p>) :
              
              errors.mobileNumber.valid ? (<p className="text-danger">Enter valid Mobile Number *</p>):null}
              <br />
              <input
                className="form-control "
                type="text"
                name="email"
                onChange={handleInputs}
                value={inputs.email}
                placeholder="Enter your email address"
              />
              {
                errors.email.required ? <p className="text-danger">Email is required *</p>:
              
              errors.email.valid ?<p className="text-danger">Enter valid Email *</p>:null}
              <br />
              <textarea
                className="form-control"
                placeholder="Tell about yourself"
                rows="5"
                onChange={handleInputs}
                value={inputs.selfDescription}
                name="selfDescription"
              ></textarea>
              {
                errors.selfDescription.required ?<p className="text-danger">Self Description is required *</p>:null
              }
              <br />
              <h5>Expertise</h5>
              <input
                className="form-control "
                type="text"
                name="expertise"
                onChange={handleInputs}
                value={inputs.expertise}
                placeholder="Add your expertise"
              />
              {
                errors.expertise.required ?<p className="text-danger">Expertise is required *</p>:null}
              <br />
              <h5>Personal info</h5>
              <input
                className="form-control "
                type="text"
                onChange={handleInputs}
                value={inputs.currentDesignation}
                name="currentDesignation"
                placeholder="Current Designation"
              />
              {
                errors.currentDesignation.required ?<p className="text-danger">Current Designation is required *</p>:null
              }
              <br />
              <input
                className="form-control "
                type="text"
                name="company"
                onChange={handleInputs}
                value={inputs.company}
                placeholder="Company/Institute name"
              />
              {
                errors.company.required ?<p className="text-danger">Company/Institute name is required *</p>:null
              }
              <br />
              <div className="row">
                <div className="col-md-6">
                  <label className="fw-bold">Start date</label>
                  <input
                    className="form-control"
                    type="date"
                    name="startDate"
                    onChange={handleInputs}
                    value={inputs.startDate}
                    placeholder="Enter the name of the farms"
                  />
                  {
                    errors.startDate.required ?<p className="text-danger">Start Date is required *</p>:null  }
                </div>

                <div className="col-md-6">
                  <label className="fw-bold">End date</label>
                  <input
                    className="form-control"
                    type="date"
                    name="endDate"
                    onChange={handleInputs}
                    value={inputs.endDate}
                    placeholder="Enter the name of the farms"
                  />
                  {
                    errors.endDate.required ?<p className="text-danger">End Date is required *</p>:null  }
                  <br />
                </div>
              </div>

              <span className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="workingHere"
                  onChange={handleInputs}
                  checked={inputs.workingHere === "working"}
                  value="working"
                />
                <small>i am currently working here</small>
              </span>
              {
                errors.workingHere.required ?<p className="text-danger">Working here is required *</p>:null  }
              <br />
             
              <hr />
              <br />
              <h5>Service areas</h5>
              <select
                name="state"
                className="form-select"
                onChange={handleInputs}
                value={inputs.state}
                aria-label="Default select example"
              >
                <option selected>select state</option>
                <option value="TamilNadu">TamilNadu</option>
                <option value="Kerala">Kerala</option>
                <option value="Karnataka">Karnataka</option>
              </select>
              {
                errors.state.required ?<p className="text-danger">State is required *</p>:null
              }
              <br />
              <select
                name="city"
                className="form-select"
                onChange={handleInputs}
                value={inputs.city}
                aria-label="Default select example"
              >
                <option selected>select City</option>
                <option value="tiruvannamalai">tiruvannamalai</option>
                <option value="bengaluru">bengaluru</option>
                <option value="chennai">chennai</option>
              </select>
              {
                errors.city.required ?<p className="text-danger">City is required *</p>:null
              }
              <br />
             
            </div>
            <div className="col-md-6 col-12 col-ld-6">
              <h5>Qualification</h5>
              <select
                name="degree"
                className="form-select"
                onChange={handleInputs}
                value={inputs.degree}
                aria-label="Default select example"
              >
                <option selected> select your Degree</option>
                <option value="Bsc">Bsc</option>
                <option value="BA">BA</option>
                <option value="MSc">MSc</option>
              </select>
              {
                errors.degree.required ?<p className="text-danger">Degree is required *</p>:null
              }
              <br />
              <input
                className="form-control"
                type="text"
                name="fieldOfStudy"
                onChange={handleInputs}
                value={inputs.fieldOfStudy}
                placeholder="Field of study"
              />
              {
                errors.fieldOfStudy.required ?<p className="text-danger">Field of study is required *</p>:null  
              }
              <br />
              <input
                className="form-control"
                type="text"
                name="university"
                onChange={handleInputs}
                value={inputs.university}
                placeholder="University/institute name"
              />
              {
                errors.university.required ?<p className="text-danger">University Name is required *</p>:null
              }
              <br />
              <select
                name="passingYear"
                onChange={handleInputs}
                value={inputs.passingYear}
                className="form-select"
                aria-label="Default select example"
              >
                <option selected>Passing year</option>
                <option value="2011">2011</option>
                <option value="2012">2012</option>
                <option value="2013">2013</option>
              </select>
              {
                errors.passingYear.required ?<p className="text-danger">Passing year is required *</p>:null
              }
              <br />
              
              <hr />
              <h5>Awards & Recognisation</h5>
              <input
                className="form-control"
                type="text"
                name="awardName"
                onChange={handleInputs}
                value={inputs.awardName}
                placeholder="Award & recognisation"
              />
              {
                errors.awardName.required ?<p className="text-danger">Award Name is required *</p>:null
              }
              <br />
              <input
                className="form-control"
                type="text"
                onChange={handleInputs}
                value={inputs.awardingAuthority}
                name="awardingAuthority"
                placeholder="Awarding authority"
              />
              {
                errors.awardingAuthority.required ?<p className="text-danger">Awarding Authority is required *</p>:null
              }
              <br />
              <input
                className="form-control"
                type="text"
                onChange={handleInputs}
                value={inputs.awardYear}
                name="awardYear"
                placeholder="Receiving year"
              />
              {
                errors.awardYear.required ?<p className="text-danger">Award Year is required *</p>:null
              }
              <br />
             
              <hr />
              <br />
              <h5>Publications</h5>
              <input
                className="form-control"
                type="text"
                onChange={handleInputs}
                value={inputs.publicationName}
                name="publicationName"
                placeholder="Publication title"
              />
              {
                errors.publicationName.required ?<p className="text-danger">Publication Name is required *</p>:null
              }
              <br />
              <input
                className="form-control"
                type="text"
                onChange={handleInputs}
                value={inputs.publicationUrl}
                name="publicationUrl"
                placeholder="Enter PublicationUrl"
              />
              {
                errors.publicationUrl.required ?<p className="text-danger">Publication Url is required *</p>:null
              }
              <br />
              <input
                className="form-control"
                type="text"
                onChange={handleInputs}
                value={inputs.uploadPublicationsHere}
                name="uploadPublicationsHere"
                placeholder="Upload Publication here"
              />
              {
                errors.uploadPublicationsHere.required ?<p className="text-danger">Upload Publication here is required *</p>:null
              }
              <br />
             
              <hr />
              <br />
              <h5>Social Media Accounts</h5>
              <div class="input-group social_media mb-3">
                <span class="input-group-text" id="basic-addon1">
                  <img
                    src={require("../../Assets/facebook.png")}
                    className="img-fluid"
                    style={{ width: "25px", height: "25px" }}
                  />
                </span>
                <input
                  type="text"
                  class="form-control"
                  value={inputs.faceBookLink}
                  onChange={handleInputs}
                  placeholder="Facebook link"
                  aria-label="Username"
                  name="faceBookLink"
                  aria-describedby="basic-addon1"
                /><br/>
                {
                  errors.faceBookLink.required ?<p className="text-danger">Facebook link is required *</p>:null
                }
              </div>
              <div class="input-group social_media mb-3">
                <span class="input-group-text" id="basic-addon1">
                  <img
                    src={require("../../Assets/youtube.png")}
                    className="img-fluid"
                    style={{ width: "25px", height: "25px" }}
                  />
                </span>
                <input
                  type="text"
                  class="form-control"
                  value={inputs.youTubeLink}
                  onChange={handleInputs}
                  placeholder="Youtube link"
                  aria-label="Username"
                  name="youTubeLink"
                  aria-describedby="basic-addon1"
                /><br/>
                {
                  errors.youTubeLink.required ?<p className="text-danger">Youtube link is required *</p>:null
                }
              </div>
              <br />
             
            </div>
            <div className="col-md-12 submit_btn">
              <center>
                <button onClick={handleSubmit} className="rounded-2 text-white w-25 text-center mb-2 ">
                  Submit your info
                </button>
              </center>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PersonalInfo;
