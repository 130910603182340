import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

const RiskDiscloure = () => {
  return (
    <>
      <Header />
      <div className="container-fluid ">
        <div className="container mt-5 pt-5">
          <div className="row mt-4">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <h1 className="fw-bold">
                Pixalive - <span className="text-success">Risk Disclosure</span>
              </h1>
              <p>
                Investing in alternative products carries inherent risks,
                including the potential loss of invested capital. Users are
                advised to exercise caution and should only invest funds they
                can afford to risk.
              </p>

              <p>
                We strive to provide relevant information on opportunities and
                products featured on our platform to help users make informed
                decisions. However, it's crucial for users to independently
                assess and thoroughly understand the risks associated with each
                investment opportunity before making any financial commitments.
              </p>

              <p>
                FAAB Invest operates as an execution-only application,
                refraining from offering business, investment, tax, or legal
                advice. Users are strongly encouraged to consult with their own
                tax, legal, and financial advisors and conduct their due
                diligence before engaging in any investment opportunities.
                Historical returns, expected returns, or probability projections
                may not accurately reflect future performance. It is imperative
                for investors to be financially prepared to bear potential
                losses on their investments.
              </p>

              <p>
                We do not promise assured returns or provide any
                security/undertakings related to the opportunities/products
                listed on the FAAB Invest platform. Users should carefully read
                risk factors and make well-informed decisions based on their
                individual assessments.
              </p>

              <p>
                At FAAB Invest, we believe in transparency and keeping our
                investors well-informed. It is crucial to understand that
                alternative investment products inherently carry risks.
              </p>

              <h3 className="fw-bold">100% Loss of Investment amount</h3>
              <p>
                Investment in any opportunity does not provide guaranteed
                returns even if invested in high-grade/rated or low credit risk
                opportunities. The investor may lose in whole or in part even
                the principal amount at the time of redemption. Investment is a
                risky affair and you may not be able to recover, on a timely
                basis or at all, the full value of the investment and/or the
                return accrued thereon and neither FAAB nor the entity(ies),
                person(s) associated to it makes any representations,
                warranties, or guaranties whatsoever in this regard.
              </p>

              <h3 className="fw-bold">Past performance and Forecasts</h3>
              <p>
                FAAB may provide its own assessment of opportunity which is
                generally based on past performance and future forecasts.
                However, investor shall understand that past performance is not
                a reliable indicator of future performance. Investor should not
                rely on any past performance as a guarantee of future investment
                performance. Investor shall not rely on FAAB assessment of
                investment opportunity. FAAB encourages investors to do their
                own evaluation of investment risk parameters and return
                expectations on case-to-case basis.
              </p>

              <h3 className="fw-bold">Nature-Related Risks</h3>
              <p>
                Agricultural projects are susceptible to adverse weather
                conditions, natural disasters, and other acts of nature. These
                events, including but not limited to droughts, floods, storms,
                and earthquakes, can significantly impact crop yields and,
                consequently, returns on investment. FAAB is not liable, and
                investors should not hold FAAB responsible for any losses
                resulting from this risk.
              </p>

              <h3 className="fw-bold">Force Majeure Events</h3>
              <p>
                Unforeseeable circumstances beyond human control, commonly
                referred to as force majeure events, may adversely affect the
                outcome of projects. These events may include, but are not
                limited to, acts of war, terrorism, government actions, and
                other unforeseen disruptions. FAAB will not be held responsible
                for any losses resulting from this risk.
              </p>

              <h3 className="fw-bold">Delayed pay-outs</h3>
              <p>
                There may be delay in receipt of payments i.e. payment may come
                before or after the stated expected payment schedule or maturity
                period. You completely understand that FAAB is not obligated to
                facilitate timely pay-outs with respect to this opportunity.
              </p>

              <p>
                FAAB is not liable, and investor(s) shall not hold FAAB
                responsible for any delays, defaults, frauds, misrepresentations
                etc. on part of any counterparty(ies). The aforesaid list of
                risk factors is not intended to be exhaustive, nor a
                comprehensive explanation of the risks involved.
              </p>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RiskDiscloure;
