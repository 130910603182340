import React, { useState, useEffect } from "react";
import "./Lakadong_turmeric.css";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { MdOutlineIosShare } from "react-icons/md";
import { Carousel } from "react-bootstrap";
import Card1L1 from "./Assets_Lakodong_turmeric/card_1img_layer1.png";
import Card1L2 from "./Assets_Lakodong_turmeric/card_1img_layer2.png";
import Card2L1 from "./Assets_Lakodong_turmeric/card_2img_layer1.png";
import Card2L2 from "./Assets_Lakodong_turmeric/card_2img_layer2.png";
import Card3L1 from "./Assets_Lakodong_turmeric/card_3img_layer1.png";
import Card3L2 from "./Assets_Lakodong_turmeric/card_3img_layer2.png";
import Card4L1 from "./Assets_Lakodong_turmeric/card_4img_layer1.png";
import Card4L2 from "./Assets_Lakodong_turmeric/card_4img-layer2.png";

function Lakadong_turmeric() {
  const [count, setCount] = useState(0);

  const increment = () => {
    setCount(count + 1);
  };

  const decrement = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };
  // carousel
  useEffect(() => {
    // Initialize the carousel
    const imageCarousel = document.getElementById("imageCarousel");
    const bootstrapCarousel = new window.bootstrap.Carousel(imageCarousel, {
      interval: false,
    });

    return () => {
      // Cleanup on component unmount
      bootstrapCarousel.dispose();
    };
  }, []);
  return (
    <div>
      <Header />
      <div className="container-fluid" id="bg_color">
        <div className="row">
          <div className=" d-none d-lg-flex ">
            <div className="col-lg-7 ">
              <div className="row">
                <div className="col-lg-6 pt-3">
                  <img
                    src="https://amyrafarms.com/cdn/shop/files/1.png?v=1690265108&width=493"
                    className="img-fluid"
                    alt="Large Image 1"
                  />
                </div>
                <div className="col-lg-6 pt-3">
                  <img
                    src="https://amyrafarms.com/cdn/shop/files/2_9aee22ab-59af-4e11-8106-7802c540bbf2.png?v=1690265109&width=493"
                    className="img-fluid"
                    alt="Large Image 2"
                  />
                </div>
                <div className="col-lg-6 pt-3">
                  <img
                    src="https://amyrafarms.com/cdn/shop/files/3_73081ce5-4326-44f1-936f-8842ce99758d.png?v=1690265109&width=493"
                    className="img-fluid"
                    alt="Large Image 3"
                  />
                </div>
                <div className="col-lg-6 pt-3">
                  <img
                    src="https://amyrafarms.com/cdn/shop/files/4_5edded38-142a-41cc-b563-f6371e13a853.png?v=1690265109&width=493"
                    className="img-fluid"
                    alt="Large Image 4"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-5 mt-5">
              <div className="change_conentent">
                <span id="text_color_dark" className="fw-bold small_font_size ">
                  AMYRAFARMS
                </span>
                <br />
                <span className=" fw-bold textheading" id="text_color_dark">
                  Lakadong Turmeric Powder From Meghalaya
                </span>
                <br />
                <span id="text_color_dark" className=" amount">
                  Rs. 300.00
                </span>
                <p id="text_color_dark" className="para_small_font_size">
                  Tax included.{" "}
                  <a href="#" id="text_color_dark">
                    Shipping
                  </a>{" "}
                  calculated at checkout.
                </p>
                <div className="butto">
                  <p id="text_color_dark" className="para_small_font_size p-0">
                    Size
                  </p>
                  <div className="btn_clr_change">
                    <a class="btn" role="button">
                      <span className="px-2 text-white">250 gm</span>
                    </a>
                  </div>
                </div>

                {/* counter for product */}

                <div className="hhbdafbhdab pt-3 ">
                  <div>
                    <p className="para_small_font_size" id="text_color_dark">
                      Quantity
                    </p>
                  </div>
                  <button class="btn w-25 p-2 rounded-0" type="submit">
                    <span onClick={decrement} id="text_color_dark">
                      -
                    </span>
                    <span className="px-4" id="text_color_dark">
                      {count}
                    </span>
                    <span onClick={increment} id="text_color_dark">
                      +
                    </span>
                  </button>
                </div>
                {/* counter for product */}
              </div>
              <div>
                <div className="d-grid gap-2 pt-3 change_btn_formate_clr">
                  <button className="btn w-75 p-2" type="button">
                    Add to cart
                  </button>
                </div>
                <div
                  className="d-grid gap-2  pt-2 another_btn_change_clr"
                  id="text_color_dark"
                >
                  <button className="btn w-75 p-2" type="button">
                    Buy it now
                  </button>
                </div>
              </div>
              <div className="">
                <p className="pt-3  para" id="text_color_dark">
                  Amyra Farms' Lakadong Turmeric Powder from Meghalaya is a
                  premium quality turmeric powder that is handpicked and
                  processed in small batches to retain its freshness and
                  potency. Our turmeric is sourced from the hills of Meghalaya
                  and is known for its high curcumin content. Our turmeric has{" "}
                  <span className="fw-bold">9% to 12%</span> extra curcumin than
                  regular turmeric, making it a more potent and effective
                  natural remedy for a variety of health benefits.
                </p>
              </div>
              <div className="table_head_content ">
                <table className="table w-75 table_bg_color table_head_content">
                  <thead className=" ">
                    <tr>
                      <th scope="col" id="text_color_dark">
                        Calories
                      </th>
                      <td scope="col" id="text_color_dark">
                        356 Kcal
                      </td>
                    </tr>
                  </thead>
                  <tbody className="table_head_content">
                    <tr>
                      <th scope="row" id="text_color_dark">
                        {" "}
                        Total Fat{" "}
                      </th>
                      <td id="text_color_dark">9.86 g</td>
                    </tr>
                    <tr>
                      <th scope="row" id="text_color_dark">
                        Carbohydrates{" "}
                      </th>
                      <td id="text_color_dark">64.6 g</td>
                    </tr>
                    <tr>
                      <th scope="row" id="text_color_dark">
                        Protein{" "}
                      </th>
                      <td colSpan={2} id="text_color_dark">
                        7.81 g
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" id="text_color_dark">
                        Sodium{" "}
                      </th>
                      <td id="text_color_dark">37 mg</td>
                    </tr>
                    <tr>
                      <th scope="row" id="text_color_dark">
                        Dietary Fibre{" "}
                      </th>
                      <td colSpan={2} id="text_color_dark">
                        {" "}
                        23 g
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" id="text_color_dark">
                        Potassium{" "}
                      </th>
                      <td colSpan={2} id="text_color_dark">
                        {" "}
                        2527 mg
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="">
                <p className="para pt-3">
                  Our Lakadong Turmeric Powder is made using traditional methods
                  and is free from any chemicals or additives, ensuring its
                  purity and authenticity. It has a bright yellow color and a
                  strong aroma that adds depth and flavor to any dish. It is
                  perfect for use in curries, soups, marinades, and smoothies.
                </p>
                <p className="para pt-3">
                  At Amyra Farms, we are committed to providing our customers
                  with the highest quality products that are sustainably sourced
                  and ethically produced. We take pride in offering you this
                  premium Lakadong Turmeric Powder that is not only good for
                  your health but also supports the local farmers and
                  communities in Meghalaya.
                </p>
                <h4 id="text_color_dark" className="lpara">
                  100% WILD Naturally Grown in the Meghalaya.
                </h4>
              </div>
              <div className="icon pt-4">
                <a href="#" className="anchors fs-6">
                  <MdOutlineIosShare />
                  Share
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Carousel for Medium and Small Devices */}
        <div
          id="imageCarousel"
          className="carousel slide d-lg-none"
         
        >
          <div className="">
            <Carousel
              interval={null}
              crossfade
              style={{ width: "80%", margin: "auto" }}
            >
              {/* Slide 1 */}
              <Carousel.Item>
                <img
                  src="https://amyrafarms.com/cdn/shop/files/1.png?v=1690265108&width=493"
                  className="img-fluid"
                  alt="Large Image 1"
                />
              </Carousel.Item>

              {/* Slide 2 */}
              <Carousel.Item>
                <img
                  src="https://amyrafarms.com/cdn/shop/files/2_9aee22ab-59af-4e11-8106-7802c540bbf2.png?v=1690265109&width=493"
                  className="img-fluid"
                  alt="Large Image 2"
                />
              </Carousel.Item>

              {/* Slide 3 */}
              <Carousel.Item>
                <img
                  src="https://amyrafarms.com/cdn/shop/files/3_73081ce5-4326-44f1-936f-8842ce99758d.png?v=1690265109&width=493"
                  className="img-fluid"
                  alt="Large Image 3"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src="https://amyrafarms.com/cdn/shop/files/4_5edded38-142a-41cc-b563-f6371e13a853.png?v=1690265109&width=493"
                  className="img-fluid"
                  alt="Large Image 4"
                />
              </Carousel.Item>
            </Carousel>
          </div>
          <a
            className="carousel-control-prev mt-5 pt-5"
            href="#imageCarousel"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next mt-5 pt-5"
            href="#imageCarousel"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
          <div className="col-lg-5 colmd-12 mt-5">
            <div className="change_conentent">
              <span id="text_color_dark" className="fw-bold small_font_size ">
                AMYRAFARMS
              </span>
              <br />
              <span className=" fw-bold textheading" id="text_color_dark">
                Lakadong Turmeric Powder From Meghalaya
              </span>
              <br />
              <span id="text_color_dark" className=" amount">
                Rs. 300.00
              </span>
              <p id="text_color_dark" className="para_small_font_size">
                Tax included.{" "}
                <a href="#" id="text_color_dark">
                  Shipping
                </a>{" "}
                calculated at checkout.
              </p>
              <div className="butto">
                <p id="text_color_dark" className="para_small_font_size p-0">
                  Size
                </p>
                <div className="btn_clr_change">
                  <a class="btn" role="button">
                    <span className="px-2 text-white">250 gm</span>
                  </a>
                </div>
              </div>

              {/* counter for product */}

              <div className="hhbdafbhdab pt-3 ">
                <div>
                  <p className="para_small_font_size" id="text_color_dark">
                    Quantity
                  </p>
                </div>
                <button class="btn w-50 p-2  rounded-0" type="submit">
                  <span onClick={decrement} id="text_color_dark">
                    -
                  </span>
                  <span className="px-4" id="text_color_dark">
                    {count}
                  </span>
                  <span onClick={increment} id="text_color_dark">
                    +
                  </span>
                </button>
              </div>
              {/* counter for product */}
            </div>
            <div>
              <div className="d-grid gap-2 pt-3 change_btn_formate_clr">
                <button className="btn w-75 p-2" type="button">
                  Add to cart
                </button>
              </div>
              <div
                className="d-grid gap-2  pt-2 another_btn_change_clr"
                id="text_color_dark"
              >
                <button className="btn w-75 p-2" type="button">
                  Buy it now
                </button>
              </div>
            </div>
            <div className="">
              <p className="pt-3  para" id="text_color_dark">
                Amyra Farms' Lakadong Turmeric Powder from Meghalaya is a
                premium quality turmeric powder that is handpicked and processed
                in small batches to retain its freshness and potency. Our
                turmeric is sourced from the hills of Meghalaya and is known for
                its high curcumin content. Our turmeric has{" "}
                <span className="fw-bold">9% to 12%</span> extra curcumin than
                regular turmeric, making it a more potent and effective natural
                remedy for a variety of health benefits.
              </p>
            </div>
            <div className="table_head_content ">
              <table className="table w-75 table_bg_color table_head_content">
                <thead className=" ">
                  <tr>
                    <th scope="col" id="text_color_dark">
                      Calories
                    </th>
                    <td scope="col" id="text_color_dark">
                      356 Kcal
                    </td>
                  </tr>
                </thead>
                <tbody className="table_head_content">
                  <tr>
                    <th scope="row" id="text_color_dark">
                      {" "}
                      Total Fat{" "}
                    </th>
                    <td id="text_color_dark">9.86 g</td>
                  </tr>
                  <tr>
                    <th scope="row" id="text_color_dark">
                      Carbohydrates{" "}
                    </th>
                    <td id="text_color_dark">64.6 g</td>
                  </tr>
                  <tr>
                    <th scope="row" id="text_color_dark">
                      Protein{" "}
                    </th>
                    <td colSpan={2} id="text_color_dark">
                      7.81 g
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" id="text_color_dark">
                      Sodium{" "}
                    </th>
                    <td id="text_color_dark">37 mg</td>
                  </tr>
                  <tr>
                    <th scope="row" id="text_color_dark">
                      Dietary Fibre{" "}
                    </th>
                    <td colSpan={2} id="text_color_dark">
                      {" "}
                      23 g
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" id="text_color_dark">
                      Potassium{" "}
                    </th>
                    <td colSpan={2} id="text_color_dark">
                      {" "}
                      2527 mg
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="">
              <p className="para pt-3">
                Our Lakadong Turmeric Powder is made using traditional methods
                and is free from any chemicals or additives, ensuring its purity
                and authenticity. It has a bright yellow color and a strong
                aroma that adds depth and flavor to any dish. It is perfect for
                use in curries, soups, marinades, and smoothies.
              </p>
              <p className="para pt-3">
                At Amyra Farms, we are committed to providing our customers with
                the highest quality products that are sustainably sourced and
                ethically produced. We take pride in offering you this premium
                Lakadong Turmeric Powder that is not only good for your health
                but also supports the local farmers and communities in
                Meghalaya.
              </p>
              <h4 id="text_color_dark" className="lpara">
                100% WILD Naturally Grown in the Meghalaya.
              </h4>
            </div>
            <div className="icon pt-4">
              <a href="#" className="anchors fs-6">
                <MdOutlineIosShare />
                Share
              </a>
            </div>
          </div>
        </div>

        <div className="row mt-5 mx-auto">
          <div className="col-md-12 ">
            <p className="h2" id="text_color_dark">
              You may also like
            </p>
          </div>
          <div className="col-md-12">
            <a href="#" className="hover-switch"></a>
          </div>
        </div>
        <div className="row pt-5 pb-5  d-flex justify-content-around">
          <div className="col-md-2 bg-white">
            <div class="image-switch-container pt-3   ">
              <img src={Card1L1} alt="Image 1" className="img-fluid" />
              {/* The second image will be displayed on hover */}
              <img src={Card1L2} alt="Image 2" className="img-fluid" />
            </div>
            <p id="text_color_dark" className="fs-6 pt-2 ">
              Premium cardamom
            </p>
            <p id="text_color_dark" className="fs-6 ">
              From Rs.800.00
            </p>
          </div>
          <div className="col-md-2 bg-white">
            <div class="image-switch-container pt-3 ">
              <img src={Card2L1} alt="Image 1" className="img-fluid" />
              {/* The second image will be displayed on hover */}
              <img src={Card2L2} alt="Image 2" className="img-fluid" />
            </div>
            <p id="text_color_dark" className="fs-6 pt-2">
              Royal Black Peppercorns
            </p>
            <p id="text_color_dark" className="fs-6">
              From Rs.375.00
            </p>
          </div>
          <div className="col-md-2 bg-white">
            <div class="image-switch-container pt-3">
              <img src={Card3L1} alt="Image 1" className="img-fluid" />
              {/* The second image will be displayed on hover */}
              <img src={Card3L2} alt="Image 2" className="img-fluid" />
            </div>
            <p id="text_color_dark" className="fs-6 pt-2">
              Spice Haven (Coffee Estate in Chikmagalur)
            </p>
            <p id="text_color_dark" className="fs-6">
              Rs.50,000.00
            </p>
          </div>
          <div className="col-md-2 bg-white">
            <div class="image-switch-container pt-3">
              <img src={Card4L1} alt="Image 1" className="img-fluid" />
              {/* The second image will be displayed on hover */}
              <img src={Card4L2} alt="Image 2" className="img-fluid" />
            </div>
            <p id="text_color_dark" className="fs-6 pt-2">
              Aromatic Masala Chai
            </p>
            <p id="text_color_dark" className="fs-6">
              Rs.350.00
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Lakadong_turmeric;
