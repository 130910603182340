import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";

// Register necessary components
Chart.register(ArcElement, Tooltip, Legend);

const PieChart = ({ totalPayment, totalReturns }) => {
  // Define data for the pie chart
  const data = {
    labels: ["Total Payment", "Total Returns"],
    datasets: [
      {
        data: [totalPayment, totalReturns], // Use props for values
        backgroundColor: ["#28edae", "#024846"],
        hoverBackgroundColor: ["#f7b906", "#f7b906"],
      },
    ],
  };

  return (
    <div>
      <Pie data={data} />
    </div>
  );
};

export default PieChart;
