import React, { useState } from "react";
import "./Rice_PD.css";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Whats_App_Stick from "../../Components/Common/Whats_App_Stick/Whats_App_Stick";
import { Carousel } from "react-bootstrap";

import { FaStar } from "react-icons/fa";

const Rice_PD = () => {
  const [count, setCount] = useState(0);

  const handleIncrement = () => {
    setCount(count + 1);
  };

  const handleDecrement = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };

  return (
    <>
      <Header />
      <div className="container mt-4 pt-5">
        <div className="row">
          <div className="col-sm-12 col-lg-12 d-flex justify-content-center  mt-5">
            <h1 className="TC_COL_1 FONT_1_SANS_SERIF TC_BG_3 px-3 py-2 rounded-1 ">
              Details of Rice Seeds
            </h1>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row mt-4">
          <div className="col-lg-6 col-md-12 col-12">
            <Carousel
              interval={null}
              crossfade
              style={{ width: "80%", margin: "auto" }}
            >
              {/* Slide 1 */}
              <Carousel.Item>
                <img
                  className="d-block w-100 img-fluid rounded-1"
                  src="https://i0.wp.com/post.medicalnewstoday.com/wp-content/uploads/sites/3/2018/09/benefits-of-hemp-seeds.jpg?w=1155&h=1541"
                  alt="1 slide"
                />
              </Carousel.Item>

              {/* Slide 2 */}
              <Carousel.Item>
                <img
                  className="d-block w-100 img-fluid rounded-1"
                  src="https://northernnester.com/wp-content/uploads/2020/08/types-of-seeds.jpeg"
                  alt="2 slide"
                />
              </Carousel.Item>

              {/* Slide 3 */}
              <Carousel.Item>
                <img
                  className="w-100 img-fluid rounded-1"
                  src="https://www.taropumps.com/media/2538/type-of-seeds-2.jpg"
                  alt="3 slide"
                />
              </Carousel.Item>
            </Carousel>
          </div>

          <div className="col-lg-6 col-md-12 col-12 mt-4 px-5">
            <h1>
              Rice seeds <small className="fs-4">(BRRI Rice)</small>
            </h1>
            <p className="text_color ">
              Available in stock
              <span className="text-black  fs-5 ml-5 ">
                <b>₹ 150</b> <small className="text-secondary">/kg</small>{" "}
              </span>
            </p>
            <span className="">
              <span className="fs-4 ">
                <FaStar style={{ color: "gold" }} />
              </span>
              <span className="text-secondary">4.9 (258)</span>
            </span>

            <span className=" ml-5 px-0 mt-2">
              <button
                onClick={handleDecrement}
                className="button_circle_color px-3 p-1"
              >
                -
              </button>
              <span className="fs-5">
                {" "}
                <b> {count}Kg</b>{" "}
              </span>
              <button
                onClick={handleIncrement}
                className="button_circle_color px-3 p-1"
              >
                +
              </button>
            </span>

            <h2 className="mt-4">Description</h2>
            <p className="text-secondary">
              Good quality rice seeds are uniform in size, full and plumps;
              while poor quality seeds are often discolred. seed is the
              foundation of any rice crop. It must be ...{" "}
              <span className="text-success">Read More</span>
            </p>
            <h2 className="mt-4">Related products</h2>

            <div className="products_scroll_media ">
              <span>
                <img
                  src="https://2.wlimg.com/product_images/bc-full/2020/10/6329741/wheat-1603695803-5451975.jpg"
                  alt=""
                  className="img-fluid image_size_wheat mx-1"
                />

                <img
                  src="https://livingskyfarms.ca/cdn/shop/files/Wholewhitewheat.jpg?v=1691556124&width=1946"
                  alt=""
                  className="img-fluid image_size_wheat mx-1 "
                />

                <img
                  src="https://www.dmtraders.com/wp-content/uploads/2021/05/FLAX-SEEDS-1.jpg.webp"
                  alt=""
                  className="img-fluid image_size_wheat mx-1"
                />
                <img
                  src="https://2.wlimg.com/product_images/bc-full/2020/10/6329741/wheat-1603695803-5451975.jpg"
                  alt=""
                  className="img-fluid image_size_wheat mx-1"
                />
                <img
                  src="https://2.wlimg.com/product_images/bc-full/2020/10/6329741/wheat-1603695803-5451975.jpg"
                  alt=""
                  className="img-fluid image_size_wheat mx-1"
                />
              </span>
            </div>

            <div class="d-grid col-12 mt-4 button_circle_color1">
              <button class="btn  p-3 rounded-1" type="button">
                Request
              </button>
            </div>
          </div>
        </div>
      </div>
      <br /><br />
      <Footer />
      <Whats_App_Stick />
    </>
  );
};

export default Rice_PD;
