import "./App.css";
import { BrowserRouter, Routes, Route, RouterProvider } from "react-router-dom";
import Home_Main from "./Pages/Home/Home_Main";
import About_Us from "./Pages/About_Us/About_Us";
import Blogs from "./Pages/Blogs/Blogs";
import Contact from "./Pages/Contact/Contact";
import Lakadong_turmeric from "./Pages/Lakadong_turmeric/Lakadong_turmeric";
import FAQ_Main from "./Pages/Faq/FAQ_Main";
import All_Collections from "./Pages/All_Collections/All_Collections";
import Investment_Collections from "./Pages/Investment_Collections/Investment_Collections";
import Services from "./Pages/Services/Services";
import Seeds_List from "./Pages/List_Of_Services/Seeds_List/Seeds_List";
import Seedlings_List from "./Pages/List_Of_Services/Seedlings_List/Seedlings_List";
import Machinery_List from "./Pages/List_Of_Services/Machinery_List/Machinery_List";
import Hire_Worker_List from "./Pages/List_Of_Services/Hire_Worker_List/Hire_Worker_List";
import Cultivation_Process_List from "./Pages/List_Of_Services/Cultivation_Process_List/Cultivation_Process_List";
import Crop_Disease_Solution_List from "./Pages/List_Of_Services/Crop_Disease_Solution_List/Crop_Disease_Solution_List";
import Rice_PD from "./Pages/Product_details/Rice_PD";
import Sign_Up from "./Pages/Login/Sign_Up/Sign_Up";
import Login from "./Pages/Login/Login/Login";
import OTP from "./Pages/Login/OTP/OTP";
import Forget_Password from "./Pages/Login/Forget_Password/Forget_Password";
import Create_Post from "./Pages/Create_Post/Create_Post";
import User_Profile from "./Pages/User_Profile/User_Profile";
import Reset_Password from "./Pages/Login/Reset_Password/Reset_Password";
import Feed from "./Pages/Feed/Feed";

import Category from "./Pages/Category/Category";
import Coins_Policy from "../src/Pages/Policies/Coins_Policy/Coins_Policy";
import Cancellation_policy from "../src/Pages/Policies/Cancellation_policy/Cancellation_policy";
import Terms_Conditions from "../src/Pages/Policies/Terms_And_Conditions/Terms_And_Conditions";
import Privacy_Policy from "../src/Pages/Policies/Privacy_Policy/Privacy_Policy";
import CreatePlan from "./Pages/CreatePlan/CreatePlan";
import LoginAdmin from "./Pages/LoginAdmin/LoginAdmin";
import Invest from "./Pages/Investment_Collections/Invest/Invest";
import PersonalInfo from "./Pages/Become Expert/PersonalInfo";
import OurExperts from "./Pages/Become Expert/Our Expert/OurExperts";
import ExpertDetails from "./Pages/Become Expert/ExpertDetails/ExpertDetails";
import Expertise from "./Pages/Become Expert/Expertise/Expertise";
import Farms_Investment from "./Pages/Farms_Investment/Farms_Investment";
import Enquiry from "./Pages/Enquiryform/Enquiry";
import RiskDiscloure from "./Pages/RiskDisclosure/RiskDiscloure";
import Refund_Policy from "./Pages/Refund_Policy/Refund_Policy";

// import appRouter from "./Router";
// import Footer from "./Components/Footer/Footer"
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Feed />} />
          <Route path="/Sign_Up" element={<Sign_Up />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/OTP" element={<OTP />} />
          <Route path="/Forget_Password" element={<Forget_Password />} />
          <Route path="/Reset_Password" element={<Reset_Password />} />
          <Route path="/Create_Post" element={<Create_Post />} />
          <Route path="/About_Us" element={<About_Us />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/FAQ_Main" element={<FAQ_Main />} />
          <Route path="/Blogs" element={<Blogs />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/User_Profile" element={<User_Profile />} />
          <Route path="/Category" element={<Category />} />
          <Route path="/Home" element={<Home_Main />} />
          <Route
            path="/Investment_Collections"
            element={<Investment_Collections />}
          />
          <Route path="/All_Collections" element={<All_Collections />} />
          <Route path="/Lakadong_turmeric" element={<Lakadong_turmeric />} />

          <Route path="/Seeds_List" element={<Seeds_List />} />
          <Route path="/Seedlings_List" element={<Seedlings_List />} />
          <Route path="/Machinery_List" element={<Machinery_List />} />
          <Route path="/Hire_Worker_List" element={<Hire_Worker_List />} />
          <Route
            path="/Cultivation_Process_List"
            element={<Cultivation_Process_List />}
          />
          <Route
            path="/Crop_Disease_Solution_List"
            element={<Crop_Disease_Solution_List />}
          />

          <Route path="/Rice_PD" element={<Rice_PD />} />

          <Route path="/Coins_Policy" element={<Coins_Policy />} />
          <Route
            path="/Cancellation_policy"
            element={<Cancellation_policy />}
          />
          <Route path="/Terms_Conditions" element={<Terms_Conditions />} />
          <Route path="/privacy_policy" element={<Privacy_Policy />} />
          <Route path="createplan" element={<CreatePlan />} />
          <Route path="/loginadmin" element={<LoginAdmin />} />
          <Route path="/Invest" element={<Invest />} />
          <Route path="/personalinfo" element={<PersonalInfo />} />
          <Route path="/ourexperts" element={<OurExperts />} />
          <Route path="/expertdetails" element={<ExpertDetails />} />
          <Route path="/expertise" element={<Expertise />} />
          <Route path="/FarmsInvestment" element={<Farms_Investment />} />
          <Route path="/Enquiry" element={<Enquiry />} />
          <Route path="/RiskDisclosure" element={<RiskDiscloure />} />
          <Route path="/Refundpolicy" element={<Refund_Policy />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
