import React from "react";
import "./Home_1_HeadLine_Video_BG.css";

const Home_1_HeadLine_Video_BG = () => {
  return (
    <>
      {/* ------------------------BACKGROUND_VIDEO--------------------- */}
      <body className="HOME_HEAD_BODY">
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-12 col-sm-12 BG_VIDEO_WIDTH my-5">
              {/* <video
                className="rounded-3"
                src="https://adearns-dev.s3.ap-south-1.amazonaws.com/Bg_Video.mp4"
                autoPlay
                loop
                muted
              /> */}

              <div
                className="col-md-6 col-sm-12 mt-5 HEAD_LINE_CONTENT text-center  
               text-md-start"
              >
                <span className="FONT_1_POPPINS text-white mt-5">
                  Smart Investment in Agriculture Simplified,
                </span>
                <p className="FONT_1_SANS_SERIF text-white mt-2">
                  Earn Tax-Free: 14.0% Average Annual Return*
                </p>
                <div
                  className="col-md-10 HEAD_INPUT_OUTER p-2 d-flex gap-2 rounded-3 "
                  style={{
                    background: "rgba(255, 255, 255, 0.1)",
                    backdropFilter: "blur(2px)",
                  }}
                >
                  <input
                    type="text"
                    className=" HEAD_INPUT_INNER p-2"
                    placeholder="Email Address"
                    style={{
                      color: "white",
                      background: "rgba(255, 255, 255, 0.1)",
                      backdropFilter: "blur(2px)",
                    }}
                  />

                  <button className="TC_BG_2 HEAD_INVEST_NOW_BTN w-25 rounded-3 fw-bold">
                    <a href="/">Invest Now</a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>

      {/* ------------------------HOME_HEADLINE_CONTENT--------------------- */}

      <body className="TC_BG_3 FONT_1_SANS_SERIF">
        <div className="container py-5">
          <div className="row py-5 d-flex gap-4 text-center text-lg-start">
            <div className="col-sm-3 TC_COL_1 HOME_HEADLINE_CONTENT">
              Pixclick Farms At A Glance
            </div>
            <div className="col-sm-1"></div>
            <div className="col-sm-2">
              <span className="TC_COL_1 HOME_HEADLINE_CONTENT">15</span>
              <sup className="POWER_PLUS TC_COL_2">+</sup>
              <p className="TC_COL_1 P_TAG_HOME_HEADLINE_CONTENT">
                Years of experience
              </p>
            </div>
            <div className="col-sm-2">
              <span className="TC_COL_1 HOME_HEADLINE_CONTENT">₹15000</span>
              <sup className="POWER_PLUS TC_COL_2">+</sup>
              <p className="TC_COL_1 P_TAG_HOME_HEADLINE_CONTENT">
                Minimum Investment
              </p>
            </div>
            <div className="col-sm-2">
              <span className="TC_COL_1 HOME_HEADLINE_CONTENT">100Acre</span>
              <sup className="POWER_PLUS TC_COL_2">+</sup>
              <p className="TC_COL_1 P_TAG_HOME_HEADLINE_CONTENT">
                Total Land Acquired
              </p>
            </div>
          </div>
        </div>
      </body>
    </>
  );
};

export default Home_1_HeadLine_Video_BG;
