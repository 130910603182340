import API from "./api";
import {  Consumer} from "./endpoints";

export const comsumerSignUp = (data) => {
  return API.post(`${Consumer}/`, data);  
};
export const UserProfileAPI = (data) => {
    return API.put(`${Consumer}/`, data);
  };
  export const getSingleUser = (data) => {
    return API.get(`${Consumer}/getSingleUser`, { params: { _id: data } })
  }
  export const getAllUsers = (data) => {
    return API.get(`${Consumer}`, data)
  }
