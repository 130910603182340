import React from "react";
import "./Steps_To_Invest.css";

const Steps_To_Invest = () => {
  return (
    <>
      <body className="TC_BG_1 FONT_1_SANS_SERIF">
        <div className="container pt-5 ">
          <div className="row px-5 d-flex justify-content-between">
            <div className="col-sm-6">
              <h1 className="TC_COL_2">
                Five Simple&nbsp;
                <span className="text-white">Steps to Your Investment</span>
              </h1>
            </div>
            <div className="col-sm-6 mt-3">
              <p className="text-white">
                Seamlessly Invest in Top-notch Farms and Reap Sustained Returns
              </p>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row px-3">
            <div className="col-sm-6 d-flex gap-5">
              <img
                src="https://amyrafarms.com/cdn/shop/files/one.png?v=1685941907"
                alt="1"
                className="STI_IMG_SIZE"
              />
              <h6 className="text-white fw-bold">
                Farm & Entity Selection
                <p className="mt-3 TJ STI_P text-white">
                  Only top-notch farms pass our stringent review process. Every
                  selected farm, along with its legal title, is incorporated
                  into a unique entity, generally an LLC.
                </p>
              </h6>
            </div>
            <div className="col-sm-6 d-flex gap-5">
              <img
                src="https://amyrafarms.com/cdn/shop/files/two.png?v=1685941907"
                alt="1"
                className="STI_IMG_SIZE"
              />
              <h6 className="text-white fw-bold">
                Investment
                <p className="mt-3 TJ STI_P text-white">
                  Peace of Mind: We value your trust and aim to provide you with
                  peace of mind throughout your investment journey. With
                  asset-backed security, you can have confidence in the solidity
                  of your investment and focus on reaping the rewards of your
                  partnership with Pixclick Farms.
                </p>
              </h6>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row px-3">
            <div className="col-sm-6 d-flex gap-5">
              <img
                src="https://amyrafarms.com/cdn/shop/files/three.png?v=1685941907"
                alt="1"
                className="STI_IMG_SIZE"
              />
              <h6 className="text-white fw-bold">
                Management
                <p className="mt-3 TJ STI_P text-white">
                  We at Pixclick Farms handle every aspect of property
                  management and administration. From liaising with local
                  farmers to insurance, accounting, and soil sustainability -
                  we've got it covered.
                </p>
              </h6>
            </div>
            <div className="col-sm-6 d-flex gap-5">
              <img
                src="https://amyrafarms.com/cdn/shop/files/four.png?v=1685941907"
                alt="1"
                className="STI_IMG_SIZE"
              />
              <h6 className="text-white fw-bold">
                Tax-Free Returns
                <p className="mt-3 TJ STI_P text-white">
                  Tax-Free Returns: One of the most appealing aspects of
                  investing in agriculture at Pixclick Farms is the opportunity
                  for tax-free returns. Agriculture investments often come with
                  favorable tax benefits, providing you with the potential to
                  maximize your earnings and enjoy tax-free income. It's a
                  win-win situation that allows you to grow your wealth while
                  minimizing your tax liabilities.
                </p>
              </h6>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row px-3">
            <div className="col-sm-6 d-flex gap-5">
              <img
                src="https://amyrafarms.com/cdn/shop/files/five.png?v=1685941907"
                alt="1"
                className="STI_IMG_SIZE"
              />
              <h6 className="text-white fw-bold">
                Secure Investment
                <p className="mt-3 TJ STI_P text-white">
                  Secure Investment: With our Buy-Back guarantee, your
                  investment in land at Pixclick Farms is safeguarded. We
                  provide you with the assurance that we will repurchase the
                  land from you, ensuring liquidity and minimizing any potential
                  risks. It's a secure investment option that allows you to
                  enjoy the benefits of land ownership with added peace of mind.
                </p>
              </h6>
            </div>
            <div className="col-sm-6 d-flex justify-content-lg-end align-items-end justify-content-center">
              <div className="TC_BG_2 HEAD_INVEST_NOW_BTN p-3 rounded-3 fw-bold mt-5 text-center">
                <a href="/">
                  Invest Now <i class="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5 pb-5">
          <div className="row px-5">
            <div className="col-sm-12 text-white bg-opacity-10 bg-white rounded-3">
              <span className="TC_COL_2">*Note </span> - Investment involves
              risk. Past performance is not indicative of future results. Please
              review all investment details and seek professional advice before
              making any investment decisions.
            </div>
          </div>
        </div>
      </body>
    </>
  );
};

export default Steps_To_Invest;
