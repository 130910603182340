import React, { useEffect } from "react";
import "./Footer.css";
import foot from "../../../src/Assets/footerlofofarms.png";
import $ from "jquery";
import { Link } from "react-router-dom"; // Import {Link}

const Footer = () => {
  useEffect(() => {
    $(".footer-back-to-top").click(function (e) {
      e.preventDefault(); // Prevent the default behavior of the anchor link
      $("html, body").animate({ scrollTop: 0 }, 1000);
    });
  }, []);

  return (
    <>
      <div className="footer FONT_1_POPPINS">
        <footer className=" container-fluid">
          <div className="row px-5">
            <div className="col-lg-3 col-md-6 col-12">
              <div className="">
                <img className="img-fluid LOGO_SIZE_ADJUST1" src={foot} alt />
                <h6 className="text-white bold-none py-3">
                  Get updates and more
                </h6>
                <div className="">
                  <input
                    id="NewsletterForm"
                    type="email"
                    name="contact[email]"
                    className="px-4 py-2 transparent-input border-0 text-white"
                    placeholder="Enter Your Email "
                    required
                  />
                </div>
              </div>
              <div className="social_media_icon   text-white  my-3">
                <i class="bi bi-facebook px-3 fs-1 facebook_color"></i>
                <i class="bi bi-instagram px-3 fs-1 instagram_color"></i>
                <i class="bi bi-youtube px-3 fs-1 youtube_color"></i>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="mediaquery_style">
                <ul className="list-unstyled ">
                  <li className="py-2 hover_under_line_Footer">
                    <a href="/" className="text-decoration-none text-white">
                      Home
                    </a>
                  </li>
                  <li className="py-2 hover_under_line_Footer">
                    <a
                      href="About_Us"
                      className="text-decoration-none text-white"
                    >
                      About us
                    </a>
                  </li>
                  <li className="py-2 hover_under_line_Footer">
                    <a
                      href="/Blogs"
                      className="text-decoration-none text-white"
                    >
                      Blogs
                    </a>
                  </li>
                  <li className="py-2 hover_under_line_Footer">
                    <a
                      href="/FAQ_Main"
                      className="text-decoration-none text-white"
                    >
                      FAQs
                    </a>
                  </li>
                  <li className="py-2 hover_under_line_Footer">
                    <a
                      href="/Contact"
                      className="text-decoration-none text-white"
                    >
                      Contact us
                    </a>
                  </li>

                  {/* <li className="py-2 hover_under_line_Footer">
                    <a
                      href="/Investment_Collections"
                      className="text-decoration-none text-white"
                    >
                      Investment Collections
                    </a>
                  </li> */}

                  <li className="py-2 hover_under_line_Footer">
                    <a
                      href="/privacy_policy"
                      className="text-decoration-none text-white"
                    >
                      Privacy Policy
                    </a>
                  </li>

                  <li className="py-2 hover_under_line_Footer">
                    <a
                      href="/All_Collections"
                      className="text-decoration-none text-white"
                    >
                      All Collections
                    </a>
                  </li>
                  {/* <li className="py-2 hover_under_line_Footer">
                  <a
                    href="/Lakadong_turmeric"
                    className="text-decoration-none text-white"
                  >
                    Lakadong_turmeric
                  </a>
                </li> */}
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-6">
              <div className="mediaquery_style">
                <ul className="list-unstyled ">
                  <li className="py-2 hover_under_line_Footer">
                    <a
                      href="/Services"
                      className="text-decoration-none text-white"
                    >
                      Services
                    </a>
                  </li>
                  <li className="py-2 hover_under_line_Footer ">
                    <a
                      href="/Seeds_List"
                      className="text-decoration-none text-white"
                    >
                      Seeds
                    </a>
                  </li>
                  <li className="py-2 hover_under_line_Footer">
                    <a
                      href="/Seedlings_List"
                      className="text-decoration-none text-white"
                    >
                      Seedlings
                    </a>
                  </li>
                  <li className="py-2 hover_under_line_Footer">
                    <a
                      href="/Machinery_List"
                      className="text-decoration-none text-white"
                    >
                      Machinery
                    </a>
                  </li>
                  <li className="py-2 hover_under_line_Footer">
                    <a
                      href="/Hire_Worker_List"
                      className="text-decoration-none text-white"
                    >
                      Hire Workers
                    </a>
                  </li>
                  <li className="py-2 hover_under_line_Footer">
                    <a
                      href="/Cultivation_Process_List"
                      className="text-decoration-none text-white"
                    >
                      Cultivation Process
                    </a>
                  </li>
                  <li className="py-2 hover_under_line_Footer">
                    <a
                      href="/Crop_Disease_Solution_List"
                      className="text-decoration-none text-white "
                    >
                      Crop Disease Solution
                    </a>
                  </li>

                 
                </ul>
              </div>
            </div>

           

            <div className="col-lg-3 col-md-6 col-12">
              <div className="mediaquery_style">
                <div>
                  <div>
                    <p className="text-white">Start Your Eco-Investment</p>
                    <a href="/" className="footer-btn">
                      Invest Now
                    </a>
                  </div>
                </div>
              </div>

              {/* back */}
              <div className=" footer-back-to-top d-flex justify-content-end my-5 text-white">
                <p className="mt-5 text-white">
                  Back to top
                  <img src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/Arrow_2_1.png?v=1686565275" />
                </p>
              </div>
            </div>
            <hr className="text-white" />
          </div>
          <div className="container-fluid ">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 mt-2 ">
                <p className="text-start text-white Address_style">
                  2nd Phase, Third Floor, 35/2, Hosur Rd, Konappana Agrahara,
                  Electronic City, Bengaluru, Karnataka 560100.
                </p>
              </div>
              <div className="col-lg-8 col-md-12 col-12 mt-5">
                <div className="d-flex text-white justify-content-end list-unstyled Address_style1">
                  <Link
                    to="/Terms_Conditions"
                    className="text-decoration-none text-white"
                  >
                    <li className="px-2">Terms & Condition</li>
                  </Link>

                  <Link
                    to="/Cancellation_policy"
                    className="text-decoration-none text-white"
                  >
                    <li className="px-2">Refund/cancellation Policy</li>
                  </Link>
                  <Link
                    to="/Coins_Policy"
                    className="text-decoration-none text-white"
                  >
                    <li className="px-2">Coins Policy</li>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
