import React from "react";
import "./Create_Post.css";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Whats_App_Stick from "../../Components/Common/Whats_App_Stick/Whats_App_Stick";
import "react-toastify/dist/ReactToastify.css";

const Create_Post = () => {
  return (
    <div>
      <Header />
      <div className="container mt-5 pt-5 FONT_1_POPPINS">
        {/* Title */}
        <div className="col-sm-12 col-lg-12 d-flex justify-content-center  mt-5">
          <h1 className="bg-light px-3 py-2 rounded-1 text_color_heading  fw-bold ">
            Create Post
          </h1>
        </div>

        {/* profile content */}
        <div className="conatiner  bg-light  p-5 ">
          <div className="row">
            <div className="col-lg-6 col-sm-12 col-12  ">
              <div
                style={{ cursor: "pointer" }}
                className="d-flex justify-content-center"
              >
                <img
                  src=""
                  alt="uploaded image"
                  className="img-display-after my-5  rounded-circle rondimg"
                  style={{ width: "300px", height: "300px" }} // Set the desired size
                />
                <img
                  src={
                    "https://cdn-icons-png.flaticon.com/512/5987/5987462.png"
                  }
                  alt="Avatar"
                  className="img-fluid my-5 img-display-before rounded-circle rondimg "
                  style={{ width: "300px", height: "300px" }}
                />

                <input
                  id="image-upload-input"
                  type="file"
                  style={{ display: "none" }}
                />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 col-12">
              <form>
                <label className="text-success fw-bold">Title</label>
                <br />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your Title"
                />
                <br />
                <label htmlFor="" className="mt-4 text-success fw-bold">
                  Hashtag
                </label>
                <br />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter a Hashtag"
                />
                <br />
                <label htmlFor="" className="mt-4 text-success fw-bold">
                  City
                </label>
                <br />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your City"
                />
                <br />
                <label htmlFor="" className="mt-4 text-success fw-bold">
                  Description
                </label>
                <br />
                <input type="text" className="form-control" />
                <br />

                <a href="/">
                  <button
                    type="button"
                    className="btn btn-success mt-5 px-5 text-black fw-bold p-3"
                    style={{ backgroundColor: "#72eab2" }}
                  >
                    Submit
                  </button>
                </a>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Whats_App_Stick />
      <Footer />
    </div>
  );
};

export default Create_Post;
