import React, { useState } from "react";
import Main_Logo from "../../../Components/Header/newlogofarms.png";
import { consumerResetPassword} from "../../../api/login";
import { useNavigate, useParams } from "react-router-dom";
 
const Reset_Password = () => {
  const navigate = useNavigate();
  const { token } = useParams(); // Assuming you're using React Router to handle route parameters
  const [data, setdata] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
 
  const handleInput = (e) => {
    const { name, value } = e.target;
    setdata((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
 
  const handleResetPassword = (e) => {
    e.preventDefault();
   
    consumerResetPassword(data) // Include the token in the request data
      .then((res) => {
        console.log("email verify", data.email);
        if (res.data.result.password) {
          navigate("/login"); // Redirect to login page after successful password reset
        } else {
          console.log("Password reset failed");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
 
  return (
    <>
      <div className="bg_forget">
        <div className="container py-5">
          <div className="d-flex justify-content-center ">
            <form className="bg-light px-5 py-5 rounded-5  ">
              <h3 className=" px-3 py-2 rounded-2 text-center  fw-bold ">
                <img
                  src={Main_Logo}
                  className="img-fluid LOGO_SIZE_ADJUST11 "
                  alt=""
                />
                <br />
              </h3>
              <h1 className="  py-2 rounded-2 text-center  fw-bold">
                Reset Password
              </h1>
              <label
                htmlFor="email"
                className="mt-3 text_color_heading fw-bold"
              >
                Email<span className="text-danger">*</span>
              </label>
              <br />
              <input
                type={"email"}
                name="email"
                placeholder="Enter a Email address"
                className="rounded-3 p-2 mt-2 form-control"
                onChange={handleInput}
              />
              <br />
              <label
                htmlFor="password"
                className="mt-3 text_color_heading fw-bold"
              >
                New Password<span className="text-danger">*</span>
              </label>
              <br />
              <input
                type={"password"}
                name="password"
                placeholder="Enter a New Password"
                className="rounded-3 p-2 mt-2 form-control"
                onChange={handleInput}
              />
              <br />
              <label className="mt-3 text_color_heading  fw-bold">
                Confirm Password<span className="text-danger">*</span>
              </label>
              <br />
              <input
                type={"password"}
                name="confirmPassword"
                onChange={handleInput}
                placeholder="Enter a Confirm Password"
                className="rounded-3 p-2 mt-2 form-control"
              />
              <div className="mt-5   text-center rounded-1 ">
                <button
                  type="submit"
                  className="btn btn-success px-5 p-2  text-white fw-bold"
                  onClick={handleResetPassword}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  );
};
 
export default Reset_Password;
 