import API from "./api";
import { Login } from "./endpoints";


export const consumerLogin = (data) => {
  return API.post(`${Login}/consumerLogin`, data);
};
export const consumerVerifyOtp = (data) => {
  return API.post(`${Login}/consumerVerify`, data);
};
export const consumerForgotPassword = (data) => {
  return API.post(`${Login}/consumerForgotPassword`, data);
};

export const consumerResetPassword = (data) => {
  return API.post(`${Login}/consumerResetPassword`, data);
};
export const adminLogin = (data) => {
  return API.post(`${Login}/adminLogin`, data);
};
