import React from "react";
import "./Get_You_Investing.css";

const Get_You_Investing = () => {
  return (
    <>
      <div className="GET_YOU_INVESTING_CONTAINER mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <span className="GET_YOU_INVESTING_TITLE">
                Let's get you investing
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <span className=" HORIZONTAL_LINE"></span>
            </div>
          </div>
        </div>

        <div className="container pb-5">
          <div className="row">
            <div className="col-lg-3 col-12 mt-5">
              <div className="GET_YOU_INVEST_CARD_CONTAINER p-4">
                <div className="d-flex justify-content-center">
                  <div className="GET_YOU_INVEST_IMG_CONTAINER mt-4">
                    <img
                      src="https://www.farmally.com.ng/assets/img/sign-up.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="GET_YOU_INVEST_CARD_TITLE d-flex justify-content-center mt-3">
                  <span>Sign Up</span>
                </div>
                <div className="GET_YOU_INVEST_CARD_DESC d-flex justify-content-center mt-3 text-justify">
                  <span>
                    In order to take part in any farm investment, you first need
                    to sign up to join the FarmAlly tree.
                  </span>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-12 mt-5">
              <div className="GET_YOU_INVEST_CARD_CONTAINER p-4">
                <div className="d-flex justify-content-center">
                  <div className="GET_YOU_INVEST_IMG_CONTAINER mt-4">
                    <img
                      src="https://www.farmally.com.ng/assets/img/browse.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="GET_YOU_INVEST_CARD_TITLE d-flex justify-content-center mt-3">
                  <span>Browse Farms</span>
                </div>
                <div className="GET_YOU_INVEST_CARD_DESC d-flex justify-content-center mt-3 text-justify">
                  <span>
                    Browse through all the various farm investment with
                    lifecylces ranging from 3 - 15 months
                  </span>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-12 mt-5">
              <div className="GET_YOU_INVEST_CARD_CONTAINER p-4">
                <div className="d-flex justify-content-center">
                  <div className="GET_YOU_INVEST_IMG_CONTAINER mt-4">
                    <img
                      src="https://www.farmally.com.ng/assets/img/sponsor.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="GET_YOU_INVEST_CARD_TITLE d-flex justify-content-center mt-3">
                  <span>Sponsor Farms</span>
                </div>
                <div className="GET_YOU_INVEST_CARD_DESC d-flex justify-content-center mt-3 text-justify">
                  <span>
                    Sponsor farms that catch your interest with ease and
                    swiftly.
                  </span>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-12 mt-5">
              <div className="GET_YOU_INVEST_CARD_CONTAINER p-4">
                <div className="d-flex justify-content-center">
                  <div className="GET_YOU_INVEST_IMG_CONTAINER mt-4">
                    <img
                      src="https://www.farmally.com.ng/assets/img/returns.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="GET_YOU_INVEST_CARD_TITLE d-flex justify-content-center mt-3">
                  <span>Make Returns</span>
                </div>
                <div className="GET_YOU_INVEST_CARD_DESC d-flex justify-content-center mt-3 text-justify">
                  <span>
                    Get the opportunity to make a 15-50% return on any
                    investment sponsored on our platform
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Get_You_Investing;
