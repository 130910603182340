import React,{useState,useEffect} from 'react';
import './Expertise.css';
import Header from '../../../Components/Header/Header';
import Footer from '../../../Components/Footer/Footer';
import Agriculture from "../../../../src/Assets/plant.png";
import Fertilizer from "../../../../src/Assets/fertilizer.png";
import Organic from "../../../../src/Assets/organic.png";
import Nursery from "../../../../src/Assets/plant-nursery.png";
import Soil from "../../../../src/Assets/seedling.png";
import Crops from "../../../../src/Assets/crop.png";
import Machinery from "../../../../src/Assets/excavator.png";
import Seeds from "../../../../src/Assets/growing-seed.png";
import Scheme from "../../../../src/Assets/house.png";
import Animal from "../../../../src/Assets/cow.png";
import Food from "../../../../src/Assets/process.png";
import Industry from "../../../../src/Assets/factory.png";
import Startup from "../../../../src/Assets/growth.png";
import Weather from "../../../../src/Assets/cloud.png";
import {getBecameExpertApi} from "../../../api/becameExpert";



const Expertise = () => {
  const datas=[
    {
      id:1,
      title:"Agriculture",
      img:Agriculture
    },
    {
      id:2,
      title:"Fertilizer",
      img:Fertilizer
    },
    {
      id:3,
      title:"OrganicFarming",
      img:Organic
    },
    {
      id:4,
      title:"Nursery",
      img:Nursery
    },
    {
      id:5,
      title:"Soil",
      img:Soil
    },
    {
      id:6,
      title:"Crops",
      img:Crops
    },
    {
      id:7,
      title:"Machinary",
      img:Machinery
    },
    {
      id:8,
      title:"Seeds",
      img:Seeds
    },
    {
      id:9,
      title:"Scheme",
      img:Scheme
    },
    {
      id:10,
      title:"Animal Husbandry",
      img:Animal
    },
    {
      id:11,
      title:"Food Processing",
      img:Food
    },
    {
      id:12,
      title:"Rural Industry",
      img:Industry
    },
    {
      id:13,
      title:"Agro Startup",
      img:Startup
    },
    {
      id:14,
      title:"Weather Info",
      img:Weather
    },
  ]

  const[getExpertise,setGetExpertise]=useState([]);

  const getBecameExpert=async()=>{
    try{
        const res=await getBecameExpertApi();
        console.log("api get expertise",res);
    }catch(error){
      console.log(error);
    }
  }

  useEffect(()=>{
    getBecameExpert();
  })  
  return (
    <>
    <Header/>
      <div className='expertise_container mt-5 pt-5 FONT_1_POPPINS'>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>Expertise</h1>
              </div>
             {
              datas.map((data)=>(
                <div className="col-md-2  mb-3 shadow rounded-5 py-3 mx-2" key={data.id} style={{cursor:"pointer"}}>
                <center>

                <img src={data.img} alt="img" className="img-fluid" style={{width:"50%", height:"50%"}}/>
                </center>
                <p className='text-center fs-5' style={{width:"100%", height:"100%"}}>{data.title}</p>
              </div>
              ))
             }
            </div>
          </div>
      </div>
      <Footer/>
    </>
  )
}

export default Expertise
