import React, { useState } from "react";
import "./Sign_Up.css";
import { useNavigate } from "react-router-dom";
import Main_Logo from "../../../Components/Header/newlogofarms.png";
import { comsumerSignUp } from "../../../api/consumer.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isAuthenticated } from '../../../Utils/Auth';
import { isValidEmail, isValidPassword, isValidPhone } from "../../../Utils/Validation";
import { getToken,saveToken } from "../../../Utils/Storage";

const Sign_Up = () => {

  const initialStateInputs = {
    email: "",
    mobileNumber: "",
    password: "",
    confirmPassword: "",
  };
  const initialStateErrors = {
    email: { required: false, valid: false },
    mobileNumber: { required: false, valid: false },
    password: { required: false, valid: false },
    confirmPassword: { required: false, valid: false },

  };


  const [inputs, setInputs] = useState(initialStateInputs);
  const [errors, setErrors] = useState(initialStateErrors);
  const [submitted, setSubmitted] = useState(false);

  const navigate = useNavigate();

  const handleValidation = (data) => {
    const error = { ...initialStateErrors };
    if (data.email === "") {
      error.email.required = true;
    }
    if (data.mobileNumber === "") {
      error.mobileNumber.required = true;
    }
    if (data.password === "") {
      error.password.required = true;
    }
    if (data.confirmPassword === "") {
      error.confirmPassword.required = true;
    }
    if (!isValidEmail(data.email)) {
      error.email.valid = true;
    }
    if (!isValidPhone(data.mobileNumber)) {
      error.mobileNumber.valid = true;
    }
    if (!isValidPassword(data.password)) {
      error.password.valid = true;
    }
    if (!isValidPassword(data.confirmPassword)) {
      error.confirmPassword.valid = true;
    }
    return error;
  };
  const handleErrors = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const prop = obj[key];
        if (prop.required === true || prop.valid === true) {
          return false;
        }
      }
    }
    return true;
  }
  const handleInput = (event) => {
    const { name, value } = event.target;
    setInputs({ ...inputs, [name]: value });

    if (submitted) {
      const newError = handleValidation({ ...inputs, [name]: value });
      setErrors(newError);
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const newError = handleValidation(inputs)
    setErrors(newError)
    setSubmitted(true)
    if (handleErrors(newError)) {
      comsumerSignUp(inputs).then(res => {
        let token = res?.data?.result?.token;
        let consumerId = res?.data?.result?.ConsumerDetails?._id;
        let data = {
          token:token, consumerId: consumerId 
        }
        saveToken(data);
        if (isAuthenticated()) {
          console.log("Navigating to /OTP");
          navigate("/OTP", { state: { email: inputs.email } });
        }
        toast.success('OTP sent to your registered email address');
      }).catch(err => {
        toast.error(err?.response?.data?.message);
      })
    }
  }


  return (
    <>
      <div className="bg_signup">
        <div className="container  py-3">
          <div className="d-flex justify-content-center">
            <form onSubmit={handleSubmit} className="bg-light p-5  rounded-5">
            <h3 className=" px-3 py-2 rounded-2 text-center  fw-bold ">
                <img src={Main_Logo} className="img-fluid LOGO_SIZE_ADJUST11 " alt="" />
                <br />
              </h3>
              <h4 className=" px-3 py-2 rounded-2 text-center">

               SignUp
              </h4>
              {/*  Login with Google Button */}
              <div class="d-grid gap-2 text-center d-md-block mt-3">
                <button
                  className="btn btn-secondary rounded-5 text-black  fw-bold p-2 px-3"
                  type="button"
                  style={{ backgroundColor: "white" }}
                >
                  {/* Google icons styles */}
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    version="1.1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 48 48"
                    enable-background="new 0 0 48 48"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="#FFC107"
                      d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12
	c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24
	c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                    ></path>
                    <path
                      fill="#FF3D00"
                      d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657
	C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                    ></path>
                    <path
                      fill="#4CAF50"
                      d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36
	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                    ></path>
                    <path
                      fill="#1976D2"
                      d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571
	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                    ></path>
                  </svg>{" "}
                  Sign Up with Google
                </button>
                <button
                  className="btn btn-secondary rounded-5 text-black mx-1  fw-bold p-2 px-3"
                  type="button"
                  style={{ backgroundColor: "white" }}
                >
                  {/* Google icons styles */}
                  Sign Up with{" "}
                  <span className="text-primary fw-bolder"> Facebook </span>
                </button>
              </div>
              <div
                className="text-secondary mb-4 mt-3 mx-auto"
                style={{
                  width: "75%",
                  height: 15,
                  borderBottom: "1px solid rgb(193, 193, 193)",
                  textAlign: "center",
                }}
              >
                <span
                  style={{
                    fontSize: 10,
                    backgroundColor: "rgb(255, 255, 255)",
                    padding: "0px 10px",
                  }}
                >
                  OR
                </span>
              </div>
              {/* .......................................................................................................................... */}
              {/*................................... input Fields  ....................................................................*/}

              {/* email inputs */}
              <label className="text_color_heading fw-bold">
                Email<span className="text-danger">*</span>
              </label>
              <br />
              <input
                type="text"
                name="email"
                placeholder="Enter a Email address"
                className=" form-control rounded-3 p-2 mt-2"
                onChange={handleInput}
              />
              {errors.email.required ? (
                <div className="text-danger form-text">
                  This field is required.
                </div>
              ) : errors.email.valid ? (
                <div className="text-danger form-text">
                  Enter valid Email Id.
                </div>
              ) : null}

              <br />

              <label htmlFor="" className="text_color_heading fw-bold mt-3">
                PhoneNumber<span className="text-danger">*</span>
              </label>
              <br />
              <input
                type="text"
                name="mobileNumber"
                onChange={handleInput}
                placeholder="Enter a PhoneNumber"
                className=" form-control rounded-3 p-2 mt-2 "
              />
              {errors.mobileNumber.required ? (
                <span className="text-danger form-text">
                  This field is required.
                </span>
              ) : errors.mobileNumber.valid ? (
                <span className="text-danger form-text">
                  Enter valid Phone Number.
                </span>
              ) : null}
              <br />

              {/* password input  */}
              <label
                htmlFor="password"
                className="mt-3 text_color_heading  fw-bold"
              >
                Password<span className="text-danger">*</span>
              </label>
              <br />
              <input
                type={"password"}
                name="password"
                value={inputs?.password}
                placeholder="Enter a Password"
                className=" form-control rounded-3 p-2 mt-2"
                onChange={handleInput}
              />
              {errors.password.required ? (
                <div className="text-danger form-text">
                  This field is required.
                </div>
              ) : errors.password.valid ? (
                <div className="text-danger form-text">
                  A minimum 8 characters password contains a <br />
                  combination of {''}
                  <strong>uppercase, lowercase, {''}</strong>
                  <strong>special <br /> character{''}</strong> and <strong>number</strong>.
                </div>
              ) : null}

              <br />
              {/* Confirm password input */}
              <label className="mt-3 text_color_heading  fw-bold">
                Confirm Password<span className="text-danger">*</span>
              </label>
              <br />
              <input
                type={"password"}
                name="confirmPassword"
                value={inputs?.confirmPassword}
                placeholder="Enter a ConfirmPassword"
                className=" form-control rounded-3 p-2 mt-2"
                onChange={handleInput}
              />
              {errors.confirmPassword.required ? (
                <div className="text-danger form-text">
                  This field is required.
                </div>
              ) : errors.confirmPassword.valid ? (
                <div className="text-danger form-text">
                  A minimum 8 characters password contains a <br />
                  combination of {''}
                  <strong>uppercase, lowercase, {''}</strong>
                  <strong>special <br /> character{''}</strong> and <strong>number</strong>.
                </div>
              ) : null}
              <p className="mt-5">
                <span>
                  <input type="checkbox" className="fs-1" />
                </span>
                <span className="mx-2">Agree the
                  <a href="#">  Terms of service </a>
                  and acknowledge the <a href="#">Privacy policy</a></span>
              </p>

              {/* signup Button */}
              <div className="mt-5 d-grid  text-center rounded-1 ">
                <button
                  type="submit"
                  className="btn btn-success px-5 p-2 text-white rounded-5 fw-bolder"
                 

                >
                  Sign Up
                </button>
              </div>
              <div className="text-center mt-4">
                <p className="fw-bold">
                  Have an account already?
                  <a
                    href="/Login"
                    className="text-decoration-none text-primary mx-1 fw-bold"
                  >
                    Login
                  </a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sign_Up;
