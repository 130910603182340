import React from "react";
import Home_1_HeadLine_Video_BG from "./Home_1_HeadLine_Video_BG/Home_1_HeadLine_Video_BG";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Home_FAQ from "./Home_FAQ/Home_FAQ";
import Why_Choose from "./Why_Choose/Why_Choose";
import Investment_Opportunities from "./Investment_Opportunities/Investment_Opportunities";
import Steps_To_Invest from "./Steps_To_Invest/Steps_To_Invest";
import Invest_Agri from "./Invest_Agri/Invest_Agri";
import Featured_Collections from "./Featured_Collections/Featured_Collections";
import Blog_Post from "../../Components/Common/Blog_Post";
import Whats_App_Stick from "../../Components/Common/Whats_App_Stick/Whats_App_Stick";
import Trusted_Brands from "../../Components/Common/Trusted_Brands/Trusted_Brands";
import Invest_Pixclick_Farms from "./Invest_Pixclick_Farms/Invest_Pixclick_Farms";
import Banners from "./Banners/Banners";
import Powerfull_Solution from "./Powerfull_Solution/Powerfull_Solution";
import How_To_Participate from "./How_To_Participate/How_To_Participate";
import Discover_App from "./Discover_App/Discover_App";
import HeadlineCarousel from "./HeadlineCarousel/HeadlineCarousel";

const Home_Main = () => {
  return (
    <div>
      <Header />
      <body className="mt-4 pt-5 overflow-x-hidden">
        <HeadlineCarousel />
        {/* <Home_1_HeadLine_Video_BG /> */}
        <Powerfull_Solution />
        <How_To_Participate />
        <Banners />
        <Why_Choose />
        <Investment_Opportunities />
        <Steps_To_Invest />
        <Invest_Agri />
        <Featured_Collections />
        <Blog_Post />
        <Trusted_Brands />
        <Discover_App />
        <Home_FAQ />
        <Invest_Pixclick_Farms />
        <Whats_App_Stick />
      </body>
      <Footer />
    </div>
  );
};

export default Home_Main;
