import { useState, useEffect } from "react";
import "./Header.css";
import Main_Logo from "../Header/newlogofarms.png";
import Goldcoinss from "../Header/gg.png";
import { FaBars, FaTimes } from "react-icons/fa";
import { clearStorage } from "../../Utils/Storage";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../Utils/Auth";
import { getconsumerId } from "../../Utils/Storage";
import { getSingleUser } from "../../api/consumer.js";

const Header = ({ showCreatePostButton }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(isAuthenticated());
  const [profileDetails, setProfileDetails] = useState();
  const [profileImage, setProfileImage] = useState();

  const navigate = useNavigate();

  const handleLogout = () => {
    setIsLoggedIn(false);
    clearStorage();
    toast.success("You have logged out successfully.");
    navigate("/");
  };

  const [isResponsive, setResponsive] = useState(false);

  const toggleResponsive = () => {
    setResponsive(!isResponsive);
  };

  const getProfileDetails = () => {
    getSingleUser(getconsumerId()).then((res) => {
      if (res.data.success === true) {
        setProfileDetails(res.data.result);
        setProfileImage(res.data.result.profileUrl);
        console.log("This is API response", res.data.result);
      } else {
        console.log("Status code failed");
      }
    });
  };

  useEffect(() => {
    // Call getProfileDetails when the component mounts
    if (isLoggedIn) {
      getProfileDetails();
    }
  }, [isLoggedIn]);

  return (
    <div>
      <nav class="navbar navbar-expand-lg bg-light fixed-top FONT_1_POPPINS">
        <div class="container-fluid">
          {/* Logo  */}
          <a class="navbar-brand LOGO_SIZE_ADJUST" href="/">
            <img src={Main_Logo} className="img-fluid " />
          </a>

          {/* toggler button  */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleResponsive}
            id="toggle_button"
          >
            {isResponsive ? <FaTimes /> : <FaBars />}
          </button>

          <div
            className={`collapse navbar-collapse color_chng  ${
              isResponsive ? "show" : ""
            }`}
          >
            <div class="d-flex justify-content-between mt-1  w-75">
              <ul class="navbar-nav px-3 ">
                <li class="nav-item px-2">
                  <Link
                    to="/Home"
                    className="nav-link active"
                    aria-current="page"
                  >
                    <span className="hover_under_line">Home</span>
                  </Link>
                </li>

                <li class="nav-item px-2">
                  <Link
                    to="/FarmsInvestment"
                    className="nav-link active"
                    aria-current="page"
                  >
                    <span className="hover_under_line">Farms Investment</span>
                  </Link>
                </li>
                <li class="nav-item px-2">
                  <Link to="/" className="nav-link active" aria-current="page">
                    <span className="hover_under_line">Feed</span>
                  </Link>
                </li>
                <li class="nav-item px-2">
                  <Link
                    to="/Category"
                    className="nav-link active"
                    aria-current="page"
                  >
                    <span className="hover_under_line">Category</span>
                  </Link>
                </li>

                <li class="nav-item px-2 ">
                  <Link
                    to="/Services"
                    className="nav-link active"
                    aria-current="page"
                  >
                    <span className="hover_under_line">Services</span>
                  </Link>
                </li>

                {/* <li class="nav-item">
                  <Link
                    to="/Blogs"
                    className="nav-link active"
                    aria-current="page"
                  >
                    <span className="hover_under_line">Blog</span>
                  </Link>
                </li> */}

                {/* <li class="nav-item">
                  <Link
                    to="/Contact"
                    className="nav-link active"
                    aria-current="page"
                  >
                    <span className="hover_under_line">ContactUs</span>
                  </Link>
                </li> */}

                <li className="nav-item dropdown px-2">
                  <Link
                    to=""
                    className="nav-link dropdown-toggle"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="hover_under_line">
                      Investment Opportunities
                    </span>
                  </Link>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <Link
                      to="/Investment_Collections"
                      className="dropdown-item"
                    >
                      Investment Collections
                    </Link>
                    <Link to="/All_Collections" className="dropdown-item">
                      All Collections
                    </Link>
                    <Link to="/Lakadong_turmeric" className="dropdown-item">
                      Lakadong Turmeric
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
            {/* Add Post  */}
            <div className=" mx-auto   mediaquerrysizemove ">
              <ul className="navbar-nav  ">
                <li className="nav-item active  mt-2 px-1">
                  {isLoggedIn && showCreatePostButton && (
                    <button
                      type="button"
                      data-bs-toggle="modal"
                      href="#exampleModalToggleOne"
                      className="btn btn-outline-success addpostcss"
                    >
                      <i className="bi bi-plus-circle"></i> Add post
                    </button>
                  )}

                  {!isLoggedIn && showCreatePostButton && (
                    <Link to="/Login" className="nav-link">
                      <button
                        type="button"
                        className="btn btn-outline-success addpostbefore "
                      >
                        <i className="bi bi-plus-circle"></i> Add post
                      </button>
                    </Link>
                  )}
                </li>
                {/* Add Product Post  */}
                <li className="nav-item active  mt-2 ">
                  {isLoggedIn && showCreatePostButton && (
                    <button
                      type="button"
                      data-bs-toggle="modal"
                      href="#exampleModalToggleOne1"
                      className="btn btn-outline-danger addpostcss"
                    >
                      <i className="bi bi-plus-circle"></i> Add Product
                    </button>
                  )}

                  {!isLoggedIn && showCreatePostButton && (
                    <Link to="/Login" className="nav-link">
                      <button
                        type="button"
                        className="btn btn-outline-danger addpostbefore "
                      >
                        <i className="bi bi-plus-circle"></i> Add Product
                      </button>
                    </Link>
                  )}
                </li>
                {/* cart */}
                <li className="nav-item mt-1 ">
                  <Link to="#" className="nav-link active" aria-current="page">
                    <i className="bi bi-cart4 cart_bagmedia  fs-5"></i>
                  </Link>
                </li>

                {/* profilepage and logout */}

                {isLoggedIn && (
                  <>
                    <li className="nav-item mt-3 px-1">
                      <img
                        src={Goldcoinss}
                        className="img-fluid coinsgoldcsss"
                        alt=""
                      />
                    </li>
                    <li className="nav-item mt-1 px-2">
                      <button className=" coins_css_lol fw-bolder ">
                        <div style={{ color: "red" }}>
                          {profileDetails && profileDetails.coins}
                        </div>{" "}
                        Earned Coins
                      </button>
                    </li>
                    <li className="nav-item dropdown mt-2">
                      <div className="dropdown ">
                        <>
                          <img
                            src={profileImage}
                            alt="Profile"
                            style={{
                              width: "37px",
                              height: "37px",
                              borderRadius: "50%",
                              position: "relative",
                              border: "2px solid lightgreen",
                            }}
                            className="dropdown-toggle profileimg_header"
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          />
                          {/* Adding the green dot */}
                          <span
                            style={{
                              position: "absolute",
                              bottom: "3px",
                              right: "1px",
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              backgroundColor: "lightgreen", // Change to lightgreen
                              border: "1px solid white", // Optional: add a border for better visibility
                              display: "inline-block",
                            }}
                          ></span>

                          <div
                            className="dropdown-menu mt-3 "
                            style={{ border: "1px solid lightgreen" }}
                            aria-labelledby="navbarDropdown userprofilecolor_css"
                          >
                            <Link className="dropdown-item " to="/User_Profile">
                              <i class="bi bi-person-circle"></i> User Profile
                            </Link>
                            <Link
                              className="dropdown-item"
                              to="/"
                              onClick={handleLogout}
                            >
                              <i class="bi bi-power "></i> Logout
                            </Link>
                          </div>
                        </>
                      </div>
                    </li>
                  </>
                )}
                {!isLoggedIn ? (
                  <>
                    <li className="nav-item ">
                      <Link to="/Login" className="nav-link">
                        <button
                          type="button"
                          className=" btn btn-success fw-bold loginbuttoncsss "
                        >
                          Login
                        </button>
                      </Link>
                    </li>
                    <li className="nav-item ">
                      <Link to="/Sign_Up" className="nav-link">
                        <button
                          type="button"
                          className=" btn btn-danger fw-bold  loginbuttoncsss "
                        >
                          SignUp
                        </button>
                      </Link>
                    </li>
                  </>
                ) : null}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
