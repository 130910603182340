import React from "react";
import Marquee from "react-fast-marquee";

const Patners_Growth_6 = () => {
  return (
    <>
      <div className="container mt-5">
        <div className="AS_SEEN_TITLE d-flex justify-content-center ">
          <h1 className="fw-bold mb-3 TC_COL_4">Our Partners in Growth</h1>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 ">
              <Marquee
                speed={100}
                direction="right"
                gradient={false}
                pauseOnHover={true}
                loop={0}
                delay={0}
                play={true}
                reverse={false}
                pauseOnClick={true}
                className="custom-marquee"
                autoFill={true}
              >
                <div className="AS_SEEN_BANNER_CONTAINER d-flex">
                  <div className="AS_SEEN_BANNER">
                    <div className="AS_SEEN_BANNER_IMG_CONTAINER">
                      <img
                        src="https://pixalivegroup.com/assets/images/division-logo/pixlive-labs.png"
                        alt="Logo One"
                      />
                    </div>
                  </div>
                  <div className="AS_SEEN_BANNER">
                    <div className="AS_SEEN_BANNER_IMG_CONTAINER">
                      <img
                        src="https://pixalivegroup.com/assets/images/division-logo/pcs.png"
                        alt="Logo Two"
                      />
                    </div>
                  </div>
                  <div className="AS_SEEN_BANNER">
                    <div className="AS_SEEN_BANNER_IMG_CONTAINER">
                      <img
                        src="https://pixalivegroup.com/assets/images/division-logo/pixaliveventures.png"
                        alt="Logo Three"
                      />
                    </div>
                  </div>
                  <div className="AS_SEEN_BANNER">
                    <div className="AS_SEEN_BANNER_IMG_CONTAINER">
                      <img
                        src="https://pixalivegroup.com/assets/images/division-logo/pixlive-labs.png"
                        alt="Logo Four"
                      />
                    </div>
                  </div>
                </div>
              </Marquee>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Patners_Growth_6;
