// store.js
// store.js
import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducer';
import { thunk } from 'redux-thunk';

// Add any middleware you need, such as thunkMiddleware for handling asynchronous actions
const middleware = applyMiddleware(thunk);

const store = createStore(rootReducer, middleware);

export default store;

