import React, { useState } from "react";
import "./Farms_Emi_Calc.css";
import PieChart from "./Pie_Chart";

const Farms_Emi_Calc = () => {
  const [amount, setAmount] = useState(1000); // Default value set to 1000

  const handleInputChange = (e) => {
    setAmount(e.target.value);
  };

  const handleSliderChange = (e) => {
    setAmount(e.target.value);
  };

  const totalPayment = amount * 10;
  const totalReturns = totalPayment * 0.18;

  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <span className="TC_COL_1 FARMS_EMI_CALC_TITLE">
              How Easy Farms Purchase Scheme Works?
            </span>
          </div>
        </div>
      </div>

      {/* EMI_CALC_MAIN_CONTAINER */}
      <div className="container-fluid mt-3">
        <div className="row d-flex align-items-center">
          {/* LEFT_CONTAINER */}
          <div className="col-12 col-lg-5 d-flex justify-content-center">
            <img
              src="https://www.joyalukkas.in/media/wysiwyg/Group_9240.png?auto=webp&format=png&quality=85"
              alt=""
              className="img-fluid"
            />
          </div>

          {/* RIGHT_CONTAINER */}
          <div className="col-12 col-lg-4">
            <div className="FARMS_EMI_CALC_SUBTITLE">
              <span>Easy Farms Investment Calculator</span>
            </div>
            <div className="FARMS_EMI_CALC_KEY_LINE mt-2">
              <span>Slide or enter monthly installment amount</span>
            </div>

            <div className="SEARCH_INPUT_CONTAINER d-flex gap-2 mt-3">
              <span className="">₹</span>
              <input
                type="number"
                value={amount}
                onChange={handleInputChange}
                placeholder="Type or Slide Amount"
                className=""
              />
              <span className="SEARCH_INPUT_CHECK">CHECK</span>
            </div>
            <div className="SLIDER_CONTAINER mt-3">
              <input
                type="range"
                min="1000"
                max="1000000"
                step="1000"
                value={amount}
                onChange={handleSliderChange}
                className="slider w-100"
              />
            </div>

            <div className="CALC_DETAILS_1 d-flex gap-2 justify-content-between mt-3">
              <div className="d-flex flex-row gap-2">
                <span className="CALC_DETAILS_TITLE_COLOUR_1 mt-1"> </span>
                <div className="d-flex flex-column">
                  <span className="CALC_DETAILS_TITLE">Your Total Payment</span>
                  <span className="CALC_DETAILS_SUBTITLE">
                    (Period of 10 months)
                  </span>
                </div>
              </div>
              <div className="TOTAL_PAYMENT_VALUE">
                <span>₹ {totalPayment}</span>
              </div>
            </div>

            <div className="CALC_DETAILS_2 d-flex gap-2 justify-content-between mt-3">
              <div className="d-flex flex-row gap-2">
                <span className="CALC_DETAILS_TITLE_COLOUR_2 mt-1"> </span>
                <div className="d-flex flex-column">
                  <span className="CALC_DETAILS_TITLE">Your Total Returns</span>
                  <span className="CALC_DETAILS_SUBTITLE">
                    (upto 18% off on making charges)
                  </span>
                </div>
              </div>
              <div className="TOTAL_RETURNS_VALUE">
                <span>₹ {totalReturns.toFixed(0)}</span>
              </div>
            </div>
          </div>

          {/* PIE_CHART */}
          <div className="col-12 col-lg-3">
            <PieChart totalPayment={totalPayment} totalReturns={totalReturns} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Farms_Emi_Calc;
