import React, { useEffect, useRef, useState } from 'react';
import Empty from '../../../src/Pages/Category/Assests/card-slide.png';
import './Category.css';
import { FaSearch } from "react-icons/fa";
import { BiImage } from "react-icons/bi"
import axios from 'axios';
import { Categorydropdown, CreateCategory } from '../../api/category';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { getconsumerId } from '../../Utils/Storage';
import { uploadFile } from "../../Utils/FileUpload";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
const Category = () => {

  const [categorydropdown, setCategorydropdown] = useState([]);
  const mainRef = useRef(null);
  const secondRef = useRef(null);
  const thirdRef = useRef(null);
  const navigate = useNavigate();
  const consumerId = getconsumerId(null);
  // const [mainPhoto, setMainPhoto] = useState(null);
  // const [secondPhoto, setSecondPhoto] = useState(null);
  // const [thirdPhoto, setThirdPhoto] = useState(null);
  // const handleMainPhotoChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       setMainPhoto(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  // // Similar functions for second and third photos
  // const handleSecondPhotoChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       setSecondPhoto(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  // const handleThirdPhotoChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       setThirdPhoto(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };
  useEffect(() => {
    CategoryList()
  }, [])
  const CategoryList = async () => {
    try {
      const res = await Categorydropdown()
      console.log("dropdown", res.data.result)
      const data = res.data.result
      setCategorydropdown(data);
    } catch (error) {

    }
  }
  const initialStateInputs = {
    consumerId: consumerId,
    modelType: "SellerId",
    title: "",
    farms: "",
    photo1: "",
    photo2: "",
    photo3: "",
    subCategorys: "",
    mobileNumber: "",
    location: "",
    price: "",
    currency: "",
    unitOfMeasurement: "",
    productQuantity: "",
    Description: "",

  };

  const initialStateErrors = {
    consumerId: { required: false, valid: false },
    modelType: { required: false, valid: false },
    title: { required: false, valid: false },
    farms: { required: false, valid: false },
    photo1: { required: false, valid: false },
    photo2: { required: false, valid: false },
    photo3: { required: false, valid: false },
    subCategorys: { required: false, valid: false },
    mobileNumber: { required: false, valid: false },
    location: { required: false, valid: false },
    price: { required: false, valid: false },
    currency: { required: false, valid: false },
    unitOfMeasurement: { required: false, valid: false },
    productQuantity: { required: false, valid: false },
    Description: { required: false, valid: false },
  };


  const [inputs, setInputs] = useState(initialStateInputs);
  const [errors, setErrors] = useState(initialStateErrors);
  const [submitted, setSubmitted] = useState(false);
  const handleInputs = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
    if (submitted) {
      const newError = handleValidation({
        ...inputs,
        [e.target.name]: e.target.value,
      });
      setErrors(newError);
    }
  };
  const handleValidation = (data) => {
    let error = { ...initialStateErrors }; // Creating a copy of initialStateErrors

    if (data.userId === "") {
      error.sellerId.required = true;
    }
    if (data.modelType === "") {
      error.modelType.required = true;
    }
    if (data.title === "") {
      error.title.required = true;
    }
    if (data.farms === "") {
      error.farms.required = true;
    }
    if (data.photo1 === "") {
      error.photo1.required = true;
    }
    if (data.photo2 === "") {
      error.photo2.required = true;
    }
    if (data.photo3 === "") {
      error.photo3.required = true;
    }
    if (data.subCategorys === "") {
      error.subCategorys.required = true;
    }
    if (data.mobileNumber === "") {
      error.mobileNumber.required = true;
    }
    if (data.location === "") {
      error.location.required = true;
    }
    if (data.price === "") {
      error.price.required = true;
    }
    if (data.currency === "") {
      error.currency.required = true;
    }
    if (data.unitOfMeasurement === "") {
      error.unitOfMeasurement.required = true;
    }
    if (data.productQuantity === "") {
      error.productQuantity.required = true;
    }
    if (data.Description === "") {
      error.Description.required = true;
    }

    return error;
  };

  const handleErrors = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const prop = obj[key];
        if (prop.required === true || prop.valid === true) {
          return false;
        }
      }
    }
    return true;
  };

  const handleFileInputs1 = (e) => {
    const file = e?.target?.files[0];
    const folder = "category/photo1/";
    if (file) {
      uploadFile(file, folder)
        .then((res) => {
          const image = res?.Location;
          setInputs({ ...inputs, photo1: image });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleFileInputs2 = (e) => {
    const file = e?.target?.files[0];
    const folder = "category/photo2/";
    if (file) {
      uploadFile(file, folder)
        .then((res) => {
          const image = res?.Location;
          setInputs({ ...inputs, photo2: image });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleFileInputs3 = (e) => {
    const file = e?.target?.files[0];
    const folder = "category/photo3/";
    if (file) {
      uploadFile(file, folder)
        .then((res) => {
          const image = res?.Location;
          setInputs({ ...inputs, photo3: image });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    const newError = handleValidation(inputs);
    setErrors(newError);
    setSubmitted(true);
    console.log("input", inputs);
    if (handleErrors(newError)) {

      CreateCategory(inputs)
        .then((res) => {
          toast.success(res?.data?.message);
          if (res?.data?.message === "Saved Successfully") {
            navigate("/");
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };


  return (
    <div className='category_page FONT_1_POPPINS'>
      <Header />
      <div className='container-fluid category_bg_img '>
        <div className='row mt-5 pt-5'>
          <div className='col-md-1'></div>
          <div className='col-md-5  pt-5 '>
            <h1 >Welcome To <br />Product Listening</h1>
            <p >When adding a product to sell at farmsell,we encourage you to provide as many details as possible. For the buyer to have sufficient information to make an informed decision</p>
          </div>
        </div>
        
      </div>
      <div className='container-fluid mt-5'>
        <div className='row px-4'>
          <div className='col-md-2 '>
            <h5>Photos</h5>
            <p>Only 3 photos are required and the first picture willbe displayed</p>
          </div>

          <div className='col-md-7 side_wi pb- pb-5'>
            <div className="row">
              <div className="col-md-4">
                {/* Main photo */}
                <label htmlFor="mainFile" className="card">
                  <input
                    ref={mainRef}
                    type="file"
                    id="mainFile"
                    className="d-none img-fluid"
                    accept="image/*"
                    onChange={handleFileInputs1}
                    name='photo1'
                  />
                  {inputs.photo1 ? (
                    <div className="card p-3">

                      <img
                        src={inputs.photo1}
                        alt="Main"
                        className="img-fluid"
                        style={{ width: "100%", height: "16vh", }}
                      />
                      <div className='text-center mt-2 text-dark fw-bold'>Main Photo</div>

                    </div>
                  ) : (
                    <div className="card">
                      <div className="card-body p-5">
                        <BiImage size={48} />
                        <h5 className="card-title">Main Photo</h5>
                      </div>
                    </div>
                  )}
                </label>
                {errors.photo1.required ? (
                  <span className="text-danger form-text d-flex mt-4 justify-content-center">
                    This field is required.
                  </span>
                ) : errors.photo1.valid ? (
                  <span className="text-danger form-text d-flex mt-4 justify-content-center">
                    select a mainphoto
                  </span>
                ) : null}
              </div>
              <div className='col-md-4'>
                {/* 2nd photo */}
                <label htmlFor="secondFile" className="card mt-3 mt-md-0">
                  <input
                    ref={secondRef}
                    type="file"
                    id="secondFile"
                    className="d-none img-fluid"
                    accept="image/*"
                    onChange={handleFileInputs2}
                    name='photo2'
                  />
                  {inputs.photo2 ? (
                    <div className="card p-3">

                      <img
                        src={inputs.photo2}
                        alt="Second"
                        className="img-fluid"
                        style={{ width: "100%", height: "16vh", }}
                      />
                      <div className='text-center mt-2 text-dark fw-bold'>2nd Photo</div>
                    </div>
                  ) : (
                    <div className="card">
                      <div className="card-body p-5">
                        <BiImage size={48} />
                        <h5 className="card-title">2nd Photo</h5>
                      </div>
                    </div>
                  )}
                </label>
                {errors.photo2.required ? (
                  <span className="text-danger form-text d-flex mt-4 justify-content-center">
                    This field is required.
                  </span>
                ) : errors.photo2.valid ? (
                  <span className="text-danger form-text d-flex mt-4 justify-content-center">
                    select a 2ndphoto
                  </span>
                ) : null}
              </div>
              <div className='col-md-4'>
                {/* 3rd photo */}
                <label htmlFor="thirdFile" className="card mt-3 mt-md-0">
                  <input
                    ref={thirdRef}
                    type="file"
                    id="thirdFile"
                    className="d-none"
                    accept="image/*"
                    onChange={handleFileInputs3}
                    name='photo3'
                  />
                  {inputs.photo3 ? (
                    <div className="card p-3">

                      <img
                        src={inputs.photo3}
                        alt="Third"
                        className="img-fluid"
                        style={{ width: "100%", height: "16vh", }}
                      />
                      <div className='text-center mt-2 text-dark fw-bold'>3rd Photo</div>
                    </div>
                  ) : (
                    <div className="card">
                      <div className="card-body p-5">
                        <BiImage size={48} />
                        <h5 className="card-title">3rd Photo</h5>
                      </div>
                    </div>
                  )}
                </label>
                {errors.photo3.required ? (
                  <span className="text-danger form-text d-flex mt-4 justify-content-center">
                    This field is required.
                  </span>
                ) : errors.photo3.valid ? (
                  <span className="text-danger form-text d-flex mt-4 justify-content-center">
                    select 3rdphoto
                  </span>
                ) : null}
              </div>
            </div>
          </div>

        </div>
        <div className='row px-4 pb-5 title_contents_bg'>

          <div className='col-md-12 py-3'>
            <h5 className=''>Title</h5>
          </div>
          <div className='col-md-3'></div>
          <div className='col-md-5 input-group-lg '>
            <div class="input-group mb-3">
              <input type="text" className="form-control select_tag" style={{ backgroundColor: '#F4F4F4' }} placeholder="Title" aria-label="Recipient's username" aria-describedby="button-addon2" onChange={(e) => handleInputs(e)} value={inputs.title} name='title' />
            </div>
            {errors.title.required ? (
              <span className="text-danger form-text d-flex mt-4 justify-content-center">
                This field is required.
              </span>
            ) : errors.title.valid ? (
              <span className="text-danger form-text d-flex mt-4 justify-content-center">
                Enter a title
              </span>
            ) : null}
          </div>
          <div className='col-md-4'></div>

          <div className='col-md-12 pt-5'>
            <h5>Category</h5>
          </div>
          <div className='col-md-5   pt-5'>
            <select className='form-select form-select-lg   select_tag' value={inputs.farms} onChange={(e) => handleInputs(e)} name='farms' style={{ backgroundColor: '#F4F4F4' }}>

              <option value='Select category' >Select category</option>
              {categorydropdown.map((item, index) => (
                <option key={index} value={item} >{item}</option>
              ))}
            </select>
            {errors.farms.required ? (
              <span className="text-danger form-text d-flex mt-4 justify-content-center">
                This field is required.
              </span>
            ) : errors.farms.valid ? (
              <span className="text-danger form-text d-flex mt-4 justify-content-center">
                Select a category
              </span>
            ) : null}
          </div>
          <div className='col-md-5 input-group-lg pt-5'>
            <input type="text" class="form-control" placeholder='Slect sub-category' style={{ backgroundColor: '#F4F4F4' }} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg" onChange={(e) => handleInputs(e)} value={inputs.subCategorys} name='subCategorys' />
            {
              errors.subCategorys.required ? (
                <span className="text-danger form-text d-flex mt-4 justify-content-center">
                  This field is required.
                </span>
              ) : errors.subCategorys.valid ? (
                <span className="text-danger form-text d-flex mt-4 justify-content-center">
                  Select a sub-category
                </span>
              ) : null
            }

          </div>
          <div className='col-md-5  pt-5'>
            <h5>Phone Number</h5>
            <p><b>Tips:</b><p className='text-muted'>Lorem Ipsum has been the industry standard dummy text since the 1500s, when an unknown printer took</p></p>
            <div class="input-group input-group-lg">
              <span class="input-group-text" id="inputGroup-sizing-lg" style={{ backgroundColor: '#F4F4F4' }}><b>+91 -</b></span>
              <input type="text" class="form-control" placeholder='12345678900' style={{ backgroundColor: '#F4F4F4' }} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg" onChange={(e) => handleInputs(e)} value={inputs.mobileNumber} name='mobileNumber' />
            </div>
            {
              errors.subCategorys.required ? (
                <span className="text-danger form-text d-flex mt-4 justify-content-center">
                  This field is required.
                </span>
              ) : errors.subCategorys.valid ? (
                <span className="text-danger form-text d-flex mt-4 justify-content-center">
                  Select a sub-category
                </span>
              ) : null
            }

          </div>
          <div className='col-md-5  input-group-lg pt-5'>
            <h5>Location</h5>
            <p><b>Tips:</b><p className='text-muted'>Make sure your location is on for automatic location detection. People also use location to search for products.</p></p>
            <input type="text" class="form-control" placeholder='Search location' style={{ backgroundColor: '#F4F4F4' }} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg" onChange={(e) => handleInputs(e)} value={inputs.location} name='location' />
            {
              errors.location.required ? (
                <span className="text-danger form-text d-flex mt-4 justify-content-center">
                  This field is required.
                </span>
              ) : errors.location.valid ? (
                <span className="text-danger form-text d-flex mt-4 justify-content-center">
                  Enter a location
                </span>
              ) : null
            }

          </div>

          <div className='col-md-5  input-group-lg pt-5'>
            <h5>Pricing</h5>
            <select className='form-select form-select-lg' style={{ backgroundColor: '#F4F4F4' }} onChange={(e) => handleInputs(e)} value={inputs.currency} name='currency'>
              <option value='UGX' >UGX</option>
              <option value='INR'>INR</option>
              <option value='Dollar'>Dollar</option>
              <option value='Euro'>Euro</option>
            </select>
            {
              errors.currency.required ? (
                <span className="text-danger form-text d-flex mt-4 justify-content-center">
                  This field is required.
                </span>
              ) : errors.currency.valid ? (
                <span className="text-danger form-text d-flex mt-4 justify-content-center">
                  Select a currency
                </span>
              ) : null
            }

          </div>
          <div className='col-md-5  input-group-lg pt-5'>
            <h5>Pricing</h5>
            <input type="text" class="form-control" placeholder='e.g 10,000' style={{ backgroundColor: '#F4F4F4' }} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg" onChange={(e) => handleInputs(e)} value={inputs.price} name='price' />
            {
              errors.price.required ? (
                <span className="text-danger form-text d-flex mt-4 justify-content-center">
                  This field is required.
                </span>
              ) : errors.price.valid ? (
                <span className="text-danger form-text d-flex mt-4 justify-content-center">
                  Enter a price
                </span>
              ) : null
            }

          </div>

          <div className='col-md-5  input-group-lg pt-5'>
            <h5>Unit of Measure</h5>
            <p><b>Tips:</b><p className='text-muted'>How are you selling your product? you can entr your own product unit.</p></p>
            <input type="text" class="form-control" placeholder='e.g Kg,tin,litre' style={{ backgroundColor: '#F4F4F4' }} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg" onChange={(e) => handleInputs(e)} value={inputs.unitOfMeasurement} name='unitOfMeasurement' />
            {
              errors.unitOfMeasurement.required ? (
                <span className="text-danger form-text d-flex mt-4 justify-content-center">
                  This field is required.
                </span>
              ) : errors.unitOfMeasurement.valid ? (
                <span className="text-danger form-text d-flex mt-4 justify-content-center">
                  Enter a unit of measurement
                </span>
              ) : null
            }

          </div>
          <div className='col-md-5  input-group-lg pt-5'>
            <h5>Product Quality</h5>
            <p><b>Tips:</b><p className='text-muted'>How are you selling your product? you can entr your own product unit.</p></p>
            <input type="text" class="form-control" placeholder='E.g 100' style={{ backgroundColor: '#F4F4F4', }} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg" onChange={(e) => handleInputs(e)} value={inputs.productQuantity} name='productQuantity' />
            {
              errors.productQuantity.required ? (
                <span className="text-danger form-text d-flex mt-4 justify-content-center">
                  This field is required.
                </span>
              ) : errors.productQuantity.valid ? (
                <span className="text-danger form-text d-flex mt-4 justify-content-center">
                  Enter a product quantity
                </span>
              ) : null
            }

          </div>
          <div className='col-md-12 pt-5 pb-3'>
            <h3>Description</h3>
            <p>Tips: Provide more detailed information about your product. this is a chance to explain why your potential.The noun category has the sense of ordering or sorting. In Biology, the science of taxonomy is all about categorizing all living organisms. It might be an easier task to categorize books in a library according to genre (e.g., fiction, non-fiction, mysteries, etc.) or to categorize the shoes in your closet according to season. </p>
            <textarea className='form-control' placeholder='Enter Description' onChange={(e) => handleInputs(e)} value={inputs.Description} name='Description' >
            </textarea>
            {
              errors.Description.required ? (
                <span className="text-danger form-text d-flex mt-4 justify-content-center">
                  This field is required.
                </span>
              ) : errors.Description.valid ? (
                <span className="text-danger form-text d-flex mt-4 justify-content-center">
                  Enter a description
                </span>
              ) : null
            }

          </div>
          <div className='col-md-2 sell_button'>
            <button className='' onClick={handleSubmit} >All dene, Sell It</button>
          </div>
          <div className='col-md-3 cancel_button'>
            <button className=''>cancel</button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Category