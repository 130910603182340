import API from "./api";
import { Category } from "./endpoints";

export const Categorydropdown = (data) => {
  return API.get(`${Category}/list`, data);
};
export const CreateCategory = (data) => {
  return API.post(`${Category}/`, data);
};

export const getCategory = (data) => {
  return API.get(`${Category}/`, data);
};
