import React, { useState } from "react";
import "./Login.css";
import { Navigate, useNavigate } from "react-router-dom";
import Main_Logo from "../../../Components/Header/newlogofarms.png";
import { consumerLogin } from "../../../api/login";
import { isValidEmail, isValidPassword } from "../../../Utils/Validation";
import { isAuthenticated } from "../../../Utils/Auth";
import { saveToken } from "../../../Utils/Storage";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const initialState = {
    email: "",
    password: "",
  };
  const initialStateErrors = {
    email: { required: false, valid: false },
    password: { required: false, valid: false },
  };
  const [inputs, setInputs] = useState(initialState);
  const [errors, setErrors] = useState(initialStateErrors);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const handleValidation = (data) => {
    let error = initialStateErrors;
    if (data.email === "") {
      error.email.required = true;
    }
    if (data.password === "") {
      error.password.required = true;
    }
    if (!isValidPassword(data.password)) {
      error.password.valid = true;
    }
    if (!isValidEmail(data.email)) {
      error.email.valid = true;
    }
    return error;
  };

  const handleInput = (event) => {
    setInputs({ ...inputs, [event?.target?.name]: event?.target?.value });
    if (submitted) {
      const newError = handleValidation({
        ...inputs,
        [event.target.name]: event.target.value,
      });
      setErrors(newError);
    }
  };

  const handleErrors = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const prop = obj[key];
        if (prop.required === true || prop.valid === true) {
          return false;
        }
      }
    }
    return true;
  };

  const handleLogin = (event) => {
    event.preventDefault();
    const newError = handleValidation(inputs);
    setErrors(newError);
    setSubmitted(true); 
    if (handleErrors(newError)) {
      consumerLogin(inputs)
        .then((res) => {
         // console.log("the login api response is",res);
          if (res?.data?.result.loginType === 'Consumer') {
          let token = res?.data?.result?.token;
          let consumerId = res?.data?.result?.ConsumerDetails?._id;
          let loginType = res?.data?.result?.loginType;
          let data = {
            token: token,
            consumerId: consumerId,
            loginType:loginType
            
          };
          saveToken(data);
          if (isAuthenticated()) {
            navigate("/");
          }
          toast.success(res?.data?.message);
        }
        else if(handleErrors(newError))
        {
          toast.success(res?.data?.message);
        
        }
     
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
   
  };

  return (
    <>
      <div className="login_background">
        <div className="container ">
          <div className="d-flex justify-content-center ">
            <form className="bg-white p-5 rounded-5 mt-5">
              {/* ............Heading ..............*/}

              <h3 className=" px-3 py-2 rounded-2 text-center  fw-bold ">
                <img
                  src={Main_Logo}
                  className="img-fluid LOGO_SIZE_ADJUST11 "
                  alt=""
                />
                <br />
              </h3>
              <h3 className=" px-5  text-center ">
                {" "}
                Welcome to Pixalive Farms
              </h3>
              {/*  Login with Google Button */}
              <div class="d-grid gap-2 mt-3  ">
                <button
                  className="btn btn-secondary rounded-5 text-black  fw-bold p-2"
                  type="button"
                  style={{ backgroundColor: "white" }}
                >
                  {/* Google icons styles */}
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    version="1.1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 48 48"
                    enable-background="new 0 0 48 48"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="#FFC107"
                      d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12
	c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24
	c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                    ></path>
                    <path
                      fill="#FF3D00"
                      d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657
	C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                    ></path>
                    <path
                      fill="#4CAF50"
                      d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36
	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                    ></path>
                    <path
                      fill="#1976D2"
                      d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571
	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                    ></path>
                  </svg>{" "}
                  Login with Google
                </button>
              </div>
              <div
                className="text-secondary mb-3 mx-auto"
                style={{
                  width: "75%",
                  height: 15,
                  borderBottom: "1px solid rgb(193, 193, 193)",
                  textAlign: "center",
                }}
              >
                <span
                  style={{
                    fontSize: 10,
                    backgroundColor: "rgb(255, 255, 255)",
                    padding: "0px 10px",
                  }}
                >
                  OR
                </span>
              </div>

              {/* ...............Login Inputs ................... */}

              <label for="email" className="text_color_heading fw-bold mt-4">
                Email <span className="text-danger">*</span>
              </label>
              <br />
              <input
                type="text"
                id="email"
                name="email"
                placeholder="Enter a Email address"
                className="form-control rounded-3 p-2 mt-2  "
                onChange={handleInput}
                value={inputs.email}
              />
              <br />
              <label htmlFor="password" className=" text_color_heading fw-bold">
                Password <span className="text-danger">*</span>
              </label>
              <br />
              <input
                type={"password"}
                name="password"
                placeholder="Enter a Password"
                className="form-control rounded-3 p-2 mt-2 "
                onChange={handleInput}
                value={inputs.password}
              />

              <br />
              <div className="mt-3">
                <a
                  href="/Forget_Password"
                  className="text-decoration-none text_color_heading fw-bold"
                >
                  Forget your Password ?
                </a>
              </div>
              {/* Login Button */}
              <div className="mt-4 d-grid text-center rounded-1">
                <button
                  type="submit"
                  className="btn btn-success px-5 p-2 rounded-5 fw-bold text-white"
                  onClick={handleLogin}
                >
                  Login
                </button>
              </div>

              {/* link to signup */}
              <div className="text-center mt-4 ">
                <p>
                  Don't have an account yet?{" "}
                  <a
                    href="/Sign_Up"
                    className="text-decoration-none  text_color_heading fw-bold"
                  >
                    SignUp
                  </a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
