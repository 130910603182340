import React, { useState } from "react";
import "./All_Collections_Filters.css";
import { IoIosArrowDown } from "react-icons/io";

const All_Collections_Filters = () => {
  const [isActive1, setIsActive1] = useState(false);

  const toggleDropdown = () => {
    setIsActive1(!isActive1);
  };
  const [isActive2, setIsActive2] = useState(false);

  const toggleDropdown2 = () => {
    setIsActive2(!isActive2);
  };
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-2 d-flex gap-3">
          <div>
              <p className="text-success">Filters :</p>
            </div>
            <div className={`dropdown ${isActive1 ? "active" : ""}`}>
              <p className="text-success" onClick={toggleDropdown}>Availablity <IoIosArrowDown /></p>
              <div className="dropdown-content">
              <p className="d-flex justify-content-between fs-6">
                  <span className="fw-bold text-muted">1 Selected</span>
                  <span>
                    <a href="#">Reset</a>
                  </span>
                </p>
                <hr></hr>
                <p>
                  <a class="" href="#">
                    <span className="fs-6">
                      <input type="checkbox" id="check_box_size" /> in Stock (6)
                    </span>
                  </a>
                </p>
                <p>
                  <a class="" href="#">
                    <span className="fs-6">
                      <input type="checkbox" id="check_box_size" /> out of Stock
                      (1)
                    </span>
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
          <div className={`dropdown ${isActive2 ? "active" : ""}`}>
              <p  className="text-success" onClick={toggleDropdown2}>Price <IoIosArrowDown /></p>
              <div className="dropdown-content">
              <p className="d-flex justify-content-between fs-6">
                  {" "}
                  <span className="fw-bold text-muted">
                    The highest price is Rs. 1,600.00
                  </span>
                  <span>
                    <a href="#">Reset</a>
                  </span>
                </p>

                <hr></hr>
                <div className="d-flex justify-content-between">
                
                  <p>
                    <a class="" href="#">
                      <span>
                        <i class="bi bi-currency-dollar text-success"></i>
                        <input type="number" className="w-75 " />
                      </span>
                    </a>
                  </p>
                  <p>
                    <a class="" href="#">
                      <span>
                        <i class="bi bi-currency-rupee text-success"></i>
                        <input type="number" className="w-75" />
                      </span>
                    </a>
                  </p>
                </div>
                
               
              </div>
            </div>
          </div>
          {/* <div className="col-md-2 d-flex gap-3">
          <div>
              <p className="text-success">Filters :</p>
            </div>
            <div class="dropdown_collections">
              <p class="text-success">Availablity <IoIosArrowDown /></p>
              <div class="dropdown_content p-3">
                <p className="d-flex justify-content-between fs-6">
                  <span className="fw-bold text-muted">1 Selected</span>
                  <span>
                    <a href="#">Reset</a>
                  </span>
                </p>
                <hr></hr>
                <p>
                  <a class="" href="#">
                    <span className="fs-6">
                      <input type="checkbox" id="check_box_size" /> in Stock (6)
                    </span>
                  </a>
                </p>
                <p>
                  <a class="" href="#">
                    <span className="fs-6">
                      <input type="checkbox" id="check_box_size" /> out of Stock
                      (1)
                    </span>
                  </a>
                </p>
              </div>
            </div>
          </div> */}
          {/* <div className="col-md-6">
            <div class="dropdown_collections">
              <p class="text-success">Price <IoIosArrowDown /></p>
              <div class="dropdown_content p-3">
             
                <p className="d-flex justify-content-between fs-6">
                  {" "}
                  <span className="fw-bold text-muted">
                    The highest price is Rs. 1,600.00
                  </span>
                  <span>
                    <a href="#">Reset</a>
                  </span>
                </p>

                <hr></hr>
                <div className="d-flex justify-content-between">
                
                  <p>
                    <a class="" href="#">
                      <span>
                        <i class="bi bi-currency-dollar text-success"></i>
                        <input type="number" className="w-75 " />
                      </span>
                    </a>
                  </p>
                  <p>
                    <a class="" href="#">
                      <span>
                        <i class="bi bi-currency-rupee text-success"></i>
                        <input type="number" className="w-75" />
                      </span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-md-1">
            <div>
              <p className="text-success text-wrap">Sort By:</p>
            </div>
          </div>
          <div className="col-md-2">
            <div>
              <select className="options_select text-success">
                <option>Best Selling</option>
                <option>Featured</option>
                <option>Alphabatically A-Z</option>
                <option>Alphabatically Z-A</option>
                <option>Price- low to high</option>
                <option>Price-high to low</option>
                <option>Date-old to new</option>
                <option>Date-new to old</option>
              </select>
            </div>
          </div>
          <div className="col-md-1">
            <div>
              <p className="text-success fs-6">6 Products</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default All_Collections_Filters;
