import React from "react";
import "./Blog_Post.css";
import Hoverimg2 from "./blog_post_assets/blog-posr-2img.jpeg";
import Hoverimg3 from "./blog_post_assets/blog-posr-3img.jpeg";

const Blog_Post = () => {
  return (
    <div className="blog_post_common_component TC_BG_4">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <p className="fs-1 fw-bold ">
              <span className="TC_COL_1">Blog</span>{" "}
              <span className="TC_COL_2">posts</span>
            </p>
          </div>
          <div className="col-md-4 px-4">
            <div className="img_1 ">
              <img
                src="https://amyrafarms.com/cdn/shop/articles/completion_1080_x_900_px.png?v=1687898941&width=720"
                alt="img"
                className="img-fluid "
              />
            </div>
            <p className="fw-bold  TC_COL_1 " id="card_heading">
              Building a Self-Sustainable Organic Modern Farm...
            </p>
            <p className="TC_COL_1 " id="card-para">
              creating a self sustainable organic modern farm is an exciting
              endeavour that combines the principles of sustainable agriculture
              with modern techniques.
            </p>
          </div>
          <div className="col-md-4 px-4 ">
            <div className="img_1 ">
              <img src={Hoverimg2} alt="img" className="img-fluid" />
            </div>
            <p className="fw-bold  TC_COL_1" id="card_heading">
              Beyond Profit: Investing in Sustainable Farmlands
            </p>
            <p className="TC_COL_1" id="card-para">
              The blog explores the dual benefits of investing insustainable
              farmlands.we look at how these...
            </p>
          </div>
          <div className="col-md-4 px-4">
            <div className="img_1 ">
              <img src={Hoverimg3} alt="ss" className="img-fluid" />
            </div>
            <p className="fw-bold  TC_COL_1" id="card_heading">
              Unearth the Potential: The Rise of Organic Farming
            </p>
            <p className="TC_COL_1" id="card-para">
              Dive into the world of organic farming with us in this informative
              peace. explore how this...
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog_Post;
