import React from "react";
import "./Blogs.css";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import Blog_Post from "../../Components/Common/Blog_Post";
import Whats_App_Stick from "../../Components/Common/Whats_App_Stick/Whats_App_Stick";
const Blogs = () => {
  return (
    <>
      <Header />
      <div className="container mt-4 pt-5 FONT_1_POPPINS">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <h1 className="green_blogs_heading">News</h1>
            <div className="zoom_img">
              <div className="zoom-img">
                <img
                  src="https://amyrafarms.com/cdn/shop/articles/completion_1080_x_900_px.png?v=1687898941&width=1500"
                  alt="ss"
                  className="img-fluid"
                  style={{
                    objectFit: "cover",
                    maxHeight: "500px",
                    width: "100%",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="text-center a_tag_building my-3">
            <a href="" className="" alt="building">
              {" "}
              <h3 className="green_blogs_heading fw-none">
                Building a Self-Sustainable Organic Modern Farm...
              </h3>
              <span className="py-5 date_color">JUNE 4,2023</span>
              <p className="green_blogs_heading">
                Creating a self-sustainable organic modern farm is an exciting
                endeavour that combines the <br /> principles of sustainable
                agriculture with modern techniques.
              </p>
            </a>
          </div>
        </div>
        <div className="row">
          <Blog_Post />
        </div>
      </div>
      <Footer />
      <Whats_App_Stick />
    </>
  );
};

export default Blogs;
