import API from "./api";
import { PostEp, ContactEnquiry } from "./endpoints";

export const createPost = (data) => {
  return API.post(`${PostEp}`, data);
};

// export const getFilterPost = (data) => {
//   return API.put(`${PostEp}/getSinglePost`, { params: { _id: data } });
// };
export const getAllPost = (data) => {
  return API.get(`${PostEp}/showPost`, data);
};

export const ContactAPI = (data) => {
  return API.post(`${ContactEnquiry}`, data);
};
