import React from "react";
import "./Seeds_List.css";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";
import Card from "react-bootstrap/Card";
import Whats_App_Stick from "../../../Components/Common/Whats_App_Stick/Whats_App_Stick";
import { Link } from "react-router-dom";

const Seeds_List = () => {
  return (
    <>
      <Header />
      <body className="SEEDS_LIST_BODY py-5">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-12 d-flex justify-content-center  mt-5">
              <h1 className="TC_COL_1 FONT_1_SANS_SERIF TC_BG_3 px-3 py-2 rounded-1 ">
                List of Seeds Products
              </h1>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            {/* -----------------------SEEDS_PRODUCT_1---------------------------------*/}
            <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
              <Card style={{ width: "18rem", height: "23rem" }}>
                <div className="SEED_CARD_IMG d-flex justify-content-center">
                  <Card.Img
                    variant="top"
                    src="https://i.pinimg.com/736x/4a/5e/f0/4a5ef0240be405d409767f2388f28a56.jpg"
                    className="img-fluid"
                    alt="Product_img_1"
                  />
                  <i
                    class="bi bi-save2 TC_BG_3 px-2 py-1 rounded-3"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  ></i>
                </div>

                <Card.Body>
                  <Card.Text>
                    <h5 className="card-title">
                      <p>Rice Seeds</p>
                    </h5>
                    <h5 className="d-flex justify-content-between align-items-center">
                      <span>
                        ₹150/
                        <span className="text-muted fs-6">Kg</span>
                      </span>
                      <span className="TC_BG_2 rounded-3 d-flex align-items-center p-1">
                        <button className="SERVICE_BTN">Request</button>
                      </span>
                    </h5>

                    <Link to="/Rice_PD">
                      <p className="text-decoration-none">
                        <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center mt-5">
                          <button className="btn text-white">View More</button>
                        </span>
                      </p>
                    </Link>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            {/* -----------------------SEEDS_PRODUCT_2---------------------------------*/}
            <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
              <Card style={{ width: "18rem", height: "23rem" }}>
                <div className="SEED_CARD_IMG d-flex justify-content-center">
                  <Card.Img
                    variant="top"
                    src="https://www.shutterstock.com/image-photo/background-big-raw-peeled-almonds-600nw-1036889638.jpg"
                    className="img-fluid"
                    alt="Product_img_1"
                  />
                  <i
                    class="bi bi-save2 TC_BG_3 px-2 py-1 rounded-3"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  ></i>
                </div>

                <Card.Body>
                  <Card.Text>
                    <h5 className="card-title">
                      <p>Almond Seeds</p>
                    </h5>
                    <h5 className="d-flex justify-content-between align-items-center">
                      <span>
                        ₹120/
                        <span className="text-muted fs-6">Kg</span>
                      </span>
                      <span className="TC_BG_2 rounded-3 d-flex align-items-center p-1">
                        <button className="SERVICE_BTN">Request</button>
                      </span>
                    </h5>
                    <Link to="/Rice_PD">
                      <p className="text-decoration-none">
                        <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center mt-5">
                          <button className="btn text-white">View More</button>
                        </span>
                      </p>
                    </Link>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            {/* -----------------------SEEDS_PRODUCT_3---------------------------------*/}
            <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
              <Card style={{ width: "18rem", height: "23rem" }}>
                <div className="SEED_CARD_IMG d-flex justify-content-center">
                  <Card.Img
                    variant="top"
                    src="https://cdn.pixabay.com/photo/2010/12/13/09/51/seed-1716_1280.jpg"
                    className="img-fluid"
                    alt="Product_img_1"
                  />
                  <i
                    class="bi bi-save2 TC_BG_3 px-2 py-1 rounded-3"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  ></i>
                </div>

                <Card.Body>
                  <Card.Text>
                    <h5 className="card-title">
                      <p>Pumpkin Seeds</p>
                    </h5>
                    <h5 className="d-flex justify-content-between align-items-center">
                      <span>
                        ₹120/
                        <span className="text-muted fs-6">Kg</span>
                      </span>
                      <span className="TC_BG_2 rounded-3 d-flex align-items-center p-1">
                        <button className="SERVICE_BTN">Request</button>
                      </span>
                    </h5>
                    <Link to="/Rice_PD">
                      <p className="text-decoration-none">
                        <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center mt-5">
                          <button className="btn text-white">View More</button>
                        </span>
                      </p>
                    </Link>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            {/* -----------------------SEEDS_PRODUCT_4---------------------------------*/}
            <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
              <Card style={{ width: "18rem", height: "23rem" }}>
                <div className="SEED_CARD_IMG d-flex justify-content-center">
                  <Card.Img
                    variant="top"
                    src="https://cdn.pixabay.com/photo/2014/07/02/06/47/wheat-381848_1280.jpg"
                    className="img-fluid"
                    alt="Product_img_1"
                  />
                  <i
                    class="bi bi-save2 TC_BG_3 px-2 py-1 rounded-3"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  ></i>
                </div>

                <Card.Body>
                  <Card.Text>
                    <h5 className="card-title">
                      <p>Wheat Seeds</p>
                    </h5>
                    <h5 className="d-flex justify-content-between align-items-center">
                      <span>
                        ₹100/
                        <span className="text-muted fs-6">Kg</span>
                      </span>
                      <span className="TC_BG_2 rounded-3 d-flex align-items-center p-1">
                        <button className="SERVICE_BTN">Request</button>
                      </span>
                    </h5>
                    <Link to="/Rice_PD">
                      <p className="text-decoration-none">
                        <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center mt-5">
                          <button className="btn text-white">View More</button>
                        </span>
                      </p>
                    </Link>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            {/* -----------------------SEEDS_PRODUCT_5---------------------------------*/}
            <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
              <Card style={{ width: "18rem", height: "23rem" }}>
                <div className="SEED_CARD_IMG d-flex justify-content-center">
                  <Card.Img
                    variant="top"
                    src="https://cdn.pixabay.com/photo/2016/03/05/22/16/coffee-1239226_1280.jpg"
                    className="img-fluid"
                    alt="Product_img_1"
                  />
                  <i
                    class="bi bi-save2 TC_BG_3 px-2 py-1 rounded-3"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  ></i>
                </div>

                <Card.Body>
                  <Card.Text>
                    <h5 className="card-title">
                      <p>Bean Seeds</p>
                    </h5>
                    <h5 className="d-flex justify-content-between align-items-center">
                      <span>
                        ₹70/
                        <span className="text-muted fs-6">Kg</span>
                      </span>
                      <span className="TC_BG_2 rounded-3 d-flex align-items-center p-1">
                        <button className="SERVICE_BTN">Request</button>
                      </span>
                    </h5>
                    <Link to="/Rice_PD">
                      <p className="text-decoration-none">
                        <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center mt-5">
                          <button className="btn text-white">View More</button>
                        </span>
                      </p>
                    </Link>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            {/* -----------------------SEEDS_PRODUCT_6---------------------------------*/}
            <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
              <Card style={{ width: "18rem", height: "23rem" }}>
                <div className="SEED_CARD_IMG d-flex justify-content-center">
                  <Card.Img
                    variant="top"
                    src="https://media.istockphoto.com/id/1309198153/photo/bowl-with-corn-kernels-for-popcorn.jpg?s=612x612&w=0&k=20&c=LWFFGsHmtXO_F9lqp-MD2HF6QftAIJxGpy0I2xxuw30="
                    className="img-fluid"
                    alt="Product_img_1"
                  />
                  <i
                    class="bi bi-save2 TC_BG_3 px-2 py-1 rounded-3"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  ></i>
                </div>

                <Card.Body>
                  <Card.Text>
                    <h5 className="card-title">
                      <p>Corn Seeds</p>
                    </h5>
                    <h5 className="d-flex justify-content-between align-items-center">
                      <span>
                        ₹70/
                        <span className="text-muted fs-6">Kg</span>
                      </span>
                      <span className="TC_BG_2 rounded-3 d-flex align-items-center p-1">
                        <button className="SERVICE_BTN">Request</button>
                      </span>
                    </h5>
                    <Link to="/Rice_PD">
                      <p className="text-decoration-none">
                        <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center mt-5">
                          <button className="btn text-white">View More</button>
                        </span>
                      </p>
                    </Link>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            {/* -----------------------SEEDS_PRODUCT_7---------------------------------*/}
            <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
              <Card style={{ width: "18rem", height: "23rem" }}>
                <div className="SEED_CARD_IMG d-flex justify-content-center">
                  <Card.Img
                    variant="top"
                    src="https://cdn.pixabay.com/photo/2016/02/17/19/14/pea-1205674_1280.jpg"
                    className="img-fluid"
                    alt="Product_img_1"
                  />
                  <i
                    class="bi bi-save2 TC_BG_3 px-2 py-1 rounded-3"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  ></i>
                </div>

                <Card.Body>
                  <Card.Text>
                    <h5 className="card-title">
                      <p>Peas Seeds</p>
                    </h5>
                    <h5 className="d-flex justify-content-between align-items-center">
                      <span>
                        ₹70/
                        <span className="text-muted fs-6">Kg</span>
                      </span>
                      <span className="TC_BG_2 rounded-3 d-flex align-items-center p-1">
                        <button className="SERVICE_BTN">Request</button>
                      </span>
                    </h5>
                    <Link to="/Rice_PD">
                      <p className="text-decoration-none">
                        <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center mt-5">
                          <button className="btn text-white">View More</button>
                        </span>
                      </p>
                    </Link>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            {/* -----------------------SEEDS_PRODUCT_8---------------------------------*/}
            <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
              <Card style={{ width: "18rem", height: "23rem" }}>
                <div className="SEED_CARD_IMG d-flex justify-content-center">
                  <Card.Img
                    variant="top"
                    src="https://cdn.pixabay.com/photo/2018/09/26/00/50/sunday-3703578_1280.jpg"
                    className="img-fluid"
                    alt="Product_img_1"
                  />
                  <i
                    class="bi bi-save2 TC_BG_3 px-2 py-1 rounded-3"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  ></i>
                </div>

                <Card.Body>
                  <Card.Text>
                    <h5 className="card-title">
                      <p>Peanuts Seeds</p>
                    </h5>
                    <h5 className="d-flex justify-content-between align-items-center">
                      <span>
                        ₹120/
                        <span className="text-muted fs-6">Kg</span>
                      </span>
                      <span className="TC_BG_2 rounded-3 d-flex align-items-center p-1">
                        <button className="SERVICE_BTN">Request</button>
                      </span>
                    </h5>
                    <Link to="/Rice_PD">
                      <p className="text-decoration-none">
                        <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center mt-5">
                          <button className="btn text-white">View More</button>
                        </span>
                      </p>
                    </Link>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </body>
      <Footer />
      <Whats_App_Stick />
    </>
  );
};

export default Seeds_List;
