import React, { useState } from "react";
import "./Crop_Disease_solution_List.css";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";
import Card from "react-bootstrap/Card";
import Whats_App_Stick from "../../../Components/Common/Whats_App_Stick/Whats_App_Stick";

const Crop_Disease_Solution_List = () => {
  const [activeTab, setActiveTab] = useState("Tab1");

  const openTab = (tabName) => {
    const tabcontent = document.getElementsByClassName("tabcontent");
    for (let i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    const tablinks = document.getElementsByClassName("tablinks");
    for (let i = 0; i < tablinks.length; i++) {
      tablinks[i].classList.remove("active");
    }
    document.getElementById(tabName).style.display = "block";
    setActiveTab(tabName);
    document.getElementById(tabName + "-tab").classList.add("active");
  };

  // This useEffect ensures that when the component mounts,
  // Tab1 is displayed and marked as active.
  React.useEffect(() => {
    openTab("Tab1");
  }, []);

  return (
    <>
      <Header />
      <div className="cropdisease123">
        <div className="container mt-5 ">
          <div className="row">
            <div className="col-sm-12 col-lg-12 d-flex justify-content-center  mt-5">
              <h1 className="TC_COL_1 FONT_1_SANS_SERIF TC_BG_3 px-3 py-2 rounded-1 ">
                Crop Diseases
              </h1>
            </div>
          </div>
          </div>
    
     <div className="container-fluid">
     <div className="row">
            <div className="col-12 mt-5">
              <div>
                <div className="col-12 text-center">
                  <div className=" ">
                    <span className="mytab_123 ">
                      <button
                        id="Tab1-tab"
                        className={`tablinks ${
                          activeTab === "Tab1" ? "active" : ""
                        }`}
                        onClick={() => openTab("Tab1")}
                        style={{
                          backgroundColor:
                            activeTab === "Tab1" ? " #024846" : "",
                          color: activeTab === "Tab1" ? "white" : "",
                          fontWeight: activeTab === "Tab1" ? "bold" : "",
                        }}
                      >
                        <span className="px-3 py-5 fs-5 ">Blogs</span>
                      </button>
                    </span>

                    <span className="mytab_123 mx-2">
                      <button
                        id="Tab2-tab"
                        className={`tablinks ${
                          activeTab === "Tab2" ? "active" : ""
                        }`}
                        onClick={() => openTab("Tab2")}
                        style={{
                          backgroundColor:
                            activeTab === "Tab2" ? " #024846" : "",
                          color: activeTab === "Tab2" ? "white" : "",
                          fontWeight: activeTab === "Tab2" ? "bold" : "",
                        }}
                      >
                        <span className="px-3 py-5 fs-5">Videos</span>
                      </button>
                    </span>
                  </div>
                </div>

                <div className="">
                  <div id="Tab1" className="tabcontent ">
                    <div className="container-fluid mt-5 ">
                      {/* card 1 */}
                      <div className="row">
                        <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
                          <Card style={{ width: "18rem", height: "23rem" }}>
                            <div className="SEEDLINGS_CARD_IMG d-flex justify-content-center">
                              <Card.Img
                                variant="top"
                                src="https://newagriindia.com/wp-content/uploads/2022/02/Rice-blast.jpg"
                                className="img-fluid"
                                alt="Product_img_1"
                              />
                            </div>

                            <Card.Body>
                              <Card.Text>
                                <h5 className="card-title">
                                  <p>Disease of Paddy</p>
                                </h5>
                                <p>
                                  Rice Blast is a fungal disease caused by
                                  Pyricularia
                                </p>
                                <p className="text-decoration-none">
                                  <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center ">
                                    <button className="btn text-white">
                                      Read More
                                    </button>
                                  </span>
                                </p>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </div>
                        {/* card 2 */}
                        <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
                          <Card style={{ width: "18rem", height: "23rem" }}>
                            <div className="SEEDLINGS_CARD_IMG d-flex justify-content-center">
                              <Card.Img
                                variant="top"
                                src="https://gardenerspath.com/wp-content/uploads/2022/06/Why-Carrots-Crack-Feature.jpg"
                                className="img-fluid"
                                alt="Product_img_1"
                              />
                            </div>

                            <Card.Body>
                              <Card.Text>
                                <h5 className="card-title">
                                  <p>Carrot Cavity Spot</p>
                                </h5>

                                <p>
                                  Rice Blast is a fungal disease caused by
                                  Pyricularia
                                </p>
                                <p className="text-decoration-none">
                                  <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center ">
                                    <button className="btn text-white">
                                      Read More
                                    </button>
                                  </span>
                                </p>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </div>
                        {/* card 3 */}
                        <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
                          <Card style={{ width: "18rem", height: "23rem" }}>
                            <div className="SEEDLINGS_CARD_IMG d-flex justify-content-center">
                              <Card.Img
                                variant="top"
                                src="https://cdn.mos.cms.futurecdn.net/G9XZ4v63WTzTdp5JnfVrEe.jpg"
                                className="img-fluid"
                                alt="Product_img_1"
                              />
                            </div>

                            <Card.Body>
                              <Card.Text>
                                <h5 className="card-title">
                                  <p>Graph bunch rot</p>
                                </h5>

                                <p>
                                  Rice Blast is a fungal disease caused by
                                  Pyricularia
                                </p>
                                <p className="text-decoration-none">
                                  <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center ">
                                    <button className="btn text-white">
                                      Read More
                                    </button>
                                  </span>
                                </p>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </div>
                        {/* card4 */}
                        <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
                          <Card style={{ width: "18rem", height: "23rem" }}>
                            <div className="SEEDLINGS_CARD_IMG d-flex justify-content-center">
                              <Card.Img
                                variant="top"
                                src="https://cdn.mos.cms.futurecdn.net/AfBg7B8PZQ9h4rdRV8snP6.jpg"
                                className="img-fluid"
                                alt="Product_img_1"
                              />
                            </div>

                            <Card.Body>
                              <Card.Text>
                                <h5 className="card-title">
                                  <p>Potato viruses</p>
                                </h5>

                                <p>
                                  Rice Blast is a fungal disease caused by
                                  Pyricularia
                                </p>
                                <p className="text-decoration-none">
                                  <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center ">
                                    <button className="btn text-white">
                                      Read More
                                    </button>
                                  </span>
                                </p>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </div>
                        {/* card 5 */}
                        <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
                          <Card style={{ width: "18rem", height: "24rem" }}>
                            <div className="SEEDLINGS_CARD_IMG d-flex justify-content-center">
                              <Card.Img
                                variant="top"
                                src="https://www.gardentech.com/-/media/project/oneweb/gardentech/images/pest-id/plant-pest/black-mold/black-mold-tomato-stem.jpg"
                                className="img-fluid"
                                alt="Product_img_1"
                              />
                            </div>

                            <Card.Body>
                              <Card.Text>
                                <h5 className="card-title">
                                  <p>Tomato old Mount</p>
                                </h5>

                                <p>
                                Rice Blast is a fungal disease caused by Pyricularia
                                </p>
                                <p className="text-decoration-none">
                                  <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center ">
                                    <button className="btn text-white">
                                      Read More
                                    </button>
                                  </span>
                                </p>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>

                      <br />
                      <br />
                      <br />
                    </div>
                  </div>




                  {/* Tab-2  */}

                  <div id="Tab2" className="tabcontent ">
                    <div className="container-fluid mt-5 ">
                     

                      {/* card 1 -videos */}

                      <div className="row">
                        <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
                          <Card style={{ width: "22rem", height: "25rem" }}>
                            <div className="SEEDLINGS_CARD_IMG d-flex justify-content-center">
                            <iframe
                            width="100%"
                            height="75%"
                            src="https://www.youtube.com/embed/EueZRKL7uMA"
                            className="img-fluid"
                            title="Video Title"
                            frameBorder="0"
                            allowFullScreen
                          ></iframe>
                            </div>

                            <Card.Body>
                              <Card.Text>
                                <h5 className="card-title">
                                  <p>Septoria tomatoes</p>
                                </h5>
                                <p>
                                Septoria leaf spot is caused by the fungus septoria
                                </p>
                                <p className="text-decoration-none ">
                                  <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center mt-4 ">
                                    <button className="btn text-white ">
                                    Play Video
                                    </button>
                                  </span>
                                </p>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </div>
                        {/* card 2 */}
                        <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
                        <Card style={{ width: "22rem", height: "25rem" }}>
                            <div className="SEEDLINGS_CARD_IMG d-flex justify-content-center">
                            <iframe
                            width="100%"
                            height="75%"
                            src="https://www.youtube.com/embed/RSu1who-Bk8"
                            className="img-fluid"
                            title="Video Title"
                            frameBorder="0"
                            allowFullScreen
                          ></iframe>
                            </div>

                            <Card.Body>
                              <Card.Text>
                                <h5 className="card-title">
                                  <p>panicle Disease</p>
                                </h5>

                                <p>
                                  Rice Blast is fungal disease by
                                  Pyricularia in them
                                </p>
                                <p className="text-decoration-none">
                                  <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center mt-4 ">
                                    <button className="btn text-white">
                                      Play Video
                                    </button>
                                  </span>
                                </p>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </div>
                        {/* card 3 */}
                        <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
                        <Card style={{ width: "22rem", height: "25rem" }}>
                            <div className="SEEDLINGS_CARD_IMG d-flex justify-content-center">
                            <iframe
                            width="100%"
                            height="75%"
                            src="https://www.youtube.com/embed/cslAfNxQDlg"
                            className="img-fluid"
                            title="Video Title"
                            frameBorder="0"
                            allowFullScreen
                          ></iframe>
                            </div>

                            <Card.Body>
                              <Card.Text>
                                <h5 className="card-title">
                                  <p>Tomato Disease</p>
                                </h5>

                                <p>
                                Early blight inflection starts at the bottom of the plant.
                                </p>
                                <p className="text-decoration-none">
                                  <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center mt-4">
                                    <button className="btn text-white">
                                    Play Video
                                    </button>
                                  </span>
                                </p>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </div>
                        {/* card4 */}
                        <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
                        <Card style={{ width: "22rem", height: "25rem" }}>
                            <div className="SEEDLINGS_CARD_IMG d-flex justify-content-center">
                            <iframe
                            width="100%"
                            height="75%"
                            src="https://www.youtube.com/embed/EueZRKL7uMA"
                            className="img-fluid"
                            title="Video Title"
                            frameBorder="0"
                            allowFullScreen
                          ></iframe>
                            </div>

                            <Card.Body>
                              <Card.Text>
                                <h5 className="card-title">
                                  <p>Pepper disease</p>
                                </h5>

                                <p>
                                Early blight inflection starts at teh bottom of the plant.
                                </p>
                                <p className="text-decoration-none">
                                  <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center mt-4">
                                    <button className="btn text-white">
                                      Play Video
                                    </button>
                                  </span>
                                </p>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </div>
                        {/* card 5 */}
                        <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
                          <Card style={{ width: "22rem", height: "25rem" }}>
                            <div className="SEEDLINGS_CARD_IMG d-flex justify-content-center">
                            <iframe
                            width="100%"
                            height="75%"
                            src="https://www.youtube.com/embed/fRhQsWX1SL4"
                            className="img-fluid"
                            title="Video Title"
                            frameBorder="0"
                            allowFullScreen
                          ></iframe>
                            </div>

                            <Card.Body>
                              <Card.Text>
                                <h5 className="card-title">
                                  <p>Black mold Tomatoes</p>
                                </h5>

                                <p>
                                Tomatoes is cultivated in Himachal Pradesh
                                </p>
                                <p className="text-decoration-none">
                                  <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center mt-3">
                                    <button className="btn text-white">
                                      Play Video
                                    </button>
                                  </span>
                                </p>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>

                    

                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
          
        </div>
      
      <Footer />
      <Whats_App_Stick />
    </>
  );
};

export default Crop_Disease_Solution_List;
