import React from "react";
import "./Trusted_Brands.css";
import Marquee from "react-fast-marquee";
import TRUST_PIC_1 from "./Assets_Trusted_Brands/TRUST_PIC_1.jpg";
import TRUST_PIC_2 from "./Assets_Trusted_Brands/TRUST_PIC_2.jpg";
import TRUST_PIC_3 from "./Assets_Trusted_Brands/TRUST_PIC_3.png";
import TRUST_PIC_4 from "./Assets_Trusted_Brands/TRUST_PIC_4.png";
import TRUST_PIC_5 from "./Assets_Trusted_Brands/TRUST_PIC_5.png";
import TRUST_PIC_6 from "./Assets_Trusted_Brands/TRUST_PIC_6.png";
import TRUST_PIC_7 from "./Assets_Trusted_Brands/TRUST_PIC_7.png";
import TRUST_PIC_8 from "./Assets_Trusted_Brands/TRUST_PIC_8.png";
import TRUST_PIC_9 from "./Assets_Trusted_Brands/TRUST_PIC_9.png";

const Trusted_Brands = () => {
  return (
    <>
      <body className="TC_BG_4">
        <div className="container pb-5 ">
          <div className="row">
            <div className="col-sm-12 text-center">
              <p className="fs-3 fw-bold">
                <span className="TC_COL_1">Trusted Brands</span>&nbsp;
                <span className="TC_COL_2">in Collaboration</span>
              </p>
            </div>
          </div>

          <div className="row TC_BG_1 py-5 rounded-5">
            <Marquee>
              <div className="col-sm-12 d-flex gap-5 justify-content-around">
                <div className="TRUST_BRAND_1">
                  <img
                    src={TRUST_PIC_1}
                    alt="TRUST_BRAND_1"
                    className="img-fluid"
                  />
                </div>
                <div className="TRUST_BRAND_1">
                  <img
                    src={TRUST_PIC_2}
                    alt="TRUST_BRAND_2"
                    className="img-fluid"
                  />
                </div>
                <div className="TRUST_BRAND_1">
                  <img
                    src={TRUST_PIC_3}
                    alt="TRUST_BRAND_3"
                    className="img-fluid"
                  />
                </div>
                <div className="TRUST_BRAND_1">
                  <img
                    src={TRUST_PIC_4}
                    alt="TRUST_BRAND_4"
                    className="img-fluid"
                  />
                </div>
                <div className="TRUST_BRAND_1">
                  <img
                    src={TRUST_PIC_5}
                    alt="TRUST_BRAND_5"
                    className="img-fluid"
                  />
                </div>
                <div className="TRUST_BRAND_1">
                  <img
                    src={TRUST_PIC_6}
                    alt="TRUST_BRAND_6"
                    className="img-fluid"
                  />
                </div>
                <div className="TRUST_BRAND_1">
                  <img
                    src={TRUST_PIC_7}
                    alt="TRUST_BRAND_7"
                    className="img-fluid"
                  />
                </div>
                <div className="TRUST_BRAND_1">
                  <img
                    src={TRUST_PIC_8}
                    alt="TRUST_BRAND_8"
                    className="img-fluid"
                  />
                </div>
                <div className="TRUST_BRAND_1">
                  <img
                    src={TRUST_PIC_9}
                    alt="TRUST_BRAND_9"
                    className="img-fluid"
                  />
                </div>
                &nbsp;
              </div>
            </Marquee>
          </div>
        </div>
      </body>
    </>
  );
};

export default Trusted_Brands;
