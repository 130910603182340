import React from "react";
import "./Discover_App.css";

const Discover_App = () => {
  return (
    <>
      <body className="BODY_DISCOVER">
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-12 col-lg-6 py-5">
              <p className="TC_COL_1 fw-bold fs-3 pt-3">FOR INDIVIDUALS</p>
              <p className="h1 fw-bold">
                Discover our app & plant trees{" "}
                <span className="TC_COL_2">for free</span>{" "}
              </p>
              <br />
              <p className="fs-5">
                Download Treeapp to plant trees across the world in just a few
                taps. Make your own global impact in less than 60 seconds.
              </p>
              <div className="d-flex ">
                <div>
                  <p className="">
                    <img
                      src="https://www.thetreeapp.org/assets/images/Download%20Treeapp%20on%20App%20Store.png"
                      alt=""
                      className="img-fluid  w-75"
                    />
                  </p>
                  <p>
                    {" "}
                    <img
                      src="https://www.thetreeapp.org/assets/images/Download%20Treeapp%20on%20Google%20Play.png"
                      alt=""
                      className="img-fluid  w-75"
                    />
                  </p>
                </div>
                <div className="d-none d-lg-block">
                  <p>
                    {" "}
                    <img
                      src="https://www.thetreeapp.org/assets/images/Download%20Treeapp%20app.png"
                      alt=""
                      className="img-fluid w-75"
                    />
                  </p>
                </div>
              </div>
              <div>
                <p className="fs-6 TC_COL_2 fw-bold">
                  Available in the UK and Ireland
                </p>
              </div>
            </div>

            <div className="col-12 col-lg-6">
              <img
                src="https://www.thetreeapp.org/assets/images/Treeapp%20app%20interfaces.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </body>
    </>
  );
};

export default Discover_App;
