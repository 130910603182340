import React from "react";
import "./FAQ_Heading.css";

const FAQ_Heading = () => {
  return (
    <div className="faq_heading_page">
      <div clasName="container-fluid">
        <div className="row">
          <div className="col-md-12  text-center bg-success">
            <p className="py-3 fw-bold fs-5 text-white">PURE GREENS BLOGS</p>
          </div>
          <div className="col-md-12 heading_bg text-center py-4  ">
            <p className="fs-1 fw-bold text-white ">
              5 Frequently Asked Farming Questions That
            </p>
            <p className="fs-1 fw-bold text-white ">
              Will Make You See the Industry In a New Light
            </p>
          </div>
          <div className="col-md-8">
          <p>
              There are plenty of frequently asked farming questions out there
              since farming is a significantly more complex system than most
              people realize. To outside observers, the system just works.
              Produce is farmed, harvested, then arrives at the grocery store
              for purchase. However, there are few industries that are as
              complicated as the multinational supply chains that dominate the
              farming landscape.
            </p>
            <p>
              The work we do with{" "}
              <a href="#" className="text-decoration-none text-success">
                {" "}
                container farms
              </a>{" "}
              is even less understood by the public. Why do we put hydroponic
              farms inside shipping containers? Are container farms more
              sustainable than traditional agriculture? Can you profit from
              operating a container farm?
            </p>
            <p>
              In this blog, we’ll address those questions and more, providing
              information on the growing trends affecting agriculture today.
            </p>
            <p className="heading_lines">
              1. What problems does our food system face today?
            </p>
            <p>
              The first frequently asked farming question we get is about the
              problems of our food system. Our modern agriculture system has
              evolved into a system where fewer farmers are growing than ever
              before. Additionally, the rise of commercial farming has pushed
              small farmers off their land, meaning that our food supply relies
              on an ever-shrinking group of multinational corporations.
            </p>
            <p>
              In this environment, supply chains have stretched further and
              further. Peaches travel an average of 1,674 miles between the farm
              and the market. Lettuce and grapes travel even further, with
              distances of 2,055 miles and 2,143 miles, respectively
            </p>
            <p>
              When crops travel that far, nutrient quality pays the price. Most
              produce loses 30 percent of nutrients in just three days after
              harvest.
            </p>
            <p>
              The problems facing agriculture today hurt both farmers and the
              customers they serve. But is there a way that farmers can cut the
              distance from their farm to your table?
            </p>
            <img
              src="https://puregreensaz.com/wp-content/uploads/2020/11/Frequently-Asked-Farm-Questions-2.jpg"
              className="img-fluid"
            />
            <i>
              Hydroponic farming, when compared with most commerical farming
              practices, minimizes the requirement for extensive food
              transportation by locating production facilities in proximity to
              urban areas. This strategy leads to reduced transportation
              expenses and lowers the carbon footprint linked to food
              distribution.
            </i>
            <p className="heading_lines">
              2. Is Hydroponics More Environmentally Friendly Than Traditional
              Farming Methods?
            </p>
            <p>
              Hydroponics is a relatively new arrival on the farming scene.
              Because of this, not many people know the intricacies of this
              impressive technology. A benefit of container farming is that it
              has less of an impact on the environment than large commercial
              farms.
            </p>
            <p>
              One of the key benefits of container farming is water efficiency.
              In hydroponics, plants are grown in a soilless medium and receive
              their nutrients through a carefully controlled water supply. This
              means that water usage is significantly reduced compared to
              conventional farming, where a substantial amount of water is often
              wasted due to runoff and evaporation.
            </p>
            <p>
              Additionally, hydroponic systems can recycle and reuse water
              efficiently, minimizing overall consumption.
            </p>
            <p>
              In contrast, traditional agriculture can strain local water
              resources, leading to issues such as aquifer depletion and water
              pollution from agricultural runoff containing pesticides and
              fertilizers.
            </p>
            <img
              src="https://puregreensaz.com/wp-content/uploads/2020/11/Frequently-Asked-Farm-Questions-1.jpg"
              className="img-fluid"
            />
            <i>
              Cubic farms employ cutting-edge irrigation techniques like
              hydroponics or aeroponics to reduce water consumption. This
              innovative approach not only saves a substantial amount of water
              but also helps tackle water scarcity issues and encourages
              environmentally sustainable practices.
            </i>
            <p className="heading_lines">
              3. Why put hydroponics inside a container?
            </p>
            <p>
              Hydroponics is great on its own, but the operation can be made
              even more efficient. While plants grown in soil have a surrounding
              ecosystem to support them, hydroponic plants are most optimal when
              supported with controlled environment agriculture. That involves
              high-powered air conditioning units, humidifiers, dehumidifiers
              and more.
            </p>
            <p>
              By combining the benefits of these two technologies, farmers can
              grow nutritious, vibrant crops without using chemical pesticides
              and herbicides that have negative impacts on the environment.
            </p>
            <p className="heading_lines">
              4. What types of places are good for container farming?
            </p>
            <p>
              Another frequently asked farming question we recieve is in regard
              to ideal locations for container farms. While there are a variety
              of locations that you can use for container farming, the best
              places have the following three attributes:
            </p>
            <p className="fw-bold fs-5">Access to Cheap Utilities</p>
            <p>
              Availability of essential utilities such as electricity, water
              supply, and waste disposal are crucial for running a hydroponic
              shipping container farm. Hydroponic systems require electricity
              for pumps, lights and environmental controls. Because of this,
              stable access to electricity is imperative.
            </p>
            <p>
              A consistent and reliable water source is also essential for the
              hydroponic nutrient solution. Therefore, a suitable location
              should have easy access to these utilities.
            </p>
            <p className="fw-bold fs-5">Cost-Effectiveness</p>
            <p>
              Evaluate the overall cost of operating a hydroponic shipping
              container farm at the chosen location. This includes
              considerations such as land or rental costs, labor costs, utility
              expenses, transportation costs and additional expenses related to
              local regulation compliance.
            </p>
            <p>
              Balancing these costs with the potential revenue from crop sales
              is crucial for the economic viability of your hydroponic farm.
            </p>
            <p className="fw-bold fs-5">Accessibility/Ease to Market</p>
            <p>
              The location should be easily accessible for transportation and
              delivery of produce and supplies. Adequate space for maneuvering
              and unloading containers is essential. Additionally, consider
              proximity to urban markets or distribution hubs to reduce
              transportation costs and enhance market access.
            </p>
            <p>
              Consider not only the farm’s proximity to local markets, but also
              evaluate the quality of the market’s demand for your intended
              crop. You can be right next door to the grocery store, but that
              doesn’t matter if demand for your produce is low.
            </p>
            <img
              src="https://puregreensaz.com/wp-content/uploads/2020/11/Frequently-Asked-Farm-Questions-3.jpg"
              className="img-fluid"
            />
            <i>
              Hydroponic container farms are like a high-tech greenhouse. They
              have the ability to cultivate crops with grow lights instead of
              sunlight, and can be calibrated to create specific growing
              environments.
            </i>
            <p className="heading_lines">5. Is container farming profitable?</p>
            <p>
              Perhaps the most frequently asked farming question we get is about
              the profitability of container farms. Container farming can be
              profitable, but your farm’s profitability will depend on certain
              factors.
            </p>
            <p>
              A farm is a business, and just like any business, it will fail or
              succeed based on underlying economic factors and the competency of
              leadership.
            </p>
            <p>
              Whether or not your farm is profitable depends on the size,
              location, product, skills, efficiency, local market and quality of
              your farm. Additionally, economic factors that are out of your
              control, like inflation and the ability to access funding sources
              have impacts on your farm’s profitability.
            </p>
            <p className="fs-5">Tips for Achieving Profitability</p>
            <p>
              To run a successful farm, you must focus on identifying a unique
              market where your customers will pay a premium for higher-quality
              fresh produce. Here’s an example that demonstrates that point:
            </p>
            <p>
              Lettuce is a crop that works well in hydroponic systems, growing
              significantly quicker than traditionally grown lettuce. It’s grown
              in abundance in Arizona, but in Alaska, the sale price is
              significantly higher because it’s harder to provide that market
              with fresh lettuce. It’s difficult to find affordable fruits and
              vegetables in Alaska because the state must import them from
              outside. This increases the transportation costs for shippers,
              further increasing the price that consumers pay at the grocery
              store.
            </p>
            <p>
              This situation presents an opportunity for an enterprising
              container farmer, who can set up a container farm and grow lettuce
              to sell to local Alaska grocers and restaurants. Container farms
              enable you to set up operations where consumers are, not where
              fertile soil is.
            </p>
            <img
              src="https://puregreensaz.com/wp-content/uploads/2020/11/Frequently-Asked-Farm-Questions-4.jpg"
              className="img-fluid"
            />
            <i>
              Through efficient use of vertical space and the implementation of
              a climate controlled environment, container farms can attain
              notably greater crop yields per square foot when contrasted with
              conventional farming practices.
            </i>
            <p>
              Selling lettuce at a premium is only one example of how locating a
              farm in a unique market can help in achieving profitability. With
              an expanding global marketplace, opportunities are emerging around
              the world for farmers of all stripes, from traditional growers to
              container farmers and greenhouse operators to benefit.
            </p>
            Interested in starting a farm? Visit our website, or call us for
            more information on getting started with a Pure Greens Container
            Farm.
            <p className="heading_lines">Related Blogs</p>
            <ul>
              <li>
                <a href="#" className="text-decoration-none text-success">
                  Are Hydroponic Crops More Nutritious?
                </a>
              </li>
              <li>
                <a href="#" className="text-decoration-none text-success">
                  What Are Modular Farms?
                </a>
              </li>
              <li>
                <a href="#" className="text-decoration-none text-success">
                  Why Restaurant Chefs Should Consider Container Farming?
                </a>
              </li>
              <li>
                <a href="#" className="text-decoration-none text-success">
                  VIDEO: Container Farming Experience | A Day in the Life of a
                  Pure Greens Grower
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-4  ">
            <p className="fs-5 text-success fw-bold">MORE CONTAINER FARM STORIES</p>
            <div className='row py-3 px-2'>
             
             <div className="col-lg-4 col-md-12">
                <img src='https://puregreensaz.com/wp-content/uploads/2023/12/summer-camp-activites-4-300x200.jpg' className="img-fluid"/>
               
              </div>
              <div className="col-lg-6">
              <p className="fs-6 text-success">Container Farms for Summer Camp Activities</p>
              <small>Read More »</small>
              </div>
            </div>
            <div className='row py-3 px-2'>
             
             <div className="col-lg-4 col-md-12">
                <img src='https://puregreensaz.com/wp-content/uploads/2023/12/shad-arefin-sanchoy-AbIiPjvu5z0-unsplash-300x200.jpg' className="img-fluid"/>
               
              </div>
              <div className="col-lg-6 ">
              <p className="fs-6 text-success">Ultimate Guide to Pesticides</p>
              <small>Read More »</small>
              </div>
            </div>
            <div className='row py-3 px-2'>
             
             <div className="col-lg-4 col-md-12">
                <img src='https://puregreensaz.com/wp-content/uploads/2023/12/Custom-Container-Farms-1-300x200.jpg' className="img-fluid"/>
               
              </div>
              <div className="col-lg-6">
              <p className="fs-6 text-success">A Green Growing Revolution: Custom-Built Hydroponic Container Farms</p>
              <small>Read More »</small>
              </div>
            </div>
            <div className='row py-3 px-2'>
             
             <div className="col-lg-4 col-md-12">
                <img src='https://puregreensaz.com/wp-content/uploads/2023/11/Hydroponic-Non-Crop-7-300x200.jpg' className="img-fluid"/>
               
              </div>
              <div className="col-lg-6">
              <p className="fs-6 text-success">Can This Stone Save Your Crops from Drowning?</p>
              <small>Read More »</small>
              </div>
            </div>
            <div className='row py-3 px-2'>
             
             <div className="col-lg-4 col-md-12">
                <img src='https://puregreensaz.com/wp-content/uploads/2020/10/20-10-07-Stacked-Units-01-1-300x200.jpg' className="img-fluid"/>
               
              </div>
              <div className="col-lg-6">
              <p className="fs-6 text-success">Benefits of Stackable Hydroponic Containers for Urban Farmers</p>
              <small>Read More »</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ_Heading;
