import React from "react";

const Powerfull_Solution = () => {
  return (
    <>
      <body className="py-5 TC_BG_1">
        <div className="container FONT_1_SANS_SERIF">
          <div className="row text-center">
            <div className="col-12">
              <h1>
                <span className="TC_COL_2">Powerful Solutions&nbsp;</span>

                <span className="text-white">for your Business</span>
              </h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8">
              <img
                src="https://www.thetreeapp.org/assets/images/business-dashboard.png"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-12 col-lg-4 text-center align-self-center">
              <p className="fs-3 TC_COL_2 fw-bold">Your Business Dashboard</p>
              <p className="text-white text-justify">
                Get access to your business dashboard and watch your impact
                grow. Meaningful impact statistics, assets from our planting
                sites, and a host of other features will make your
                sustainability journey with us even easier. Choose a plan now or
                decide later, it’s <b>free</b> to create an account.
              </p>

              <button className="TC_BG_2 HEAD_INVEST_NOW_BTN w-25 rounded-3 p-2 fw-bold">
                <a href="/">Get Started</a>
              </button>
            </div>
          </div>
        </div>

        {/* -------------------------cards--------------------------------------- */}
        {/* <div className="container-fluid FONT_1_SANS_SERIF">
          <div className="row d-flex justify-content-around">
            <div className="col-12 col-lg-3 TC_BG_3 shadow-lg w-25 rounded-1 text-center py-5">
              <img
                src="https://www.thetreeapp.org/assets/images/icons/api-icon.svg"
                alt=""
                className="img-fluid"
              />
              <p className="fs-5 fw-bold fw-500 TC_COL_1 pt-4">
                Tree Planting & Impact APIs
              </p>
              <p>
                Automatically plant a tree for every order, every employee,
                every user, etc. and have the impact at your fingertips!
              </p>
              <u className="pb-5">About the API</u>
            </div>
            <div className="col-12 col-lg-3 TC_BG_3 shadow-lg w-25 rounded-1 text-center py-5">
              <img
                src="https://www.thetreeapp.org/assets/images/icons/zapier-icon.svg"
                alt=""
                className="img-fluid w-100"
              />
              <p className="fs-5 fw-bold fw-500 TC_COL_1 pt-4">
                No-code Integrations
              </p>
              <p>
                Pixclick Farms App is now live on Zapier, & systems allowing you
                to integrate tree planting into your business in few clicks.
              </p>
              <u className="pb-5">About Zapier</u>
            </div>
            <div className="col-12 col-lg-3 TC_BG_3 shadow-lg w-25 rounded-1 text-center py-5">
              <img
                src="https://www.thetreeapp.org/assets/images/icons/tree-counter-widget.svg"
                alt=""
                className="img-fluid"
              />
              <p className="fs-5 fw-bold fw-500 TC_COL_1 pt-1">
                Tree Counter Widget
              </p>
              <p>
                Our tree counter widget is a visual way for you to demonstrate
                your commitment to the environment.
              </p>
              <u className="pb-5">About the widget</u>
            </div>
          </div>
        </div> */}

        {/* <div className="container mt-5 FONT_1_SANS_SERIF TC_BG_3 rounded-1">
          <div className="row text-center py-3">
            <div className="col-12">
              <p className="fs-4 fw-bold">
                <b className="TC_COL_1">Advertise on Pixclick Farms App:</b> get
                your brand in front of our 100k UK app users
              </p>
            </div>
          </div>
        </div> */}
      </body>
    </>
  );
};

export default Powerfull_Solution;
