import React from "react";
import "./ExpertDetails.css";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";
import { FaSolarPanel } from "react-icons/fa";
import { BsSuitcaseLg } from "react-icons/bs";

const ExpertDetails = () => {
  return (
    <>
      <Header />
      <div className="expert-details-container mt-5 pt-5 FONT_1_POPPINS">
        <div className="container">
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12 col-12">
              <h1>Expert Details</h1>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <img
                src="https://images.pexels.com/photos/1542085/pexels-photo-1542085.jpeg?auto=compress&cs=tinysrgb&w=600"
                alt=""
                className="img-fluid"
                style={{
                  width: "400px",
                  height: "530px",
                  borderRadius: "10px",
                }}
              />
            </div>
            <div className="col-lg-8 col-md-6 col-12">
              <h3>Amanda</h3>
              <span className="lead text-muted mb-3">
                Deputy Director Finance, Department of Finance Government of
                Rajasthan.
              </span>
              <br />
              <span className="text-dark text-center fs-5 pt-5"        
              >
                <FaSolarPanel />             
                Solar Energy
              </span>
              <div className="pt-3">
                <h5>About </h5>
                <p>
                  Solar energy, a pivotal player in the realm of renewable
                  resources, harnesses the radiant power emitted by the sun. Its
                  utilization spans a spectrum of applications, from powering
                  residential homes to fueling large-scale industrial
                  operations. At its core, solar energy captures photons emitted
                  by the sun and converts them into electricity through
                  photovoltaic cells or uses them to heat fluids, generating
                  thermal energy.
                </p>
                <hr />
                <ul>
                  <li>
                    <b>Deputy Director Finance</b>{" "}
                    <small>
                      Department of Finance, Govt of Rajasthan currently working
                      here.
                    </small>
                  </li>
                  <li>
                    <p>
                      <b>Nano-technology</b> at centre for covering technologies
                      university of rajasthan 2007.
                    </p>
                    <p>
                      <b>Nano-technology</b> at centre for covering technologies
                      university of rajasthan 2013.
                    </p>
                  </li>
                  <li>Lives In Rajasthan, Churu.</li>
                </ul>
                <hr />
                <h5>Expertise</h5>
                <span className="  p-1 text-center fs-5 ">
                  <span>
                    <FaSolarPanel />
                  </span>{" "}
                  Solar Energy
                </span>
              </div>
            </div>
          </div>
          
        </div>
      </div>  
      <Footer />
    </>
  );
};

export default ExpertDetails;
