import React from "react";
import "./About_Us.css";
import img1 from "./About_Assests/images/icons1img.avif";
import img2 from "./About_Assests/images/icons2img.webp";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Whats_App_Stick from "../../Components/Common/Whats_App_Stick/Whats_App_Stick";

const About_Us = () => {
  return (
    <>
      <Header />
      <body className="mt-4 pt-5 FONT_1_POPPINS">
        <div className="container-fluid ">
          <div className="row">
            <div className=" col-md-12   about_us_bg_img">
              <p className="display-4 text-white fw-bold">About Us</p>
            </div>
          </div>
        </div>
        <div className="container-fluid aboutus_1st_row">
          <div className="container">
            <div className="row pt-5">
              <div className="col-md-7 ">
                <h2 className="DARKGREENCOLOR">
                  About <span className="LIGHTCOLOR">Us</span>
                </h2>

                <img
                  src={"https://pixalivegroup.com/assets/images/environment.png"}
                  className="img-fluid mt-2 rounded-5 w-75 "
                  alt=""
                />
              </div>
              <div className="col-md-5  mt-5 " id="text_color_dark">
                <p>
                  At Pixclick Farms, our thought process is deeply rooted in a
                  vision of sustainable agriculture, community empowerment, and
                  the delivery of premium quality products. We believe in
                  creating a positive impact on the environment, society, and
                  the lives of our customers.
                </p>
                <p>
                  Innovation is a driving force behind Pixclick Farms. We
                  constantly strive to improve and evolve, exploring new
                  agricultural technologies and techniques to enhance
                  productivity and sustainability. By embracing innovation, we
                  aim to stay at the forefront of the industry, delivering
                  cutting-edge solutions that benefit both our customers and the
                  environment.
                </p>
                <div className="arrow_btn text-center mb-5 ">
                  <button type="button" className="btn ">
                    <i class="bi bi-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid dark_green_bg">
          <div className="container ">
            <div className="row">
              <div className="col-md-12">
                <h2 className="WhiteGREENCOLOR mt-5">
                  Experience the Beauty of{" "}
                  <span id="text_color_ligt">Pixclick Farms</span>
                </h2>
                <p className="text-white fs-5">
                  Sustainable agriculture and a commitment to delivering
                  exceptional quality products.
                </p>
              </div>
              <div className="col-md-4 pt-3">
                <img
                  src={"https://pixalivegroup.com/assets/images/rajasekar.jpg"}
                  alt=""
                  className="img-fluid about_us_profile rounded-5 "
                />
                <p className=" mt-4 fs-4 text-center" id="text_color_ligt">
                  Sundaresan RajaSekar
                </p>
                <p className="text-white text-center fw-bold fs-5 ">Founder</p>
                <p className="text-white fw-bold fs-5 text-center ">
                  CEO Pixclick Farms Managed Farm Land
                </p>
              </div>
              <div className="col-md-4 pt-3">
                <img
                  src={
                    "https://pixalivegroup.com/assets/images/team/sathish.jpg"
                  }
                  alt=""
                  className="img-fluid about_us_profile  rounded-5"
                />
                <p className=" text-center mt-4 fs-4 " id="text_color_ligt">
                  Mr. Sathish Kumar
                </p>
                <p className="text-white  text-center fw-bold fs-5">
                  Technical Advisor to CEO
                </p>
                <p className="text-white  text-center fw-bold fs-5">
                  Managed Farm Land
                </p>
              </div>
              <div className="col-md-4 pt-3">
                <img
                  src={
                    "https://pixalivegroup.com/assets/images/team/vignesh.jpg"
                  }
                  alt=""
                  className="img-fluid about_us_profile rounded-5 "
                />
                <p className="text-center mt-4 fs-4" id="text_color_ligt">
                  Mr. Vignesh Dhanasekaran
                </p>
                <p className="text-white text-center  fw-bold fs-5 ">
                  Group Coo
                </p>
                <p className="text-white text-center fw-bold fs-5">
                  Pixclick Farms cafe
                </p>
              </div>
              <div className="col-md-4 pt-3">
                <img
                  src={
                    "https://pixalivegroup.com/assets/images/team/3.jpg"
                  }
                  alt=""
                  className="img-fluid about_us_profile  mt-4 rounded-5"
                />
                <p className="text-center mt-4 fs-4" id="text_color_ligt">
                Mr. Nitesh Jain
                </p>
                <p className="text-white text-center fs-5  fw-bold ">
                Director, Pixalive Platforms
                </p>
                <p className="text-white text-center fs-5 fw-bold ">
                Pixclick Farms cafe
                </p>
              </div>
              <div className="col-md-4 ">
                <img
                  src={
                    "https://pixalivegroup.com/assets/images/team/4.jpg"
                  }
                  alt=""
                  className="img-fluid about_us_profile mt-5 rounded-5"
                />
                <p className="text-center mt-4  fs-4" id="text_color_ligt">
                Mr. Abhishek Jain
                </p>
                <p className="text-white text-center fs-5  fw-bold ">
                  Business Head Pixclick Farms Species
                </p>
                <p className="text-white text-center fs-5 fw-bold ">
                 Farms Managed Farm Land
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid light_background_color">
          <div className="container">
            <div className="row">
              <div className="col-md-12 pt-4">
                <h2
                  className=" fw-bold mt-4 heading_space"
                  id="text_color_ligt"
                >
                  Trusted Brands <br />
                  <span className="" id="text_color_dark">
                    {" "}
                    in Collaboration
                  </span>
                </h2>
              </div>
              <div className="col-md-12 d-flex justify-content-center gap-5  ">
                <img src={img1} alt="" className="img-fluid " />
                <img src={img2} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid TC_BG_1">
          <div className="row text-center">
            <div className="col-md-3"></div>
            <div className="col-lg-6 col-md-12 text-white ">
              <p className="display-4 fw-bold" id="text_color_ligt">
                Heading
              </p>
              <p className="fs-6 text-white">
                <b>
                  Sustainable agriculture, community empowerment, and premium
                  quality
                </b>
                from the foundation of Pixclick Farms' philosophy, guiding their
                commitment to making a positive impact on the environment,
                society, and their valued customers.
              </p>
            </div>
            <div className="col-md-3"></div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-6 ">
                <img
                  src="https://amyrafarms.com/cdn/shop/files/green-plants-grow-in-lines-on-rolling-hills.jpg?v=1687895465"
                  className="img-fluid rounded-3 pb-5"
                />
              </div>
              <div className="col-md-6">
                <p className="fs-5 pt-5 text-white ">
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    class="iconify iconify--heroicons img-fluid ticksize"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="none"
                      stroke="#28edae"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M9 12.75L11.25 15L15 9.75M21 12a9 9 0 1 1-18 0a9 9 0 0 1 18 0Z"
                    ></path>
                  </svg>{" "}
                  Sustainable Agriculture:
                </p>
                <p className="text-white">
                  {" "}
                  Pixclick Farms is deeply committed to sustainable agricultural
                  practices. By adopting eco-friendly techniques, they strive to
                  preserve the health of the land, protect natural resources,
                  and promote a balanced ecosystem.
                </p>
                <p className="fs-5 pt-3 text-white">
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    class="iconify iconify--heroicons img-fluid ticksize"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="none"
                      stroke="#28edae"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M9 12.75L11.25 15L15 9.75M21 12a9 9 0 1 1-18 0a9 9 0 0 1 18 0Z"
                    ></path>
                  </svg>
                  Sustainable Agriculture:
                </p>
                <p className="text-white">
                  {" "}
                  Pixclick Farms is deeply committed to sustainable agricultural
                  practices. By adopting eco-friendly techniques, they strive to
                  preserve the health of the land, protect natural resources,
                  and promote a balanced ecosystem.
                </p>
                <p className="fs-5 pt-3 text-white">
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    class="iconify iconify--heroicons img-fluid ticksize"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="none"
                      stroke="#28edae"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="M9 12.75L11.25 15L15 9.75M21 12a9 9 0 1 1-18 0a9 9 0 0 1 18 0Z"
                    ></path>
                  </svg>{" "}
                  Sustainable Agriculture:
                </p>
                <p className="text-white">
                  {" "}
                  Pixclick Farms is deeply committed to sustainable agricultural
                  practices. By adopting eco-friendly techniques, they strive to
                  preserve the health of the land, protect natural resources,
                  and promote a balanced ecosystem.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Heading */}
      </body>
      <Whats_App_Stick />
      <Footer />
    </>
  );
};

export default About_Us;
