import React from "react";
import "./Banners.css";

const Banners = () => {
  return (
    <div>
      <body className="py-5 BODY_BANNER">
        <div className="container FONT_1_SANS_SERIF">
          <div className="row text-center">
            <div className="col-12">
              <h1>
                <span className="TC_COL_1">Empowering farmers&nbsp;</span>

                <span className="text-white">of all kinds</span>
              </h1>
              <p className="text-white">
                Revolutionizing farming operations for all types of farms
              </p>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row BANNER_IMG_SIZE ">
            <div className="col-sm-12 SCROLL_SIDE_TABLE d-flex justify-content-between">
              <div className="BANNER_IMG_OVERLAY ">
                <img
                  src="https://agrierp.com/wp-content/uploads/2023/03/Row-Crop-Farms.webp"
                  alt=""
                />
                <div className="BANNER_TEXT_OVERLAY">
                  <p className="text-white text-center">Row Crop Farms</p>
                  <span className="text-white ls-wide">
                    Advanced inventory management, allowing farmers to easily
                    manage and track large quantities of bulk crops.
                  </span>
                </div>
              </div>
              <div className="BANNER_IMG_OVERLAY ">
                <img
                  src="https://agrierp.com/wp-content/uploads/2023/03/Livestock-Farms.webp"
                  alt=""
                />
                <div className="BANNER_TEXT_OVERLAY">
                  <p className="text-white text-center">Livestock Farms</p>
                  <span className="text-white ls-wide">
                    Advanced inventory management, allowing farmers to easily
                    manage and track large quantities of bulk crops.
                  </span>
                </div>
              </div>
              <div className="BANNER_IMG_OVERLAY ">
                <img
                  src="https://agrierp.com/wp-content/uploads/2023/03/Vegetable-Farms.webp"
                  alt=""
                />
                <div className="BANNER_TEXT_OVERLAY">
                  <p className="text-white text-center">Vegetable Farms</p>
                  <span className="text-white ls-wide">
                    Advanced inventory management, allowing farmers to easily
                    manage and track large quantities of bulk crops.
                  </span>
                </div>
              </div>
              <div className="BANNER_IMG_OVERLAY ">
                <img
                  src="https://agrierp.com/wp-content/uploads/2023/03/Indoor-Vertical-Farms.webp"
                  alt=""
                />
                <div className="BANNER_TEXT_OVERLAY">
                  <p className="text-white text-center">Indoor Farms</p>
                  <span className="text-white ls-wide">
                    Advanced inventory management, allowing farmers to easily
                    manage and track large quantities of bulk crops.
                  </span>
                </div>
              </div>
              <div className="BANNER_IMG_OVERLAY ">
                <img
                  src="https://agrierp.com/wp-content/uploads/2023/03/Nut-and-Citrus-Farms.webp"
                  alt=""
                />
                <div className="BANNER_TEXT_OVERLAY">
                  <p className="text-white text-center">Citrus Farms</p>
                  <span className="text-white ls-wide">
                    Advanced inventory management, allowing farmers to easily
                    manage and track large quantities of bulk crops.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
};

export default Banners;
