import React, {useState} from "react";
import "./Enquiry.css";
import Header from "../../Components/Header/Header";
import Foot from "../../Components/Footer/Footer";
import SortableComponents from "./Sortable";
import { ContactAPI } from "../../api/PostApi";
import { isValidPhone, isValidEmail } from "../../Utils/Validation";
import { toast } from "react-toastify";

const Enquiry = () => {
  const initialState = {
    mobileNumber: "",
    name: "",
    email: "",
    comments: "",
  };

  const initialStateErrors = {
    mobileNumber: { required: false, valid: false },
    name: { required: false },
    email: { required: false },
    comments: { required: false, valid: false },
  };

  const [inputs, setInputs] = React.useState(initialState);
  const [errors, setErrors] = React.useState(initialStateErrors);
  const [submitted, setSubmitted] = React.useState(false);

  const handleValidation = (data) => {
    const error = { ...initialStateErrors };
    if (data.name === "") {
      errors.name.required = true;
    }
    if (data.email === "") {
      errors.email.required = true;
    }
    if (data.mobileNumber === "") {
      errors.mobileNumber.required = true;
    }
    if (data.email === "") {
      errors.email.required = true;
    }

    if (data.comments === "") {
      errors.comments.required = true;
    }
    if (!isValidEmail(data.email)) {
      errors.email.valid = true;
    }
    if (!isValidPhone(data.mobileNumber)) {
      errors.mobileNumber.valid = true;
    }

    return error;
  };
  const handleErrors = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const prop = obj[key];
        if (prop.required === true || prop.valid === true) {
          return false;
        }
      }
    }
    return true;
  };

  const handleInputs = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
    if (submitted) {
      const newError = handleValidation({
        ...inputs,
        [e.target.name]: e.target.value,
      });
      setErrors(newError);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const newError = handleValidation(inputs);
    setErrors(newError);
    setSubmitted(true);

    if (handleErrors(newError)) {
      ContactAPI(inputs)
        .then((res) => {
          if (res?.data?.message === "Saved Successfully") {
            toast.success(res?.data?.message);
            e.target.reset();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err?.res?.data?.message);
        });
    }
  };

  // location suggestion:

  const [selectedLocation, setSelectedLocation] = useState("");

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
    console.log("Selected Location:", event.target.value);
  };

  const locations = [
    "Diversify your Investments",
    "Earn Fixed and Predictable Returns",
    "Choose across Flexible Tenures"

  ];

  return (
    <>
      <Header />
      <div className="container-fluid  mb-5  mt-5">
        {/* ............................................leftside content................................................. */}
        <div className="container mt-5 pt-5  ">
          <div className="row d-flex flex-wrap shadow-lg  mb-5 p-5">
            <div className="col-lg-6 col-md-12 col-sm-12 ">
              {/* <img
                src="https://insight.kellogg.northwestern.edu/imager/clientcontent/251706/Full_1220_Farmers_Call_center_265dcf20bf86bc91590c49e978b05b5a.jpg"
                alt=""
                className="img-fluid  w-100 "
              /> */}
              <h1 className="fw-bold text-muted ">
                Explore the world of{" "}
                <span className="text-success">
                  Alternative Fixed Income Investments
                </span>{" "}
              </h1>
              <p className="text-muted mt-3">
                Opportunities earlier available to only the ultra-rich, now
                accessible to all.
              </p>
              <div className="row mt-4">
                <div className="col-lg-3">
                  <img
                    src="https://www.altgraaf.com/images/iocn-3.svg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="col-lg-8 ">
                  <h6 className="mt-2">Diversify your Investments</h6>
                  <p className="text-muted small">
                    We offer you the opportunity to diversify your investments
                    across asset classes such as corporate debt, leasing,
                    invoice discounting & revenue-based financing.
                  </p>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-lg-3">
                  <img
                    src="https://www.altgraaf.com/images/iocn-1.svg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="col-lg-8 ">
                  <h6 className="mt-2">Earn Fixed and Predictable Returns</h6>
                  <p className="text-muted small">
                    Predictable returns help you plan your financial goals
                    better. Earn returns ranging from 8% to 20%.
                  </p>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-lg-3">
                  <img
                    src="https://www.altgraaf.com/images/iocn-2.svg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="col-lg-8 ">
                  <h6 className="mt-2">Choose across Flexible Tenures</h6>
                  <p className="text-muted small">
                    Investment opportunities on our platform have tenures
                    ranging from 30 days to 3 years.
                  </p>
                </div>
              </div>
            </div>

            {/* ...........................rightside form............................................................. */}

            <div className="col-lg-6 col-md-6 col-12 ">
              <h1 className="text-center fw-bold text-muted mb-3">
                Enquiry Details
              </h1>
              <h6 className="text-center mt-3">
                Please drop in your details and we will get in touch with you
                shortly.
              </h6>

              <form className="mt-5">
                <div className="col-lg-12 col-sm-12">
                  <div>
                    <label htmlFor="">Mobile Number <span className="text-danger">*</span></label>
                    <input
                      type="tel"
                      name="mobileNumber"
                      className="form-control "
                      placeholder="Enter a mobileNumber"
                      onChange={handleInputs}
                    />
                    {errors.mobileNumber.required ? (
                      <p className="error text-danger">MobileNumber is required</p>
                    ) : null}
                  </div>
                  <div>
                    <label className="mt-3" htmlFor="">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="form-control "
                      placeholder="Enter a Name"
                      onChange={handleInputs}
                    />
                    {errors.name.required ? (
                      <p className="error text-danger">Name is required</p>
                    ) : null}
                  </div>
                  <div>
                    <label className="mt-3" htmlFor="">
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="form-control "
                      placeholder="@EmailAddress"
                      onChange={handleInputs}
                    />
                    {errors.email.required ? (
                      <p className="error text-danger">Email is required</p>
                    ) : null}
                  </div>

                  <div>
                    <label className="mt-3" htmlFor="">
                      When are you planning to invest? 
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="comments"
                      value={selectedLocation}
                      onChange={handleLocationChange}
                    >
                      <option selected>Select Any Option</option>
                      
                      {locations.map((location, index) => (
                    <option key={index} value={location}>
                      {location}
                    </option>
                  ))}
                    </select>
                    {errors.comments.required ? (
                      <p className="error text-danger">Select AnyOption is required</p>
                    ) : null}
                  </div>

                  <div className="mt-5 d-grid ">
                    <button
                      onClick={handleSubmit}
                      className=" btn btn-success  p-3 text-white fw-bold  "
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <SortableComponents />
      <Foot />
    </>
  );
};

export default Enquiry;
