import React from "react";
import "./Seedlings_List.css";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";
import Card from "react-bootstrap/Card";
import Whats_App_Stick from "../../../Components/Common/Whats_App_Stick/Whats_App_Stick";
import { Link } from "react-router-dom";

const Seedlings_List = () => {
  return (
    <>
      <Header />
      <body className="SEEDLINGS_LIST_BODY py-5">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-12 d-flex justify-content-center  mt-5">
              <h1 className="TC_COL_1 FONT_1_SANS_SERIF TC_BG_3 px-3 py-2 rounded-1 ">
                List of Seeds Products
              </h1>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            {/* -----------------------SEEDLINGS_PRODUCT_1---------------------------------*/}
            <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
              <Card style={{ width: "18rem", height: "23rem" }}>
                <div className="SEEDLINGS_CARD_IMG d-flex justify-content-center">
                  <Card.Img
                    variant="top"
                    src="https://media.istockphoto.com/id/1010105320/photo/young-garlic-on-the-ground-freshly-harvested-fresh-harvest.jpg?s=612x612&w=0&k=20&c=K96s6I717nCM2MuXwFfva83ryoUd35RVRu-N5_dAhsU="
                    className="img-fluid"
                    alt="Product_img_1"
                  />
                  <i
                    class="bi bi-save2 TC_BG_3 px-2 py-1 rounded-3"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  ></i>
                </div>

                <Card.Body>
                  <Card.Text>
                    <h5 className="card-title">
                      <p>Garlic</p>
                    </h5>
                    <h5 className="d-flex justify-content-between align-items-center">
                      <span>
                        ₹05/
                        <span className="text-muted fs-6">pcs</span>
                      </span>
                      <span className="TC_BG_2 rounded-3">
                        <button className="btn">Request</button>
                      </span>
                    </h5>

                    <Link to="/Rice_PD">
                      <p>
                        <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center mt-5">
                          <button className="btn text-white">View More</button>
                        </span>
                      </p>
                    </Link>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            {/* -----------------------SEEDLINGS_PRODUCT_2---------------------------------*/}
            <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
              <Card style={{ width: "18rem", height: "23rem" }}>
                <div className="SEEDLINGS_CARD_IMG d-flex justify-content-center">
                  <Card.Img
                    variant="top"
                    src="https://t4.ftcdn.net/jpg/01/43/64/97/360_F_143649787_gqC5N20d9MhXoC1LRGDSbyACrpHR685c.jpg"
                    className="img-fluid"
                    alt="Product_img_1"
                  />
                  <i
                    class="bi bi-save2 TC_BG_3 px-2 py-1 rounded-3"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  ></i>
                </div>

                <Card.Body>
                  <Card.Text>
                    <h5 className="card-title">
                      <p>Onion</p>
                    </h5>
                    <h5 className="d-flex justify-content-between align-items-center">
                      <span>
                        ₹05/
                        <span className="text-muted fs-6">pcs</span>
                      </span>
                      <span className="TC_BG_2 rounded-3">
                        <button className="btn">Request</button>
                      </span>
                    </h5>
                    <Link to="/Rice_PD">
                      <p className="text-decoration-none">
                        <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center mt-5">
                          <button className="btn text-white">View More</button>
                        </span>
                      </p>
                    </Link>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            {/* -----------------------SEEDLINGS_PRODUCT_3---------------------------------*/}
            <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
              <Card style={{ width: "18rem", height: "23rem" }}>
                <div className="SEEDLINGS_CARD_IMG d-flex justify-content-center">
                  <Card.Img
                    variant="top"
                    src="https://www.thespruce.com/thmb/XA4KSN2QuQND5RGPYwnOJ2adCUs=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/grow-mango-seeds-1902625-03-2feda35ea8d9402ab3be40dd4cdd0d5c.jpg"
                    className="img-fluid"
                    alt="Product_img_1"
                  />
                  <i
                    class="bi bi-save2 TC_BG_3 px-2 py-1 rounded-3"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  ></i>
                </div>

                <Card.Body>
                  <Card.Text>
                    <h5 className="card-title">
                      <p>Mango</p>
                    </h5>
                    <h5 className="d-flex justify-content-between align-items-center">
                      <span>
                        ₹80/
                        <span className="text-muted fs-6">pcs</span>
                      </span>
                      <span className="TC_BG_2 rounded-3">
                        <button className="btn">Request</button>
                      </span>
                    </h5>
                    <Link to="/Rice_PD">
                      <p className="text-decoration-none">
                        <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center mt-5">
                          <button className="btn text-white">View More</button>
                        </span>
                      </p>
                    </Link>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            {/* -----------------------SEEDLINGS_PRODUCT_4---------------------------------*/}
            <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
              <Card style={{ width: "18rem", height: "23rem" }}>
                <div className="SEEDLINGS_CARD_IMG d-flex justify-content-center">
                  <Card.Img
                    variant="top"
                    src="https://as2.ftcdn.net/v2/jpg/01/84/51/23/1000_F_184512368_T53Z9XXKhmk3QWXpSsdTcojPtRHa58KO.jpg"
                    className="img-fluid"
                    alt="Product_img_1"
                  />
                  <i
                    class="bi bi-save2 TC_BG_3 px-2 py-1 rounded-3"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  ></i>
                </div>

                <Card.Body>
                  <Card.Text>
                    <h5 className="card-title">
                      <p>Lime</p>
                    </h5>
                    <h5 className="d-flex justify-content-between align-items-center">
                      <span>
                        ₹30/
                        <span className="text-muted fs-6">pcs</span>
                      </span>
                      <span className="TC_BG_2 rounded-3">
                        <button className="btn">Request</button>
                      </span>
                    </h5>
                    <Link to="/Rice_PD">
                      <p className="text-decoration-none">
                        <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center mt-5">
                          <button className="btn text-white">View More</button>
                        </span>
                      </p>
                    </Link>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            {/* -----------------------SEEDLINGS_PRODUCT_5---------------------------------*/}
            <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
              <Card style={{ width: "18rem", height: "23rem" }}>
                <div className="SEEDLINGS_CARD_IMG d-flex justify-content-center">
                  <Card.Img
                    variant="top"
                    src="https://as1.ftcdn.net/v2/jpg/02/50/14/90/1000_F_250149090_D8gziruTx9UCuj4dsQCdXWHOBBHVR9iV.jpg"
                    className="img-fluid"
                    alt="Product_img_1"
                  />
                  <i
                    class="bi bi-save2 TC_BG_3 px-2 py-1 rounded-3"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  ></i>
                </div>

                <Card.Body>
                  <Card.Text>
                    <h5 className="card-title">
                      <p>Tomatoo</p>
                    </h5>
                    <h5 className="d-flex justify-content-between align-items-center">
                      <span>
                        ₹20/
                        <span className="text-muted fs-6">pcs</span>
                      </span>
                      <span className="TC_BG_2 rounded-3">
                        <button className="btn">Request</button>
                      </span>
                    </h5>
                    <Link to="/Rice_PD">
                      <p className="text-decoration-none">
                        <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center mt-5">
                          <button className="btn text-white">View More</button>
                        </span>
                      </p>
                    </Link>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            {/* -----------------------SEEDLINGS_PRODUCT_6---------------------------------*/}
            <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
              <Card style={{ width: "18rem", height: "23rem" }}>
                <div className="SEEDLINGS_CARD_IMG d-flex justify-content-center">
                  <Card.Img
                    variant="top"
                    src="https://as2.ftcdn.net/v2/jpg/04/01/95/93/1000_F_401959393_fohsQ2D4vYxcWjgvhmzkYSa68ShUQwDw.jpg"
                    className="img-fluid"
                    alt="Product_img_1"
                  />
                  <i
                    class="bi bi-save2 TC_BG_3 px-2 py-1 rounded-3"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  ></i>
                </div>

                <Card.Body>
                  <Card.Text>
                    <h5 className="card-title">
                      <p>Pepper</p>
                    </h5>
                    <h5 className="d-flex justify-content-between align-items-center">
                      <span>
                        ₹15/
                        <span className="text-muted fs-6">pcs</span>
                      </span>
                      <span className="TC_BG_2 rounded-3">
                        <button className="btn">Request</button>
                      </span>
                    </h5>
                    <Link to="/Rice_PD">
                      <p className="text-decoration-none">
                        <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center mt-5">
                          <button className="btn text-white">View More</button>
                        </span>
                      </p>
                    </Link>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            {/* -----------------------SEEDLINGS_PRODUCT_7---------------------------------*/}
            <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
              <Card style={{ width: "18rem", height: "23rem" }}>
                <div className="SEEDLINGS_CARD_IMG d-flex justify-content-center">
                  <Card.Img
                    variant="top"
                    src="https://as1.ftcdn.net/v2/jpg/01/02/14/88/1000_F_102148866_ybxz79gL5lOvALEXbjd9YrckT5KtQ1WE.jpg"
                    className="img-fluid"
                    alt="Product_img_1"
                  />
                  <i
                    class="bi bi-save2 TC_BG_3 px-2 py-1 rounded-3"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  ></i>
                </div>

                <Card.Body>
                  <Card.Text>
                    <h5 className="card-title">
                      <p>Cocunut</p>
                    </h5>
                    <h5 className="d-flex justify-content-between align-items-center">
                      <span>
                        ₹60/
                        <span className="text-muted fs-6">pcs</span>
                      </span>
                      <span className="TC_BG_2 rounded-3">
                        <button className="btn">Request</button>
                      </span>
                    </h5>
                    <Link to="/Rice_PD">
                      <p className="text-decoration-none">
                        <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center mt-5">
                          <button className="btn text-white">View More</button>
                        </span>
                      </p>
                    </Link>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            {/* -----------------------SEEDLINGS_PRODUCT_8---------------------------------*/}
            <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
              <Card style={{ width: "18rem", height: "23rem" }}>
                <div className="SEEDLINGS_CARD_IMG d-flex justify-content-center">
                  <Card.Img
                    variant="top"
                    src="https://as1.ftcdn.net/v2/jpg/02/99/43/48/1000_F_299434842_UF1e0k44KUpkdtAEu0XbbPVnTHFuRwAm.jpg"
                    className="img-fluid"
                    alt="Product_img_1"
                  />
                  <i
                    class="bi bi-save2 TC_BG_3 px-2 py-1 rounded-3"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  ></i>
                </div>

                <Card.Body>
                  <Card.Text>
                    <h5 className="card-title">
                      <p>Carrot</p>
                    </h5>
                    <h5 className="d-flex justify-content-between align-items-center">
                      <span>
                        ₹20/
                        <span className="text-muted fs-6">pcs</span>
                      </span>
                      <span className="TC_BG_2 rounded-3">
                        <button className="btn">Request</button>
                      </span>
                    </h5>
                    <Link to="/Rice_PD">
                      <p className="text-decoration-none">
                        <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center mt-5">
                          <button className="btn text-white">View More</button>
                        </span>
                      </p>
                    </Link>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </body>
      <Footer />
      <Whats_App_Stick />
    </>
  );
};

export default Seedlings_List;
