import React from "react";
import "./Machinery_List.css";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";
import Card from "react-bootstrap/Card";
import Whats_App_Stick from "../../../Components/Common/Whats_App_Stick/Whats_App_Stick";
import { Link } from "react-router-dom";

const Machinery_List = () => {
  return (
    <>
      <Header />
      <body className="MACHINERY_LIST_BODY py-5">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-12 d-flex justify-content-center  mt-5">
              <h1 className="TC_COL_1 FONT_1_SANS_SERIF TC_BG_3 px-3 py-2 rounded-1 ">
                List of Machineries
              </h1>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            {/* -----------------------MACHINERY_PRODUCT_1---------------------------------*/}
            <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
              <Card style={{ width: "18rem", height: "23rem" }}>
                <div className="SEEDLINGS_CARD_IMG d-flex justify-content-center">
                  <Card.Img
                    variant="top"
                    src="https://www.indiafilings.com/learn/wp-content/uploads/2020/07/shutterstock_1186100632-1.jpg"
                    className="img-fluid"
                    alt="Product_img_1"
                  />
                  <i
                    class="bi bi-save2 TC_BG_3 px-2 py-1 rounded-3"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  ></i>
                </div>

                <Card.Body>
                  <Card.Text>
                    <h5 className="card-title">
                      <p>Power Tiller</p>
                    </h5>
                    <h5 className="d-flex justify-content-between align-items-center">
                      <span>
                        ₹300/
                        <span className="text-muted fs-6">day</span>
                      </span>
                      <span className="TC_BG_2 rounded-3">
                        <button className="btn">Request</button>
                      </span>
                    </h5>

                    <Link to="/Rice_PD">
                      <p>
                        <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center mt-5">
                          <button className="btn text-white">View More</button>
                        </span>
                      </p>
                    </Link>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            {/* -----------------------MACHINERY_PRODUCT_2---------------------------------*/}
            <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
              <Card style={{ width: "18rem", height: "23rem" }}>
                <div className="SEEDLINGS_CARD_IMG d-flex justify-content-center">
                  <Card.Img
                    variant="top"
                    src="https://image.made-in-china.com/44f3j00WknoZFNJifcV/New-Gasoline-4L-Fuel-4-Rows-6-Rows-Walking-Paddy-Rice-Transplanter.webp"
                    className="img-fluid"
                    alt="Product_img_1"
                  />
                  <i
                    class="bi bi-save2 TC_BG_3 px-2 py-1 rounded-3"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  ></i>
                </div>

                <Card.Body>
                  <Card.Text>
                    <h5 className="card-title">
                      <p>Rice Transplanter</p>
                    </h5>
                    <h5 className="d-flex justify-content-between align-items-center">
                      <span>
                        ₹600/
                        <span className="text-muted fs-6">day</span>
                      </span>
                      <span className="TC_BG_2 rounded-3">
                        <button className="btn">Request</button>
                      </span>
                    </h5>
                    <Link to="/Rice_PD">
                      <p className="text-decoration-none">
                        <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center mt-5">
                          <button className="btn text-white">View More</button>
                        </span>
                      </p>
                    </Link>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            {/* -----------------------MACHINERY_PRODUCT_3---------------------------------*/}
            <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
              <Card style={{ width: "18rem", height: "23rem" }}>
                <div className="SEEDLINGS_CARD_IMG d-flex justify-content-center">
                  <Card.Img
                    variant="top"
                    src="https://4.imimg.com/data4/DN/WC/MY-14136357/agricultural-thresher-machine-500x500.jpg"
                    className="img-fluid"
                    alt="Product_img_1"
                  />
                  <i
                    class="bi bi-save2 TC_BG_3 px-2 py-1 rounded-3"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  ></i>
                </div>

                <Card.Body>
                  <Card.Text>
                    <h5 className="card-title">
                      <p>Thresher Machine</p>
                    </h5>
                    <h5 className="d-flex justify-content-between align-items-center">
                      <span>
                        ₹500/
                        <span className="text-muted fs-6">day</span>
                      </span>
                      <span className="TC_BG_2 rounded-3">
                        <button className="btn">Request</button>
                      </span>
                    </h5>
                    <Link to="/Rice_PD">
                      <p className="text-decoration-none">
                        <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center mt-5">
                          <button className="btn text-white">View More</button>
                        </span>
                      </p>
                    </Link>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            {/* -----------------------MACHINERY_PRODUCT_4---------------------------------*/}
            <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
              <Card style={{ width: "18rem", height: "23rem" }}>
                <div className="SEEDLINGS_CARD_IMG d-flex justify-content-center">
                  <Card.Img
                    variant="top"
                    src="https://st.depositphotos.com/1000144/2602/i/450/depositphotos_26023255-stock-photo-an-yellow-harvester-in-work.jpg"
                    className="img-fluid"
                    alt="Product_img_1"
                  />
                  <i
                    class="bi bi-save2 TC_BG_3 px-2 py-1 rounded-3"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  ></i>
                </div>

                <Card.Body>
                  <Card.Text>
                    <h5 className="card-title">
                      <p>Combine Harvesting</p>
                    </h5>
                    <h5 className="d-flex justify-content-between align-items-center">
                      <span>
                        ₹800/
                        <span className="text-muted fs-6">day</span>
                      </span>
                      <span className="TC_BG_2 rounded-3">
                        <button className="btn">Request</button>
                      </span>
                    </h5>
                    <Link to="/Rice_PD">
                      <p className="text-decoration-none">
                        <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center mt-5">
                          <button className="btn text-white">View More</button>
                        </span>
                      </p>
                    </Link>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            {/* -----------------------MACHINERY_PRODUCT_5---------------------------------*/}
            <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
              <Card style={{ width: "18rem", height: "23rem" }}>
                <div className="SEEDLINGS_CARD_IMG d-flex justify-content-center">
                  <Card.Img
                    variant="top"
                    src="https://agriculturepost.com/wp-content/uploads/2021/08/Mahindra-launches-new-heavy-duty-Rotavator-%E2%80%93-Mahindra-Mahavator.jpg"
                    className="img-fluid"
                    alt="Product_img_1"
                  />
                  <i
                    class="bi bi-save2 TC_BG_3 px-2 py-1 rounded-3"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  ></i>
                </div>

                <Card.Body>
                  <Card.Text>
                    <h5 className="card-title">
                      <p>Rotavator</p>
                    </h5>
                    <h5 className="d-flex justify-content-between align-items-center">
                      <span>
                        ₹500/
                        <span className="text-muted fs-6">day</span>
                      </span>
                      <span className="TC_BG_2 rounded-3">
                        <button className="btn">Request</button>
                      </span>
                    </h5>
                    <Link to="/Rice_PD">
                      <p className="text-decoration-none">
                        <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center mt-5">
                          <button className="btn text-white">View More</button>
                        </span>
                      </p>
                    </Link>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            {/* -----------------------MACHINERY_PRODUCT_6---------------------------------*/}
            <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
              <Card style={{ width: "18rem", height: "23rem" }}>
                <div className="SEEDLINGS_CARD_IMG d-flex justify-content-center">
                  <Card.Img
                    variant="top"
                    src="https://www.shutterstock.com/image-photo/detail-ripper-bulldozer-caterpillar-600nw-430309111.jpg"
                    className="img-fluid"
                    alt="Product_img_1"
                  />
                  <i
                    class="bi bi-save2 TC_BG_3 px-2 py-1 rounded-3"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  ></i>
                </div>

                <Card.Body>
                  <Card.Text>
                    <h5 className="card-title">
                      <p>Ripper Machine</p>
                    </h5>
                    <h5 className="d-flex justify-content-between align-items-center">
                      <span>
                        ₹400/
                        <span className="text-muted fs-6">day</span>
                      </span>
                      <span className="TC_BG_2 rounded-3">
                        <button className="btn">Request</button>
                      </span>
                    </h5>
                    <Link to="/Rice_PD">
                      <p className="text-decoration-none">
                        <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center mt-5">
                          <button className="btn text-white">View More</button>
                        </span>
                      </p>
                    </Link>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            {/* -----------------------MACHINERY_PRODUCT_7---------------------------------*/}
            <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
              <Card style={{ width: "18rem", height: "23rem" }}>
                <div className="SEEDLINGS_CARD_IMG d-flex justify-content-center">
                  <Card.Img
                    variant="top"
                    src="https://wallpapers.com/images/hd/tractor-pictures-7drx0l5prr9v3id4.jpg"
                    className="img-fluid"
                    alt="Product_img_1"
                  />
                  <i
                    class="bi bi-save2 TC_BG_3 px-2 py-1 rounded-3"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  ></i>
                </div>

                <Card.Body>
                  <Card.Text>
                    <h5 className="card-title">
                      <p>Tractor</p>
                    </h5>
                    <h5 className="d-flex justify-content-between align-items-center">
                      <span>
                        ₹700/
                        <span className="text-muted fs-6">day</span>
                      </span>
                      <span className="TC_BG_2 rounded-3">
                        <button className="btn">Request</button>
                      </span>
                    </h5>
                    <Link to="/Rice_PD">
                      <p className="text-decoration-none">
                        <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center mt-5">
                          <button className="btn text-white">View More</button>
                        </span>
                      </p>
                    </Link>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            {/* -----------------------MACHINERY_PRODUCT_8---------------------------------*/}
            <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
              <Card style={{ width: "18rem", height: "23rem" }}>
                <div className="SEEDLINGS_CARD_IMG d-flex justify-content-center">
                  <Card.Img
                    variant="top"
                    src="https://5.imimg.com/data5/SELLER/Default/2021/1/RX/TF/QY/25075711/moba-laser-land-leveler.jpg"
                    className="img-fluid"
                    alt="Product_img_1"
                  />
                  <i
                    class="bi bi-save2 TC_BG_3 px-2 py-1 rounded-3"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  ></i>
                </div>

                <Card.Body>
                  <Card.Text>
                    <h5 className="card-title">
                      <p>Leveler</p>
                    </h5>
                    <h5 className="d-flex justify-content-between align-items-center">
                      <span>
                        ₹500/
                        <span className="text-muted fs-6">day</span>
                      </span>
                      <span className="TC_BG_2 rounded-3">
                        <button className="btn">Request</button>
                      </span>
                    </h5>
                    <Link to="/Rice_PD">
                      <p className="text-decoration-none">
                        <span className="TC_BG_1 rounded-3 d-flex align-items-center justify-content-center mt-5">
                          <button className="btn text-white">View More</button>
                        </span>
                      </p>
                    </Link>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </body>
      <Footer />
      <Whats_App_Stick />
    </>
  );
};

export default Machinery_List;
