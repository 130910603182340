import React from "react";
import "./As_Seen_In_2.css";
import Marquee from "react-fast-marquee";

const As_Seen_In_2 = () => {
  return (
    <>
      <div className="container mt-5 mb-2">
        <div className="AS_SEEN_TITLE d-flex justify-content-center">
          As Seen In
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 ">
              <Marquee
                speed={100}
                direction="right"
                gradient={false}
                pauseOnHover={true}
                loop={0}
                delay={0}
                play={true}
                reverse={false}
                pauseOnClick={true}
                className="custom-marquee"
                autoFill={true}
              >
                <div className="AS_SEEN_BANNER_CONTAINER d-flex">
                  <div className="AS_SEEN_BANNER">
                    <div className="AS_SEEN_BANNER_IMG_CONTAINER">
                      <img
                        src="https://hips.hearstapps.com/hmg-prod/images/best-annual-flowers-geraniums-1521752646.jpg?crop=1.00xw:0.376xh;0,0.517xh"
                        alt="Logo One"
                      />
                    </div>
                  </div>
                  <div className="AS_SEEN_BANNER">
                    <div className="AS_SEEN_BANNER_IMG_CONTAINER">
                      <img
                        src="https://adenearthworks.com/wp-content/uploads/2015/04/gernaiums-aden-earthworks.jpg"
                        alt="Logo Two"
                      />
                    </div>
                  </div>
                  <div className="AS_SEEN_BANNER">
                    <div className="AS_SEEN_BANNER_IMG_CONTAINER">
                      <img
                        src="https://www.thespruce.com/thmb/VYJyiMeu8N0ek6SVD1FvLudGkGo=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/SPR-houseplants-grown-in-water-4177520-hero-264670857d8b4c68a66b6d63c20e179e.jpg"
                        alt="Logo Three"
                      />
                    </div>
                  </div>
                  <div className="AS_SEEN_BANNER">
                    <div className="AS_SEEN_BANNER_IMG_CONTAINER">
                      <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQugvIrK1MWWe2NuucqBP8orm09RK8-NsKBzRu3wfsKiDu70mFAz8YSn1bcQ0meGYOZDmU&usqp=CAU"
                        alt="Logo Four"
                      />
                    </div>
                  </div>
                  <div className="AS_SEEN_BANNER">
                    <div className="AS_SEEN_BANNER_IMG_CONTAINER">
                      <img
                        src="https://cdn.shopify.com/s/files/1/2690/0106/files/DSC_3114-2.jpg?v=1593253928"
                        alt="Logo Five"
                      />
                    </div>
                  </div>
                </div>
              </Marquee>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default As_Seen_In_2;
