import React, { useEffect, useState } from "react";
import "./Featured_Collections.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { getCategory } from "../../../api/category";

const Featured_Collections = () => {
  const [category, setCategory] = useState([]);
  const owloptions = {
    loop: true,
    margin: 20,
    nav: false,
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      1000: { items: 4 },
    },
  };

  useEffect(() => {
    getAllUsersList();
  }, []);

  const getAllUsersList = async () => {
    try {
      const categoryData = await getCategory();
      const categoryResult = categoryData.data.result;
      setCategory(categoryResult);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="container">
        <div className="row pt-5">
          <div className="col-12 col-lg-12 text-center text-lg-start">
            <h1 className="TC_COL_1 ls-widest">
              Featured <span className="TC_COL_2">collection</span>
            </h1>
          </div>
        </div>

        <div className="row mt-5">
          <div className="container-fluid col-12  bg-light">
            <OwlCarousel className="owl-theme" {...owloptions}>
              {category.map((data, index) => (
                <div key={index} className="item">
                  <div className="bg-white rounded-3 shadow card card-light p-3" style={{ height: "auto", width: "15rem" }}>
                    <div className="d-flex align-items-center">
                      <img src={data.profileUrl} className="img-fluid rounded-circle" style={{ height: "3rem", width: "3rem" }} alt="Profile" />
                      <span className="ms-2">{data.name}</span>
                    </div>
                    <p className="pt-1">Title: {data.title}</p>
                    <img src={data.photo1} className="img-fluid" style={{ height: "10rem", width: "15rem" }} alt="Product" />
                    <p className="mt-1">Price: {data.price}</p>
                    <p>Description: {data.Description}</p>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </>
  );
};

export default Featured_Collections;
