import React from "react";
import "./Why_Choose.css";

const Why_Choose = () => {
  return (
    <>
      <body className="WHY_CHOOSE_BODY pt-5">
        {/* ----------------CONTENT_WHY_CHOOSE_BODY----------- */}
        <div className="container text-white">
          <div className="row">
            <div className="col-12 FONT_1_SANS_SERIF">
              <h1>
                <span>
                  Why Choose&nbsp;
                  <span className="TC_COL_2">Pixclick Farms?</span>
                </span>
              </h1>
              <p className="text-white">
                Amidst a rapidly expanding global population and rising demand
                for food, Pixclick Farms presents a diversified investment
                opportunity with promising long-term returns. With our diverse
                offerings, you can customize your investments according to your
                financial goals.
              </p>
            </div>
          </div>
        </div>

        {/* ----------------TABLE_WHY_CHOOSE_BODY----------- */}

        <div className="container">
          <div className="row">
            <div className="col-12 SCROLL_SIDE_TABLE">
              <table class="table my-transparent-table text-center">
                {/* ----------------thead----------- */}

                <thead className="align-middle">
                  <tr>
                    <th scope="col" className="text-white text-start">
                      Asset Class
                    </th>
                    <th scope="col">
                      <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/land_1.png?v=1686049399"
                        alt=""
                        className="IMG_SIZE"
                      />
                      <p className="TC_COL_2 FONT_SIZE">
                        Farmland with Pixclick
                      </p>
                    </th>
                    <th scope="col">
                      <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/loan-against-fd_1.png?v=1686049399"
                        alt=""
                        className="IMG_SIZE"
                      />
                      <p className="TC_COL_2 FONT_SIZE">Mutual Funds</p>
                    </th>
                    <th scope="col">
                      <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/investment_1.png?v=1686049398"
                        alt=""
                        className="IMG_SIZE"
                      />
                      <p className="TC_COL_2 FONT_SIZE">Fixed Deposits (FD)</p>
                    </th>
                    <th scope="col">
                      <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/share-product_1.png?v=1686049398"
                        alt=""
                        className="IMG_SIZE"
                      />
                      <p className="TC_COL_2 FONT_SIZE">Share Market</p>
                    </th>
                    <th scope="col">
                      <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/property-signboard_1.png?v=1686049399"
                        alt=""
                        className="IMG_SIZE"
                      />
                      <p className="TC_COL_2 FONT_SIZE">Property Gains</p>
                    </th>
                  </tr>
                </thead>

                {/* ----------------tbody----------- */}

                <tbody className="my-transparent-table">
                  {/* ----------------tbody_row_1----------- */}

                  <tr>
                    <th scope="row" className="text-white text-start">
                      Attractive Yield
                    </th>
                    <td>
                      <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/secondary-check_6b2b78b0_svg.png?v=1686049399"
                        alt=""
                        className=""
                      />
                    </td>
                    <td>
                      {/* <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/secondary-check_6b2b78b0_svg.png?v=1686049399"
                        alt=""
                        className=""
                      /> */}
                    </td>
                    <td>
                      <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/secondary-check_6b2b78b0_svg.png?v=1686049399"
                        alt=""
                        className=""
                      />
                    </td>
                    <td>
                      {/* <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/secondary-check_6b2b78b0_svg.png?v=1686049399"
                        alt=""
                        className=""
                      /> */}
                    </td>
                    <td>
                      {/* <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/secondary-check_6b2b78b0_svg.png?v=1686049399"
                        alt=""
                        className=""
                      /> */}
                    </td>
                  </tr>

                  {/* ----------------tbody_row_2----------- */}

                  <tr>
                    <th scope="row" className="text-white text-start">
                      low volatility
                    </th>
                    <td>
                      <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/secondary-check_6b2b78b0_svg.png?v=1686049399"
                        alt=""
                        className=""
                      />
                    </td>
                    <td>
                      {/* <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/secondary-check_6b2b78b0_svg.png?v=1686049399"
                        alt=""
                        className=""
                      /> */}
                    </td>
                    <td>
                      <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/secondary-check_6b2b78b0_svg.png?v=1686049399"
                        alt=""
                        className=""
                      />
                    </td>
                    <td>
                      {/* <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/secondary-check_6b2b78b0_svg.png?v=1686049399"
                        alt=""
                        className=""
                      /> */}
                    </td>
                    <td>
                      {/* <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/secondary-check_6b2b78b0_svg.png?v=1686049399"
                        alt=""
                        className=""
                      /> */}
                    </td>
                  </tr>
                  {/* ----------------tbody_row_3----------- */}

                  <tr>
                    <th scope="row" className="text-white text-start">
                      Equity Build Up{" "}
                    </th>
                    <td>
                      <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/secondary-check_6b2b78b0_svg.png?v=1686049399"
                        alt=""
                        className=""
                      />
                    </td>
                    <td>
                      <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/secondary-check_6b2b78b0_svg.png?v=1686049399"
                        alt=""
                        className=""
                      />
                    </td>
                    <td>
                      {/* <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/secondary-check_6b2b78b0_svg.png?v=1686049399"
                        alt=""
                        className=""
                      /> */}
                    </td>
                    <td>
                      <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/secondary-check_6b2b78b0_svg.png?v=1686049399"
                        alt=""
                        className=""
                      />
                    </td>
                    <td>
                      {/* <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/secondary-check_6b2b78b0_svg.png?v=1686049399"
                        alt=""
                        className=""
                      /> */}
                    </td>
                  </tr>
                  {/* ----------------tbody_row_4----------- */}

                  <tr>
                    <th scope="row" className="text-white text-start">
                      No Leverage
                    </th>
                    <td>
                      <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/secondary-check_6b2b78b0_svg.png?v=1686049399"
                        alt=""
                        className=""
                      />
                    </td>
                    <td>
                      {/* <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/secondary-check_6b2b78b0_svg.png?v=1686049399"
                        alt=""
                        className=""
                      /> */}
                    </td>
                    <td>
                      {/* <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/secondary-check_6b2b78b0_svg.png?v=1686049399"
                        alt=""
                        className=""
                      /> */}
                    </td>
                    <td>
                      <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/secondary-check_6b2b78b0_svg.png?v=1686049399"
                        alt=""
                        className=""
                      />
                    </td>
                    <td>
                      <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/secondary-check_6b2b78b0_svg.png?v=1686049399"
                        alt=""
                        className=""
                      />
                    </td>
                  </tr>
                  {/* ----------------tbody_row_5----------- */}

                  <tr>
                    <th scope="row" className="text-white text-start">
                      Hard Asset
                    </th>
                    <td>
                      <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/secondary-check_6b2b78b0_svg.png?v=1686049399"
                        alt=""
                        className=""
                      />
                    </td>
                    <td>
                      {/* <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/secondary-check_6b2b78b0_svg.png?v=1686049399"
                        alt=""
                        className=""
                      /> */}
                    </td>
                    <td>
                      {/* <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/secondary-check_6b2b78b0_svg.png?v=1686049399"
                        alt=""
                        className=""
                      /> */}
                    </td>
                    <td>
                      {/* <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/secondary-check_6b2b78b0_svg.png?v=1686049399"
                        alt=""
                        className=""
                      /> */}
                    </td>
                    <td>
                      <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/secondary-check_6b2b78b0_svg.png?v=1686049399"
                        alt=""
                        className=""
                      />
                    </td>
                  </tr>
                  {/* ----------------tbody_row_6----------- */}

                  <tr>
                    <th scope="row" className="text-white text-start">
                      Inflation Hedge
                    </th>
                    <td>
                      <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/secondary-check_6b2b78b0_svg.png?v=1686049399"
                        alt=""
                        className=""
                      />
                    </td>
                    <td>
                      <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/secondary-check_6b2b78b0_svg.png?v=1686049399"
                        alt=""
                        className=""
                      />
                    </td>
                    <td>
                      {/* <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/secondary-check_6b2b78b0_svg.png?v=1686049399"
                        alt=""
                        className=""
                      /> */}
                    </td>
                    <td>
                      {/* <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/secondary-check_6b2b78b0_svg.png?v=1686049399"
                        alt=""
                        className=""
                      /> */}
                    </td>
                    <td>
                      {/* <img
                        src="https://cdn.shopify.com/s/files/1/0695/8009/5810/files/secondary-check_6b2b78b0_svg.png?v=1686049399"
                        alt=""
                        className=""
                      /> */}
                    </td>
                  </tr>
                  {/* ----------------tbody_Bottom_Higlighted_Row----------- */}

                  <tr className="align-middle">
                    <th scope="row" className="text-start ">
                      <span className="TC_COL_2">Average Annual Return *</span>
                    </th>
                    <td className="text-white fs-4">
                      <span>14.0%</span>
                    </td>
                    <td className="text-white fs-4">
                      <span>12.0%</span>
                    </td>{" "}
                    <td className="text-white fs-4">
                      <span>8.0%</span>
                    </td>{" "}
                    <td className="text-white fs-4">
                      <span>6.4%</span>
                    </td>{" "}
                    <td className="text-white fs-4">
                      <span>6.5%</span>
                    </td>
                  </tr>
                </tbody>
              </table>

              <p className="text-white mt-5 pb-5 pt-5">
                *Note: The above comparisons are made to provide you with an
                understanding of the differences between various investment
                options. Past performance does not guarantee future results, and
                the trend may not continue. All returns are estimates and assume
                reinvestment of dividends. The provided information is not meant
                to represent the results of an actual investment. Returns do not
                include any management fees, transaction costs, or expenses. The
                risk/reward profile for each asset class varies significantly.
                You cannot invest directly in an index
              </p>
            </div>
          </div>
        </div>
      </body>
    </>
  );
};

export default Why_Choose;
