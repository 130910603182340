import React from "react";
import "./Home_FAQ.css";
import Accordion from "react-bootstrap/Accordion";

const Home_FAQ = () => {
  return (
    <div className="home_frequenty-asked-questions">
      <div className="container-fluid " id="bg_color">
        <div className="row pt-5">
          <div className="col-md-4 px-5">
            <h2 id="text_color_dark">
              Frequently asked <br />
              <span id="text_color_ligt">Questions</span>
            </h2>
            <br />
            <a href="#" id="text_color_dark" className=" fs-5">
              Explore all
            </a>
          </div>
          <div className="col-md-8 ">
            <Accordion>
              <div>
                <Accordion.Item eventKey="0" className="custom_border_radius">
                  <Accordion.Header>
                    <b id="text_color_dark" className="">
                      What makes Pixclick Farms a unique investment opportunity?
                    </b>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p id="text_color_dark">
                      While we don't guarantee returns upto 14% IRR, farmland
                      investments have historically shown strong, steady
                      returns. With our expertise in managing and optimizing
                      farm operations, we aim to maximize profitability for our
                      investors.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </div>
              <br />
              <div>
                <Accordion.Item eventKey="1" className="custom_border_radius">
                  <Accordion.Header>
                    <b id="text_color_dark" className="fs-6 ">
                      What type of farms does Pixclick Farms invest in?
                    </b>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p id="text_color_dark">
                      {" "}
                      We strive to identify and cultivate investment
                      opportunities that offer significant crop value, ensuring
                      that our investors benefit from the potential rewards
                      associated with high-value crop farming.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </div>
              <br />
              <div>
                <Accordion.Item eventKey="2" className="custom_border_radius">
                  <Accordion.Header>
                    <b id="text_color_dark" className="fs-6 ">
                      What are the expected returns on my investment?
                    </b>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p id="text_color_dark">
                      {" "}
                      At Pixclick Farms, we offer a range of investment
                      opportunities with average returns varying between 9% to
                      14% Internal Rate of Return (IRR). Investment returns can
                      vary depending on the specific type of investment you
                      choose within our portfolio. We strive to provide
                      attractive and competitive returns that align with the
                      risk and potential associated with each investment option.
                      The average returns we offer demonstrate our commitment to
                      delivering favorable financial outcomes for our investors.
                      It's important to note that returns in agriculture can be
                      influenced by various factors, including crop yields,
                      market conditions, operational costs, and the duration of
                      the investment. Our team carefully analyzes these factors
                      to ensure that the investment opportunities we offer have
                      the potential to generate solid returns. To obtain more
                      precise information about the average returns associated
                      with specific investment options at Pixclick Farms, I
                      recommend reaching out to our investment advisors or
                      visiting our official website. Our team will provide you
                      with comprehensive details tailored to your specific
                      investment preferences and guide you through the
                      investment options that align with your financial goals.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </div>
              <br />
              <div>
                <Accordion.Item eventKey="3" className="custom_border_radius">
                  <Accordion.Header>
                    <b id="text_color_dark" className="fs-6 ">
                      How do I start investing with Pixclick Farms?
                    </b>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p id="text_color_dark">
                      {" "}
                      Get started on your investment journey immediately with
                      Pixclick Farms by investing as little as Rs. 15,000
                      through our user-friendly website. We believe in making
                      investing accessible and convenient for everyone. With
                      just a small investment of Rs. 15,000, you can begin your
                      agricultural investment with us and embark on the path
                      towards financial growth and sustainability. Our online
                      platform provides a seamless experience, allowing you to
                      explore investment options, review details, and securely
                      invest in a few simple steps. Whether you're a seasoned
                      investor or new to the world of agriculture, our website
                      makes it easy to start your investment journey with
                      confidence.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </div>
              <br />
              <div>
                <Accordion.Item eventKey="4" className="custom_border_radius">
                  <Accordion.Header>
                    <b id="text_color_dark" className="fs-6 ">
                      Can I visit the farms in which I invest?
                    </b>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p id="text_color_dark">
                      {" "}
                      Absolutely! Before making your investment, we invite you
                      to visit our farm located in Chikmagalur. At Pixclick
                      Farms, we believe in transparency and building trust with
                      our investors. That's why we welcome you to personally
                      experience our farm and witness the quality of our
                      operations firsthand. During your visit, you'll have the
                      opportunity to see our state-of-the-art facilities,
                      observe our farming practices, and meet our knowledgeable
                      team. We'll be delighted to provide you with a
                      comprehensive tour, answer any questions you may have, and
                      offer insights into the investment opportunities
                      available. By visiting our farm, you can gain confidence
                      in your investment decision and have a clear understanding
                      of our commitment to excellence, sustainability, and
                      delivering favorable returns. To schedule your farm visit,
                      simply reach out to our team through our website or
                      contact our dedicated support line. We will be more than
                      happy to arrange a convenient time for you to explore our
                      farm and witness the potential that awaits you at Pixclick
                      Farms. Invest with confidence after experiencing our farm
                      firsthand. We look forward to welcoming you and showcasing
                      the opportunities that await you in the world of
                      agriculture investing!
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </div>
            </Accordion>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home_FAQ;
