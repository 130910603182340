import React from "react";
import Header from "../../Components/Header/Header";
import Investment_Heading_1 from "./Pages/Investment_Heading_1/Investment_Heading_1";
import As_Seen_In_2 from "./Pages/As_Seen_In_2/As_Seen_In_2";
import Patners_Growth_6 from "./Pages/Patners_Growth_6/Patners_Growth_6";
import Get_You_Investing from "./Pages/Get_You_Investing/Get_You_Investing";
import InvestmentTab from "./Pages/Investment.Harvest.tab/InvestmentTab";
import OurInverster from "./Pages/AWorldOurInvester/OurInverster";
import Footer from "../../Components/Footer/Footer";
import GoGreen from "./GoGreen";
import Agri_Investments from "./Pages/Agri_Investments/Agri_Investments";
import OnlineStore from "./OnlineStore";
import Farms_Emi_Calc from "./Pages/Farms_Emi_Calc/Farms_Emi_Calc";

const Farms_Investment = () => {
  return (
    <>
      <Header />
      <div className="mt-5 pt-4">
        <Investment_Heading_1 />
        <As_Seen_In_2 />
        <Farms_Emi_Calc />
        <Get_You_Investing />
        <InvestmentTab />
        <OurInverster />
        <Agri_Investments />
        <Patners_Growth_6 />
        <GoGreen />
        <OnlineStore />
      </div>
      <Footer />
    </>
  );
};

export default Farms_Investment;
