import React, { useState } from "react";
import "./All_Collections.css";
import All_Collections_Filters from "../../Components/Common/All_Collections_Filters/All_Collections_Filters";
import Footer from "../../Components/Footer/Footer";
import Whats_App_Stick from "../../Components/Common/Whats_App_Stick/Whats_App_Stick";
import Header from "../../Components/Header/Header";

const All_Collections = () => {
  const [count, setCount] = useState(0);

  const increment = () => {
    setCount(count + 1);
  };

  const decrement = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };

  // 
  const imgs = [
    {
      id: 0,
      Value:
        "https://amyrafarms.com/cdn/shop/products/7.png?v=1681059291&width=713",
    },
   
  ];
 
  const [sliderData, setSliderData] = useState(imgs[0]);
  const handleClick = (index) => {
    const slider = imgs[index];
    setSliderData(slider);
  };

  return (
    <>
      <Header />
      <div className="container-fluid all_collections_page FONT_1_POPPINS">
        <div className="container py-5 mt-5">
          <div className="row">
            <div className="col-md-12">
              <p className=" TC_COL_1  " id="by_farm_heading">
                Buy Farm Products
              </p>
            </div>
            <div className="col-md-12 pt-5">
              <All_Collections_Filters />
            </div>
          </div>
          <div className="row gap-4 ">
            <div
              class="col-md-3 mt-3 whole_card_image "
              style={{ width: "300px" }}
            >
              <div
                class="image_scale_hover d-flex align-items-stretch flex-column"
                style={{ width: "17rem", position: "relative" }}
              >
                <img
                  src="https://amyrafarms.com/cdn/shop/products/6.png?v=1681059291&width=360"
                  alt="Image 1"
                  class=""
                />

                <div class="card-body pt-3">
                  <p id="text_color_dark" class="fs-4 fw-bold pt-2">
                    100% Pure Himalayan Wild Forest Raw Honey
                  </p>
                  <p id="text_color_dark" class="fs-6">
                    From Rs.449.00
                  </p>
                </div>

                <div class="card-footer add_tocart_btn mt-5 pt-4">
                  
                <a
                    href="#"
                    className="btn-block text-center text-decoration-none   "
                    data-bs-toggle="modal"
                    data-bs-target="#honey_card_modal"
                    type="button"
                  >
                    Choose Options
                  </a>

                  <div
                    class="modal fade"
                    id="honey_card_modal"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-xl ">
                      <div
                        class="modal-content "
                        style={{ maxHeight: "550px", overflowY: "auto" }}
                      >
                        <div className="all_collections_page">
                          <div className="row">
                            <div className="col-md-12 d-flex justify-content-end px-5 mt-2 ">
                              <button
                                type="button"
                                class="btn-close border rounded-circle fs-5"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                          </div>
                          <div className="row p-5">
                            <div className="col-md-6">
                              <img
                                src="https://amyrafarms.com/cdn/shop/products/6.png?v=1681059291&width=360"
                                className="img-fluid bg-white w-100 h-75"
                              />
                            </div>
                            <div className="col-md-6">
                              <small className="TC_COL_1">Amyra Farms</small>
                              <p className="h1 TC_COL_1">  100% Pure Himalayan Wild Forest Raw Honey</p>
                              <p className="fs-6 TC_COL_1">Rs. 449.00</p>
                              <p className="text-center">Sold Out</p>
                              <small className="TC_COL_1">
                                Tax included. Shipping calculated at checkout.
                              </small>
                              <br />

                              <small className="TC_COL_1 ">Size</small>
                              <div>
                                <p className="btn border bg-success text-white rounded-pill">
                                 <s> 350 gm</s>
                                </p>
                                <p className="btn border bg-success  text-white rounded-pill ">
                                 <s> 500 gm</s>
                                </p>
                              </div>

                              <small className="TC_COL_1 ">Quantity</small>
                              <br />
                              <br />
                              <button
                                class="btn w-25 p-2 border rounded-0"
                                type="submit"
                              >
                                <span onClick={decrement} id="text_color_dark">
                                  -
                                </span>
                                <span className="px-4" id="text_color_dark">
                                  {count}
                                </span>
                                <span onClick={increment} id="text_color_dark">
                                  +
                                </span>
                              </button>
                              <div className="d-grid gap-2 pt-3 change_btn_formate_clr">
                                <button
                                  className="btn btn-block p-2 "
                                  type="button"
                                >
                                  Add to cart
                                </button>
                              </div>
                              <div
                                className="d-grid gap-2  pt-2 another_btn_change_clr"
                                id="text_color_dark"
                              >
                                <button
                                  className="btn btn-block p-2"
                                  type="button"
                                >
                                  Buy it now
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="modal-body"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ position: "absolute", top: "2%", left: "58%" }}>
                  <p
                    className="bg-success px-3 rounded-5"
                    style={{
                      color: "white",
                      fontSize: "18px",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Sold Out
                  </p>
                </div>
              </div>
            </div>

            {/* 2 card */}
            <div
              class="col-md-3 mt-3 whole_card_image"
              style={{ width: "300px" }}
            >
              <div
                class=" image_scale_hover d-flex align-items-stretch flex-column"
                style={{ width: "17rem" }}
              >
                <img
                  src="https://amyrafarms.com/cdn/shop/files/1.png?v=1690265108&width=360"
                  alt="Image 1"
                  className="img-fluid"
                />
                <div class="card-body pt-3">
                  <p id="text_color_dark" className="fs-4 fw-bold pt-2 ">
                    Lakadong Turmeric Powder From Meghalaya
                  </p>
                  <p id="text_color_dark" className="fs-6 ">
                    Rs.300.00
                  </p>
                </div>
                <div className="card-footer add_tocart_btn">
                  <a
                    href="#"
                    className="btn-block text-center text-decoration-none   "
                    data-bs-toggle="modal"
                    data-bs-target="#turmeric_card_modal"
                    type="button"
                  >
                    Add To Cart
                  </a>

                  <div
                    class="modal fade"
                    id="turmeric_card_modal"
                    tabindex="-1"
                    aria-labelledby="turmeric_card_modal"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-top modal-md rounded-0">
                      <div
                        class="modal-content "
                        style={{
                          maxHeight: "400px",
                          overflow: "hidden",
                          width: "400px",
                        }}
                      >
                        <div className="all_collections_page">
                          <div className="row">
                            <div className="col-md-12 d-flex justify-content-end px-5 mt-2 ">
                              <button
                                type="button"
                                class="btn-close border rounded-circle fs-5"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                          </div>
                          <div className="row p-5">
                            <div className="col-md-3">
                              <img
                                src="https://amyrafarms.com/cdn/shop/files/1.png?v=1690265108&width=360"
                                className="img-fluid bg-white h-75"
                              />
                            </div>
                            <div className="col-md-9">
                              <small className="TC_COL_1" id="text_color_dark">
                                Amyra Farms
                              </small>

                              <p className="  " style={{ fontSize:'15px',}} id="text_color_dark">
                                {" "}
                                Lakadong Turmeric Powder From Meghalaya
                              </p>
                              <span id="text_color_dark">Size:250 gm</span>
                              <br />
                            </div>
                            <div className="col-md-12">
                              <div className="d-grid gap-2 pt-3 change_btn_formate_clr">
                                <button
                                  className="btn btn-block p-2"
                                  type="button"
                                >
                                  View cart (4)
                                </button>
                              </div>
                              <div
                                className="d-grid gap-2  pt-2 another_btn_change_clr"
                                id="text_color_dark"
                              >
                                <button
                                  className="btn btn-block p-2"
                                  type="button"
                                >
                                  Check Out
                                </button>
                                <a
                                  href="#"
                                  className="text-center  text-primary"
                                  style={{ backgroundColor: "#EAEFE6" }}
                                >
                                  Continue Shopping
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* 3 card */}
            <div
              class="col-md-3 mt-3 whole_card_image "
              style={{ width: "300px" }}
            >
              <div
                class=" image_scale_hover d-flex align-items-stretch flex-column"
                style={{ width: "17rem", position: "relative" }}
              >
                <img
                  src="https://amyrafarms.com/cdn/shop/products/2.png?v=1681058161&width=360"
                  alt="Image 1"
                  className="img-fluid"
                />
                <div class="card-body pt-3">
                  <p id="text_color_dark" className="fs-4 fw-bold pt-2 ">
                    A2 Gir Cow Ghee Made From Bilona Method
                  </p>
                  <p id="text_color_dark" className="fs-6 ">
                    <s>Rs.1500.00</s> Rs.1200.00
                  </p>
                </div>
                <div className="mt-auto card-footer">
                  <div className="card-footer add_tocart_btn ">
                    <a
                      href="#"
                      className="btn-block text-center text-decoration-none   "
                      data-bs-toggle="modal"
                      data-bs-target="#ghee_card_modal"
                      type="button"
                    >
                     Add To Cart
                    </a>

                    <div
                      class="modal fade"
                      id="ghee_card_modal"
                      tabindex="-1"
                      aria-labelledby="turmeric_card_modal"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-dialog-top modal-md rounded-0">
                        <div
                          class="modal-content "
                          style={{
                            maxHeight: "400px",
                            overflow: "hidden",
                            width: "400px",
                          }}
                        >
                          <div className="all_collections_page">
                            <div className="row">
                              <div className="col-md-12 d-flex justify-content-end px-5 mt-2 ">
                                <button
                                  type="button"
                                  class="btn-close border rounded-circle fs-5"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                            </div>
                            <div className="row p-5">
                              <div className="col-md-3">
                                <img
                                  src="https://amyrafarms.com/cdn/shop/products/2.png?v=1681058161&width=360"
                                  className="img-fluid bg-white h-75"
                                />
                              </div>
                              <div className="col-md-9">
                                <small
                                  className="TC_COL_1"
                                  id="text_color_dark"
                                >
                                  Amyra Farms
                                </small>

                                <p
                                  className="  "
                                  id="text_color_dark"
                                  style={{ fontSize:'15px',}}
                                >
                                  {" "}
                                  A2 Gir Cow Ghee Made From Bilona Method
                                </p>
                                <span id="text_color_dark">Size:500 ml</span>
                                <br />
                              </div>
                              <div className="col-md-12">
                                <div className="d-grid gap-2 pt-3 change_btn_formate_clr">
                                  <button
                                    className="btn btn-block p-2"
                                    type="button"
                                  >
                                    View cart (4)
                                  </button>
                                </div>
                                <div
                                  className="d-grid gap-2  pt-2 another_btn_change_clr"
                                  id="text_color_dark"
                                >
                                  <button
                                    className="btn btn-block p-2"
                                    type="button"
                                  >
                                    Check Out
                                  </button>
                                  <a
                                    href="#"
                                    className="text-center  text-primary"
                                    style={{ backgroundColor: "#EAEFE6" }}
                                  >
                                    Continue Shopping
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ position: "absolute", top: "2%", left: "58%" }}>
                  <p
                    className="bg-success px-3 rounded-5"
                    style={{
                      color: "white",
                      fontSize: "18px",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    20% off
                  </p>
                </div>
              </div>
            </div>
            {/* 4 card */}
            <div
              class="col-md-3 mt-3  whole_card_image"
              style={{ width: "300px" }}
            >
              <div
                class=" image_scale_hover  d-flex align-items-stretch flex-column"
                style={{ width: "17rem", position: "relative" }}
              >
                <img
                  src="https://amyrafarms.com/cdn/shop/products/ACVwithgingergarliclemonandhoney.png?v=1678186266&width=360"
                  alt="Image 1"
                  className="img-fluid bg-secondary"
                />
                <div class="card-body pt-3">
                  <p id="text_color_dark" className="fs-4 fw-bold pt-2 ">
                    Wellness Wonder Apple Cider Vinegar with Ginger, Garlic,
                    Lemon & Honey
                  </p>
                  <p id="text_color_dark" className="fs-6 ">
                    <s>Rs.799.00</s> Rs.699.00
                  </p>
                </div>
                <div className="card-footer add_tocart_btn">
                  <a
                    href="#"
                    className="btn-block text-center text-decoration-none   "
                    data-bs-toggle="modal"
                    data-bs-target="#lemon_honey_card_modal"
                    type="button"
                  >
                  Add To Cart
                  </a>

                  <div
                    class="modal fade"
                    id="lemon_honey_card_modal"
                    tabindex="-1"
                    aria-labelledby="turmeric_card_modal"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-top modal-md rounded-0">
                      <div
                        class="modal-content "
                        style={{
                          maxHeight: "400px",
                          overflow: "hidden",
                          width: "400px",
                        }}
                      >
                        <div className="all_collections_page">
                          <div className="row">
                            <div className="col-md-12 d-flex justify-content-end px-5 mt-2 ">
                              <button
                                type="button"
                                class="btn-close border rounded-circle fs-5"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                          </div>
                          <div className="row p-5">
                            <div className="col-md-3">
                              <img
                                src="https://amyrafarms.com/cdn/shop/products/ACVwithgingergarliclemonandhoney.png?v=1678186266&width=360"
                                className="img-fluid bg-white h-75"
                              />
                            </div>
                            <div className="col-md-9">
                              <small className="TC_COL_1" id="text_color_dark">
                                Amyra Farms
                              </small>

                              <p className=" " style={{ fontSize:'15px',}} id="text_color_dark">
                                {" "}
                                Wellness Wonder Apple Cider Vinegar with Ginger,
                                Garlic, Lemon & Honey
                              </p>

                              <br />
                            </div>
                            <div className="col-md-12">
                              <div className="d-grid gap-2 pt-3 change_btn_formate_clr">
                                <button
                                  className="btn btn-block p-2"
                                  type="button"
                                >
                                  View cart (4)
                                </button>
                              </div>
                              <div
                                className="d-grid gap-2  pt-2 another_btn_change_clr"
                                id="text_color_dark"
                              >
                                <button
                                  className="btn btn-block p-2"
                                  type="button"
                                >
                                  Check Out
                                </button>
                                <a
                                  href="#"
                                  className="text-center  text-primary"
                                  style={{ backgroundColor: "#EAEFE6" }}
                                >
                                  Continue Shopping
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ position: "absolute", top: "2%", left: "58%" }}>
                  <p
                    className="bg-success px-3 rounded-5"
                    style={{
                      color: "white",
                      fontSize: "18px",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    13% off
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row pb-4 gap-4 mt-3">
            <div
              class="col-md-3 mt-3 whole_card_image"
              style={{ width: "300px" }}
            >
              <div
                class=" image_scale_hover d-flex align-items-stretch flex-column"
                style={{ width: "17rem" }}
              >
                <img
                  src="https://amyrafarms.com/cdn/shop/files/2_5bf22777-15b5-4c7d-b913-0b90c3712476.png?v=1690347554&width=360"
                  alt="Image 1"
                  className="img-fluid "
                />
                <div class="card-body pt-3">
                  <p id="text_color_dark" className="fs-4 fw-bold pt-2 ">
                    Royal Black Peppercorns
                  </p>
                  <p id="text_color_dark" className="fs-6 ">
                    Rs.375.00
                  </p>
                </div>
                <div className="card-footer add_tocart_btn">
                  <a
                    href="#"
                    className="btn-block text-center text-decoration-none   "
                    data-bs-toggle="modal"
                    data-bs-target="#pepper_card_modal"
                    type="button"
                  >
                  Add To Cart
                  </a>

                  <div
                    class="modal fade"
                    id="pepper_card_modal"
                    tabindex="-1"
                    aria-labelledby="turmeric_card_modal"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-top modal-md rounded-0">
                      <div
                        class="modal-content "
                        style={{
                          maxHeight: "400px",
                          overflow: "hidden",
                          width: "400px",
                        }}
                      >
                        <div className="all_collections_page">
                          <div className="row">
                            <div className="col-md-12 d-flex justify-content-end px-5 mt-2 ">
                              <button
                                type="button"
                                class="btn-close border rounded-circle fs-5"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                          </div>
                          <div className="row p-5">
                            <div className="col-md-3">
                              <img
                                src="https://amyrafarms.com/cdn/shop/files/2_5bf22777-15b5-4c7d-b913-0b90c3712476.png?v=1690347554&width=360"
                                className="img-fluid bg-white h-75"
                              />
                            </div>
                            <div className="col-md-9">
                              <small className="TC_COL_1" id="text_color_dark">
                                Amyra Farms
                              </small>

                              <p className="  " style={{ fontSize:'15px',}} id="text_color_dark">
                                {" "}
                                Royal Black Peppercorns
                              </p>
                              <span id="text_color_dark">Size:250 gms</span>
                              <br />
                            </div>
                            <div className="col-md-12">
                              <div className="d-grid gap-2 pt-3 change_btn_formate_clr">
                                <button
                                  className="btn btn-block p-2"
                                  type="button"
                                >
                                  View cart (4)
                                </button>
                              </div>
                              <div
                                className="d-grid gap-2  pt-2 another_btn_change_clr"
                                id="text_color_dark"
                              >
                                <button
                                  className="btn btn-block p-2"
                                  type="button"
                                >
                                  Check Out
                                </button>
                                <a
                                  href="#"
                                  className="text-center  text-primary"
                                  style={{ backgroundColor: "#EAEFE6" }}
                                >
                                  Continue Shopping
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-3 mt-3 whole_card_image"
              style={{ width: "300px" }}
            >
              <div
                class=" image_scale_hover d-flex align-items-stretch flex-column"
                style={{ width: "17rem" }}
              >
                <img
                  src="https://amyrafarms.com/cdn/shop/files/2_6139ca20-fade-4cb3-a481-3fa92e594d4c.png?v=1690346255&width=360"
                  alt="Image 1"
                  className="img-fluid "
                />
                <div class="card-body pt-3">
                  <p id="text_color_dark" className="fs-4 fw-bold pt-2  ">
                    Premium cardamom
                  </p>
                  <p id="text_color_dark" className="fs-6 ">
                    From Rs.800.00
                  </p>
                </div>
                <div className="card-footer add_tocart_btn">
                  {/* modal button */}

                  <a
                    href="#"
                    className="btn-block text-center text-decoration-none   "
                    data-bs-toggle="modal"
                    data-bs-target="#cardamon_card_modal"
                    type="button"
                  >
                    Choose Options
                  </a>

                  <div
                    class="modal fade"
                    id="cardamon_card_modal"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-xl ">
                      <div
                        class="modal-content "
                        style={{ maxHeight: "550px", overflowY: "auto" }}
                      >
                        <div className="all_collections_page">
                          <div className="row">
                            <div className="col-md-12 d-flex justify-content-end px-5 mt-2 ">
                              <button
                                type="button"
                                class="btn-close border rounded-circle fs-5"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                          </div>
                          <div className="row p-5">
                            <div className="col-md-6">
                              <img
                                src="https://amyrafarms.com/cdn/shop/files/2_6139ca20-fade-4cb3-a481-3fa92e594d4c.png?v=1690346255&width=600"
                                className="img-fluid bg-white w-100 h-100"
                              />
                            </div>
                            <div className="col-md-6">
                              <small className="TC_COL_1">Amyra Farms</small>

                              <p className="h1 TC_COL_1">Premium cardamom</p>
                              <p className="fs-6 TC_COL_1">Rs. 800.00</p>
                              <small className="TC_COL_1">
                                Tax included. Shipping calculated at checkout.
                              </small>
                              <br />

                              <small className="TC_COL_1 ">Size</small>
                              <div>
                                <p className="btn rounded-pill TC_BG_1">
                                  250 gm
                                </p>
                                <p className="btn rounded-pill TC_BG_1">
                                  350 gm
                                </p>
                              </div>

                              <small className="TC_COL_1 ">Quantity</small>
                              <br />
                              <br />
                              <button
                                class="btn w-25 p-2 border rounded-0"
                                type="submit"
                              >
                                <span onClick={decrement} id="text_color_dark">
                                  -
                                </span>
                                <span className="px-4" id="text_color_dark">
                                  {count}
                                </span>
                                <span onClick={increment} id="text_color_dark">
                                  +
                                </span>
                              </button>
                              <div className="d-grid gap-2 pt-3 change_btn_formate_clr">
                                <button
                                  className="btn btn-block p-2"
                                  type="button"
                                >
                                  Add to cart
                                </button>
                              </div>
                              <div
                                className="d-grid gap-2  pt-2 another_btn_change_clr"
                                id="text_color_dark"
                              >
                                <button
                                  className="btn btn-block p-2"
                                  type="button"
                                >
                                  Buy it now
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="modal-body"></div>
                      </div>
                    </div>
                  </div>
                  {/* modal contents */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Whats_App_Stick />
      <Footer />
    </>
  );
};

export default All_Collections;
