import React from "react";
import "./HeadlineCarousel.css";
import ReactTyped from "react-typed";

const HeadlineCarousel = () => {
  return (
    <>
      <body className="py-5 TC_BG_3">
        <div
          id="carouselExampleFade"
          className="carousel slide carousel-fade"
          data-bs-ride="carousel"
          data-bs-pause="hover"
        >
          <div className="carousel-inner">
            <div
              className="carousel-item active BG_IMG_1 d-flex justify-content-start"
              data-bs-interval="3000"
            >
              <div
                className="col-md-6 col-sm-12 mt-5 HEAD_LINE_CONTENT text-center  
               text-md-start"
              >
                <span className="FONT_1_POPPINS text-white mt-5">
                  Smart Investment in Agriculture Simplified,
                </span>
                <p className="FONT_1_SANS_SERIF text-white mt-2">
                  Earn Tax-Free: 14.0% Average Annual Return*
                </p>
                <div
                  className="col-md-10 HEAD_INPUT_OUTER p-2 d-flex gap-2 rounded-3 "
                  style={{
                    background: "rgba(255, 255, 255, 0.1)",
                    backdropFilter: "blur(2px)",
                  }}
                >
                  <input
                    type="text"
                    className=" HEAD_INPUT_INNER p-2"
                    placeholder="Email Address"
                    style={{
                      color: "white",
                      background: "rgba(255, 255, 255, 0.1)",
                      backdropFilter: "blur(2px)",
                    }}
                  />

                  <button className="TC_BG_2 HEAD_INVEST_NOW_BTN w-25 rounded-3 fw-bold">
                    <a href="/">Invest Now</a>
                  </button>
                </div>
              </div>
            </div>
            <div
              className="carousel-item BG_IMG_2 d-flex justify-content-start"
              data-bs-interval="3000"
            >
              <div
                className="col-md-6 col-sm-12 mt-5 HEAD_LINE_CONTENT text-center  
               text-md-start"
              >
                <span className="FONT_1_POPPINS text-white mt-5">
                  Smart Investment in Agriculture Simplified,
                </span>
                <p className="FONT_1_SANS_SERIF text-white mt-2">
                  Earn Tax-Free: 14.0% Average Annual Return*
                </p>
                <div
                  className="col-md-10 HEAD_INPUT_OUTER p-2 d-flex gap-2 rounded-3 "
                  style={{
                    background: "rgba(255, 255, 255, 0.1)",
                    backdropFilter: "blur(2px)",
                  }}
                >
                  <input
                    type="text"
                    className=" HEAD_INPUT_INNER p-2"
                    placeholder="Email Address"
                    style={{
                      color: "white",
                      background: "rgba(255, 255, 255, 0.1)",
                      backdropFilter: "blur(2px)",
                    }}
                  />

                  <button className="TC_BG_2 HEAD_INVEST_NOW_BTN w-25 rounded-3 fw-bold">
                    <a href="/">Invest Now</a>
                  </button>
                </div>
              </div>
            </div>
            <div
              className="carousel-item BG_IMG_3 d-flex justify-content-start"
              data-bs-interval="3000"
            >
              <div
                className="col-md-6 col-sm-12 mt-5 HEAD_LINE_CONTENT text-center  
               text-md-start"
              >
                <span className="FONT_1_POPPINS text-white mt-5">
                  Smart Investment in Agriculture Simplified,
                </span>
                <p className="FONT_1_SANS_SERIF text-white mt-2">
                  Earn Tax-Free: 14.0% Average Annual Return*
                </p>
                <div
                  className="col-md-10 HEAD_INPUT_OUTER p-2 d-flex gap-2 rounded-3 "
                  style={{
                    background: "rgba(255, 255, 255, 0.1)",
                    backdropFilter: "blur(2px)",
                  }}
                >
                  <input
                    type="text"
                    className=" HEAD_INPUT_INNER p-2"
                    placeholder="Email Address"
                    style={{
                      color: "white",
                      background: "rgba(255, 255, 255, 0.1)",
                      backdropFilter: "blur(2px)",
                    }}
                  />

                  <button className="TC_BG_2 HEAD_INVEST_NOW_BTN w-25 rounded-3 fw-bold">
                    <a href="/">Invest Now</a>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleFade"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleFade"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </body>
    </>
  );
};

export default HeadlineCarousel;
