import React from "react";
import FAQ_Heading from "./FAQ_Heading/FAQ_Heading";
import Accordion_FAQ from "./Accordion_FAQ/Accordion_FAQ";
import Whats_App_Stick from "../../Components/Common/Whats_App_Stick/Whats_App_Stick";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

const FAQ_Main = () => {
  return (
    <div>
      <Header />
      <Accordion_FAQ />
      <FAQ_Heading />
      <Whats_App_Stick />
      <Footer />
    </div>
  );
};

export default FAQ_Main;
