import React from "react";
import "./Services.css";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";

import Whats_App_Stick from "../../Components/Common/Whats_App_Stick/Whats_App_Stick";

const Services = () => {
  return (
    <>
      <Header />
      <body className="farming_services py-5 ">
        <div className="container-fluid"></div>
        <div className="container">
          <div className="row text-center  mx-auto">
            <div className="col-sm-12 col-lg-12 d-flex justify-content-center  mt-5">
              <h1 className="TC_COL_1 FONT_1_SANS_SERIF TC_BG_3 px-3 rounded-1 text-center">
                List of Services
              </h1>
            </div>

            {/*  */}
            <div class="col-lg-3 col-6 button_response  d-flex justify-content-between mb-4 pt-4">
              <a href="/Seeds_List">
                <div class="services_page">
                  <div class="sevices_text">
                    <p className="text-white px-3">Seeds</p>
                  </div>
                  <img
                    src="https://images.pexels.com/photos/1393382/pexels-photo-1393382.jpeg?auto=compress&cs=tinysrgb&w=600"
                    alt="Your Image"
                    className="img-fluid rounded sevices_img"
                  />
                </div>
              </a>
            </div>
            <div class="col-lg-3 col-6 button_response  d-flex justify-content-between mb-4 pt-4">
              <a href="/Seedlings_List">
                <div class="services_page">
                  <div class="sevices_text">
                    <p className="text-white px-3">Seedlings</p>
                  </div>
                  <img
                    src="https://images.pexels.com/photos/6262108/pexels-photo-6262108.jpeg?auto=compress&cs=tinysrgb&w=600"
                    alt="Your Image"
                    className="img-fluid rounded sevices_img"
                  />
                </div>
              </a>
            </div>
            <div class="col-lg-3 col-6 button_response  d-flex justify-content-between mb-4 pt-4">
              <a href="/Machinery_List">
                <div class="services_page">
                  <div class="sevices_text">
                    <p className="text-white px-3">Machinery</p>
                  </div>
                  <img
                    src="https://images.pexels.com/photos/2254093/pexels-photo-2254093.jpeg?auto=compress&cs=tinysrgb&w=600"
                    alt="Your Image"
                    className="img-fluid rounded sevices_img"
                  />
                </div>
              </a>
            </div>
            <div class="col-lg-3 col-6 button_response  d-flex justify-content-between mb-4 pt-4">
              <a href="/Hire_Worker_List">
                <div class="services_page">
                  <div class="sevices_text">
                    <p className="text-white px-3">Hire Workers</p>
                  </div>
                  <img
                    src="https://images.pexels.com/photos/9798966/pexels-photo-9798966.jpeg?auto=compress&cs=tinysrgb&w=600"
                    alt="Your Image"
                    className="img-fluid rounded sevices_img"
                  />
                </div>
              </a>
            </div>
            <div class="col-lg-3 col-6 button_response  d-flex justify-content-between mb-4 ">
              <a href="/Cultivation_Process_List">
                <div class="services_page">
                  <div class="sevices_text">
                    <p className="text-white px-3">Cultivation process</p>
                  </div>
                  <img
                    src="https://images.pexels.com/photos/348689/pexels-photo-348689.jpeg?auto=compress&cs=tinysrgb&w=600"
                    alt="Your Image"
                    className="img-fluid rounded sevices_img"
                  />
                </div>
              </a>
            </div>
            <div class="col-lg-3 col-6 button_response  d-flex justify-content-between mb-4">
              <a href="/Crop_Disease_Solution_List">
                <div class="services_page">
                  <div class="sevices_text">
                    <p className="text-white px-3">Crop Disease Solution</p>
                  </div>
                  <img
                    src=" https://media.istockphoto.com/id/1250059761/photo/septoria-of-tomatoes-tomato-leaves-affected-by-septoria-lycopersici-fungus.webp?b=1&s=170667a&w=0&k=20&c=S_Pti3h23Pv0NU-8-Mo8HjCvhwODBs9WfMVUPxvrW40="
                    alt="Your Image"
                    className="img-fluid rounded sevices_img"
                  />
                </div>
              </a>
            </div>

            {/*  */}
          </div>
        </div>
      </body>
      <Whats_App_Stick />
      <Footer />
    </>
  );
};

export default Services;
