import React from "react";
import "./OnlineStore.css";
import { FaArrowRight } from "react-icons/fa6";
const OnlineStore = () => {
  return (
    <div className="container-fluid mb-4 mt-5">
      <div className="OnlineStore">
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <div className="OnlineStore_col_para_1">
              <p className="fw-bold">
                Increase the presence <br></br>of your online store
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="OnlineStore_col_para_2">
              <p>
                Expand your presence even further than websites and start
                <br></br> selling on marketplaces, on social media, on mobile,
                and more!
              </p>
            </div>
            <div className="">
                <a href="/enquiry" className="btn btn-outline-success "> Get started</a>
                <a href="/enquiry" className="mx-2 text-decoration-none text-black">Learn more <FaArrowRight /></a>
                </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default OnlineStore;
