import React from "react";
import "./Invest_Agri.css";

const Invest_Agri = () => {
  return (
    <>
      <body className="IG_BG FONT_1_SANS_SERIF pb-5">
        <div className="container py-5">
          <div className="row pb-5">
            <div className="col-sm-6 col-12 ">
              <p className="pt-5">
                <p className="pt-5"></p>
              </p>
              <p>
                <p className="text-white fs-1 fw-bold lh-sm text-center text-lg-start">
                  When{" "}
                  <span className="TC_COL_2">investing in agriculture</span> ,
                  you typically receive several forms of security, including:
                </p>
              </p>
            </div>

            {/* -----------------------ACCORDION------------------- */}
            <div className="col-sm-6 col-12">
              <p className="pt-5">
                <p className="pt-5"></p>
              </p>
              <div
                className="accordion accordion-flush ACCORDION_COL"
                id="accordionFlushExample"
              >
                <div
                  className="accordion-item"
                  style={{ backgroundColor: "transparent" }}
                >
                  <h2
                    className="accordion-header"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <button
                      className="accordion-button collapsed text-white"
                      style={{ backgroundColor: "transparent" }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      Low Volatility
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body text-white">
                      Agriculture investment returns are typically less volatile
                      than traditional investments, such as stocks or bonds.
                      This is due to the stable and predictable nature of the
                      agricultural industry, where demand for food remains
                      consistent even during economic downturns.
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item"
                  style={{ backgroundColor: "transparent" }}
                >
                  <h2
                    className="accordion-header"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <button
                      className="accordion-button collapsed text-white"
                      style={{ backgroundColor: "transparent" }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      Diversification
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body text-white">
                      Agricultural investments tend to have a low correlation
                      with traditional financial markets such as stocks and
                      bonds. This means that the performance of agricultural
                      investments often moves independently of other asset
                      classes. By including agriculture in your portfolio, you
                      can reduce the overall risk and volatility of your
                      investments, as agricultural returns are influenced by a
                      unique set of factors, such as weather conditions,
                      commodity prices, and global demand for food and
                      agricultural products.
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item"
                  style={{ backgroundColor: "transparent" }}
                >
                  <h2
                    className="accordion-header"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <button
                      className="accordion-button collapsed text-white"
                      style={{ backgroundColor: "transparent" }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      Inflation Hedge
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body text-white">
                      Agriculture investments can act as an effective hedge
                      against inflation. Agricultural products, such as crops
                      and livestock, tend to have intrinsic value and their
                      prices can rise with inflation. As inflation erodes the
                      purchasing power of traditional currencies, investments in
                      agriculture can help protect and preserve your wealth over
                      the long term.
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item"
                  style={{ backgroundColor: "transparent" }}
                >
                  <h2
                    className="accordion-header"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <button
                      className="accordion-button collapsed text-white"
                      style={{ backgroundColor: "transparent" }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFour"
                      aria-expanded="false"
                      aria-controls="flush-collapseFour"
                    >
                      Global Demand
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFour"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body text-white">
                      The global demand for food and agricultural products
                      continues to rise due to population growth, changing
                      dietary preferences, and increasing middle-class
                      consumption in emerging economies. Investing in
                      agriculture allows you to tap into this growing demand and
                      participate in the long-term growth of the sector. As a
                      result, agricultural investments can provide stable and
                      consistent returns over time, contributing to the
                      diversification of your overall investment portfolio.
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item"
                  style={{ backgroundColor: "transparent" }}
                >
                  <h2
                    className="accordion-header"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <button
                      className="accordion-button collapsed text-white"
                      style={{ backgroundColor: "transparent" }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFive"
                      aria-expanded="false"
                      aria-controls="flush-collapseFive"
                    >
                      Tangible assets
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFive"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body text-white">
                      Agriculture investments are typically tangible assets that
                      provide a level of security and stability. Land, crops,
                      livestock, and agricultural infrastructure have inherent
                      value and can serve as a solid foundation for your
                      investment portfolio. These tangible assets are less
                      susceptible to market fluctuations and can provide a sense
                      of stability and resilience during economic downturns.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  );
};

export default Invest_Agri;
