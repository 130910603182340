import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";


const Refund_Policy = () => {
  return (
    <div>
        <Header />
      <div className="container pt-5 mt-5">
        <div className="row pt-3">
          <div className="col-2"></div>
          <div className="col-8 mb-5">
            <h1 className="font-weight-bold  display-4">
              FAAB - Refund and Cancellation <br /> Policy
            </h1>
            <p className="text-secondary pt-3 fs-4">
              Due to service providers in nature “NO REFUND”,“NO CANCELLATION”
              will be entertained once the Payment has been made. The payment
              made will be considered as the contribution to the SPV.
            </p>
            <h5 className="pt-3 fs-2 font-weight-bold">Contact Us</h5>
            <h6 className="text-secondary pt-3 fs-5">
              FAAB Invest Advisors Private Limited. <br /> #17/A, 8th Main, 5th Cross
              Kamakshi Hospital Road, Saraswathipuram, Mysuru- <br /> 570009 <br /> +91 860 44
              333 77 <br /> invest@faab.in
            </h6>
          </div>
          <div className="col-2 "></div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Refund_Policy;
