import React from "react";
import "./Investment_Opportunities.css";
import Card from "react-bootstrap/Card";
import Product_img_1 from "../Investment_Opportunities/Assets_Investment_Opportunities/Product_img_1.jpg";
import Product_img_2 from "../Investment_Opportunities/Assets_Investment_Opportunities/Product_img_2.png";
import Product_img_3 from "../Investment_Opportunities/Assets_Investment_Opportunities/Product_img_3.png";
import Product_img_4 from "../Investment_Opportunities/Assets_Investment_Opportunities/Product_img_4.jpg";
import Product_img_5 from "../Investment_Opportunities/Assets_Investment_Opportunities/Product_img_5.jpg";

const Investment_Opportunities = () => {
  return (
    <>
      <body className="IO_BODY FONT_1_SANS_SERIF pb-5">
        {/* --------------Content_Investment_Opportunities---------------- */}
        <div className="container pt-5">
          <div className="row justify-content-between text-center text-md-start align-items-center">
            <div className="col-sm-4 FONT_1_SANS_SERIF">
              <h1 className="TC_COL_1">
                Investment
                <h1 className="TC_COL_2">Opportunities</h1>
              </h1>
            </div>
            <div className="col-sm-6">
              <p className="TC_COL_1">
                Diverse Opportunities, Diverse Returns: Step into a world of
                handpicked, growth-centric, and sustainable investment
                opportunities.
              </p>
            </div>
          </div>
        </div>

        {/* --------------Products_Investment_Opportunities---------------- */}
        <div className="container">
          <div className="row">
            {/* --------------Product_1_Investment_Opportunities---------------- */}
            <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
              <Card style={{ width: "18rem", height: "26rem" }}>
                <div className="IO_CARD_IMG p-1 d-flex justify-content-center">
                  <Card.Img
                    variant="top"
                    src={Product_img_1}
                    className="img-fluid"
                    alt="Product_img_1"
                  />
                </div>

                <p className="text-center IO_FONT_SIZE_1 TC_COL_1">
                  <i class="bi bi-geo-alt-fill"></i> &nbsp; Chikmagalur,
                  Karnataka
                </p>
                <h5 className="TC_COL_1 text-center fw-bold">Bee Farming</h5>
                <Card.Body>
                  <Card.Text>
                    <span className="d-flex justify-content-between IO_FONT_SIZE_1">
                      <p className="my-auto TC_COL_1 ">Return Rate</p>
                      <p className="my-auto TC_COL_1">9% Yearly</p>
                    </span>
                    <span className="d-flex justify-content-between IO_FONT_SIZE_1 mt-2">
                      <p className="my-auto TC_COL_1 ">Payout</p>
                      <p className="my-auto TC_COL_1">Every 3 Months</p>
                    </span>
                    <span className="d-flex justify-content-between IO_FONT_SIZE_1 mt-2">
                      <p className="my-auto TC_COL_1 ">Lock-In</p>
                      <p className="my-auto TC_COL_1">1 Year</p>
                    </span>
                    <span className="d-flex justify-content-between IO_FONT_SIZE_1 mt-2">
                      <p className="my-auto TC_COL_1 ">Investment</p>
                      <p className="my-auto TC_COL_1">Rs. 15,000</p>
                    </span>
                    <span className="fs-6 d-flex justify-content-center mt-3">
                      <p className="TC_COL_1 IO_BOTTOM_BORDER">Know More</p>
                    </span>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            {/* --------------Product_2_Investment_Opportunities---------------- */}
            <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
              <Card style={{ width: "18rem", height: "26rem" }}>
                <div className="IO_CARD_IMG p-1 d-flex justify-content-center">
                  <Card.Img variant="top" src={Product_img_2} />
                </div>

                <p className="text-center IO_FONT_SIZE_1 TC_COL_1">
                  <i class="bi bi-geo-alt-fill"></i> &nbsp;Kodaikanal, TamilNadu
                </p>
                <h5 className="TC_COL_1 text-center fw-bold">Estate Farms</h5>
                <Card.Body>
                  <Card.Text>
                    <span className="d-flex justify-content-between IO_FONT_SIZE_1">
                      <p className="my-auto TC_COL_1 ">Return Rate</p>
                      <p className="my-auto TC_COL_1">14% IRR</p>
                    </span>
                    <span className="d-flex justify-content-between IO_FONT_SIZE_1 mt-2">
                      <p className="my-auto TC_COL_1 ">Payout</p>
                      <p className="my-auto TC_COL_1">Once a Year</p>
                    </span>
                    <span className="d-flex justify-content-between IO_FONT_SIZE_1 mt-2">
                      <p className="my-auto TC_COL_1 ">Lock-In</p>
                      <p className="my-auto TC_COL_1">1 Year</p>
                    </span>
                    <span className="d-flex justify-content-between IO_FONT_SIZE_1 mt-2">
                      <p className="my-auto TC_COL_1 ">Investment</p>
                      <p className="my-auto TC_COL_1">Rs. 7,00,000</p>
                    </span>
                    <span className="fs-6 d-flex justify-content-center mt-3">
                      <p className="TC_COL_1 IO_BOTTOM_BORDER">Know More</p>
                    </span>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            {/* --------------Product_3_Investment_Opportunities---------------- */}
            <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
              <Card style={{ width: "18rem", height: "26rem" }}>
                <div className="IO_CARD_IMG p-1 d-flex justify-content-center">
                  <Card.Img variant="top" src={Product_img_3} />
                </div>

                <p className="text-center IO_FONT_SIZE_1 TC_COL_1">
                  <i class="bi bi-geo-alt-fill"></i> &nbsp; Ooty, TamilNadu
                </p>
                <h5 className="TC_COL_1 text-center fw-bold">Coffee Estate</h5>
                <Card.Body>
                  <Card.Text>
                    <span className="d-flex justify-content-between IO_FONT_SIZE_1">
                      <p className="my-auto TC_COL_1 ">Return Rate</p>
                      <p className="my-auto TC_COL_1">14% - 17% IRR</p>
                    </span>
                    <span className="d-flex justify-content-between IO_FONT_SIZE_1 mt-2">
                      <p className="my-auto TC_COL_1 ">Payout</p>
                      <p className="my-auto TC_COL_1">Once a Year</p>
                    </span>
                    <span className="d-flex justify-content-between IO_FONT_SIZE_1 mt-2">
                      <p className="my-auto TC_COL_1 ">Lock-In</p>
                      <p className="my-auto TC_COL_1">1 Year</p>
                    </span>
                    <span className="d-flex justify-content-between IO_FONT_SIZE_1 mt-2">
                      <p className="my-auto TC_COL_1 ">Investment</p>
                      <p className="my-auto TC_COL_1">Rs. 38,00,000</p>
                    </span>
                    <span className="fs-6 d-flex justify-content-center mt-3">
                      <p className="TC_COL_1 IO_BOTTOM_BORDER">Know More</p>
                    </span>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            {/* --------------Product_4_Investment_Opportunities---------------- */}
            <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
              <Card style={{ width: "18rem", height: "26rem" }}>
                <div className="IO_CARD_IMG p-1 d-flex justify-content-center">
                  <Card.Img variant="top" src={Product_img_4} />
                </div>

                <p className="text-center IO_FONT_SIZE_1 TC_COL_1">
                  <i class="bi bi-geo-alt-fill"></i> &nbsp; Chikmagalur,
                  Karnataka
                </p>
                <h5 className="TC_COL_1 text-center fw-bold">Spice Haven</h5>
                <Card.Body>
                  <Card.Text>
                    <span className="d-flex justify-content-between IO_FONT_SIZE_1">
                      <p className="my-auto TC_COL_1 ">Return Rate</p>
                      <p className="my-auto TC_COL_1">17% - 20% IRR</p>
                    </span>
                    <span className="d-flex justify-content-between IO_FONT_SIZE_1 mt-2">
                      <p className="my-auto TC_COL_1 ">Payout</p>
                      <p className="my-auto TC_COL_1">Once a Year</p>
                    </span>
                    <span className="d-flex justify-content-between IO_FONT_SIZE_1 mt-2">
                      <p className="my-auto TC_COL_1 ">Lock-In</p>
                      <p className="my-auto TC_COL_1">1 Year</p>
                    </span>
                    <span className="d-flex justify-content-between IO_FONT_SIZE_1 mt-2">
                      <p className="my-auto TC_COL_1 ">Investment</p>
                      <p className="my-auto TC_COL_1">Rs. 48,00,000</p>
                    </span>
                    <span className="fs-6 d-flex justify-content-center mt-3">
                      <p className="TC_COL_1 IO_BOTTOM_BORDER">Know More</p>
                    </span>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            {/* --------------Product_5_Investment_Opportunities---------------- */}
            <div className="col-6 col-lg-3 mt-5 d-flex justify-content-center">
              <Card style={{ width: "18rem", height: "26rem" }}>
                <div className="IO_CARD_IMG p-1 d-flex justify-content-center">
                  <Card.Img variant="top" src={Product_img_5} />
                </div>

                <p className="text-center IO_FONT_SIZE_1 TC_COL_1">
                  <i class="bi bi-geo-alt-fill"></i> &nbsp; Chikmagalur,
                  Karnataka
                </p>
                <h5 className="TC_COL_1 text-center fw-bold">Cow Dairy</h5>
                <Card.Body>
                  <Card.Text>
                    <span className="d-flex justify-content-between IO_FONT_SIZE_1">
                      <p className="my-auto TC_COL_1 ">Return Rate</p>
                      <p className="my-auto TC_COL_1">12% Yearly</p>
                    </span>
                    <span className="d-flex justify-content-between IO_FONT_SIZE_1 mt-2">
                      <p className="my-auto TC_COL_1 ">Payout</p>
                      <p className="my-auto TC_COL_1">Every 3 Months</p>
                    </span>
                    <span className="d-flex justify-content-between IO_FONT_SIZE_1 mt-2">
                      <p className="my-auto TC_COL_1 ">Lock-In</p>
                      <p className="my-auto TC_COL_1">1 Year</p>
                    </span>
                    <span className="d-flex justify-content-between IO_FONT_SIZE_1 mt-2">
                      <p className="my-auto TC_COL_1 ">Investment</p>
                      <p className="my-auto TC_COL_1">Rs. 3,00,000</p>
                    </span>
                    <span className="fs-6 d-flex justify-content-center mt-3">
                      <p className="TC_COL_1 IO_BOTTOM_BORDER">Know More</p>
                    </span>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </body>
      {/* --------------Bottom_Content_Investment_Opportunities---------------- */}
      <div className="IO_TC_BG">
        <div className="container">
          <div className="row pt-5 pb-5 justify-content-between text-center text-md-start align-items-center">
            <div className="col-sm-6 FONT_1_SANS_SERIF">
              <h1 className="TC_COL_1">
                Start Your Investment Journey&nbsp;
                <span className="TC_COL_2">with Rs. 15000</span>
              </h1>
              <p className="TC_COL_1">
                Take a step towards healthier planet. Join us.
              </p>
            </div>
            <div className="col-sm-3 my-auto ">
              <p className="TC_COL_1 text-center">Start Your Eco-Investment</p>
              <p className="d-flex justify-content-center">
                <button className="TC_BG_2 HEAD_INVEST_NOW_BTN w-50 p-2 rounded-3 fw-bold">
                  <a href="/">
                    Invest Now <i class="bi bi-arrow-right"></i>
                  </a>
                </button>
              </p>
            </div>
            <div className="col-sm-3 my-auto ">
              <p className="TC_COL_1 text-center">or call us now</p>
              <p className="d-flex justify-content-center">
                <button className="TC_BG_2 HEAD_INVEST_NOW_BTN w-50 p-2 rounded-3 fw-bold">
                  <a href="/">+91 9876543210</a>
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Investment_Opportunities;
