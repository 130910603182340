import React from 'react';
import './Contact.css';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';

const Contact = () => {
  return (
    <>
    <Header/>
    <div className='contactForm FONT_1_POPPINS'>
      <nav>
        <div className='navDetials pt-5'>
          <div className='homeContact'>
            <span><i class="bi bi-house-door-fill"></i>
            <h6>Home</h6><i class="bi bi-chevron-right"></i>
            <h6>Contact Us</h6>
            </span>
            <h3>Contact Us</h3>

          </div>
          <div className='fingerImage'>
            <img src='https://pixalivegroup.com/assets/images/bg-shape-3.png' alt='Authentication Image'/>
          </div>
        </div>
      </nav>
      <div className='contactFormsDetials'>
        <div className='FormDetails'>
          <h4><a href='tel:+91 87785 84566'>+918778584566</a></h4>
          <h5>Official Email</h5>
          <p className='emailpixalive'><a href='mailto:contact@pixalive.me'>contact@pixalive.me</a></p>
          <h5>Location</h5>
          <p className='text-white'>Third floor, No 35/2 Konappana Agrahara, Hosur Rd, 2nd phase,<br/> Electronic City, Karnataka 560100</p>
          <span className='iconsContact'>
          <a href='https://www.facebook.com/pixalive.app.9/'><i class="bi bi-facebook"></i></a>
          <a href='https://twitter.com/pixaliveo?lang=en'><i class="bi bi-twitter-x"></i></a>
          <a href="https://in.linkedin.com/company/pixalive" ><i class="bi bi-linkedin"></i></a>
 </span>
        </div>
        <div className='messageBox'>
          <h6>HAVE QUESTIONS?</h6>
          <h3>Send us a Message</h3> 
          <div className='NameBox'>
            <input type='text' placeholder='Name' maxLength={30}/>

          </div>
          <div className='NameBox phoneEmail'>
            <input type='email' placeholder='email' maxLength={20}/>
            <input type='text' placeholder='Mobile Number' maxLength={10}/>    
          </div>
          <div className='NameBox'>
            <textarea rows={5 } placeholder='Tell Us About Project'/>
            </div>
            <button className='getDetialsbtn'><span><i class="bi bi-telegram"></i></span>Get In Touch</button>

        </div>
        



      </div>
      <div className="mapsDetails">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.9179663074456!2d77.6680843748179!3d12.848574817564112!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1589d7163475%3A0xeb85ca7b7efa7262!2sPixalive!5e0!3m2!1sen!2sin!4v1714035606384!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{border:0}}
          allowfullscreen=""
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
          </div>
          <Footer/>
          </>
  )
}

export default Contact
