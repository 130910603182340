import React, { useState } from "react";
import "./Invest.css";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";
import tree from "../../../Assets/just tree.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "#4E7F1A" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "#4E7F1A" }}
      onClick={onClick}
    />
  );
}

function Invest() {
  const [slideIndex, setSlideIndex] = useState(0);
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll at once
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    centerMode: true,
    centerPadding: "30px", // Padding on the sides of the center slide
    responsive: [
      {
        breakpoint: 768, // Adjust these breakpoints according to your needs
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "10px",
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerPadding: "20px",
        },
      },
    ],
  };

  return (
    <div>
      <Header />
      <body className="mt-5 mb-5 pt-5 overflow-x-hidden">
        <div className="container-fluid">
          <div className="row d-flex">
            <div className="col-lg-6 col-md-6 ">
              <div className="row ">
                <div className="col-6 col-lg-6 col-md-6 mb-5">
                  <img
                    src="https://amyrafarms.com/cdn/shop/articles/completion_1080_x_900_px.png?v=1687898941&width=720"
                    alt="coming-soon"
                    className="img-fluid"
                  />
                </div>
                <div className="col-6 col-lg-6 col-md-6">
                  <img
                    src="https://amyrafarms.com/cdn/shop/articles/completion_1080_x_900_px.png?v=1687898941&width=720"
                    alt="coming-soon"
                    className="img-fluid"
                  />
                </div>
                <div className="col-6 col-lg-6 col-md-6">
                  <img
                    src="https://amyrafarms.com/cdn/shop/articles/completion_1080_x_900_px.png?v=1687898941&width=720"
                    alt="coming-soon"
                    className="img-fluid"
                  />
                </div>
                <div className="col-6 col-lg-6 col-md-6">
                  <img
                    src="https://amyrafarms.com/cdn/shop/articles/completion_1080_x_900_px.png?v=1687898941&width=720"
                    alt="coming-soon"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className=" col-lg-6 col-md-6 col-12">
              <div className="px-5">
                <div>
                  <h6 className="text-start invest_invest_head">
                    Pixalive Farms
                  </h6>
                </div>
                <div>
                  <h1 className="text-start invest_invest_title mb-4">
                    Hillsong Coffee Estate - Managed Coffee Estate in Sakleshpur
                  </h1>
                </div>
                <div>
                  <h4>Rs. 200,000.00</h4>
                </div>
                <div>
                  <h7>Tax included. Shipping calculated at checkout.</h7>
                </div>
                <div className="d-flex justify-content-start  gap-3 mt-4 invest_invest_check">
                  <div>
                    <h5>Return Rate </h5>
                  </div>
                  <div>
                    <h5>14%-17% IRR</h5>
                  </div>
                </div>
                <div className="d-flex justify-content-start  gap-5 mt-4 invest_invest_check">
                  <div>
                    <h5>Payout </h5>
                  </div>
                  <div>
                    <h5> Once a Year</h5>
                  </div>
                </div>
                <div className="d-flex justify-content-start  gap-5 mt-4 invest_invest_check">
                  <div>
                    <h5>Lock-In </h5>
                  </div>
                  <div>
                    <h5> 1 Year</h5>
                  </div>
                </div>
                <div className="d-flex justify-content-start  gap-3 mt-4 invest_invest_check">
                  <div>
                    <h5>Investment </h5>
                  </div>
                  <div>
                    <h5> Rs. 23,00,000</h5>
                  </div>
                </div>
                <button className="btn  w-50 mt-4 d-flex justify-content-center align-items-center invest_invest_intest_btn">
                  Invest
                </button>

                <div className="mt-4 d-flex justify-content-center w-100">
                  <table className="table">
                    <tr>
                      <td>Revenue Structure</td>
                      <td>70 (Owner):30 (Amyra Farms)</td>
                    </tr>
                    <tr>
                      <td>Price per acre</td>
                      <td>Rs 25 Lakhs Starting</td>
                    </tr>
                    <tr>
                      <td>Total Acres</td>
                      <td>150</td>
                    </tr>
                    <tr>
                      <td>Booking Amount</td>
                      <td>Rs. 200000</td>
                    </tr>
                    <tr>
                      <td>Preliminary IRR</td>
                      <td>14 - 17%*</td>
                    </tr>
                    <tr>
                      <td>Current Plantation</td>
                      <td>Robusta Coffee, Black Pepper, Cardamom, Areca nut</td>
                    </tr>
                  </table>
                </div>

                <div className="mt-2 text-start invest_invest_content_text">
                  Amyra Farms Hillsong Coffee Estate where the hills of
                  Sakleshpur resonate with the robust melody of coffee
                  cultivation, creating a symphony of flavors.
                </div>
                <div className="mt-2 text-start invest_invest_unlock">
                  Unlock the Potential: Invest in Our Diverse Crop Portfolio in
                  Sakleshpur!
                </div>
                <div className="mt-2 text-start invest_invest_content_text">
                  Explore Our Managed Coffee Estate for Sale, Flourishing with
                  Robusta Coffee, Black Pepper, Areca Nut, Ginger, and Cardamom
                </div>
                <div className="d-flex mt-4 align-items-center">
                  <img
                    src={tree}
                    className="img-fluid"
                    height={30}
                    width={30}
                    alt="Tree"
                  />
                  <div className=" d-flex ">
                    <div className="text-muted">
                      {" "}
                      <span className="font-weight-bold">Robusta Coffee:</span>
                      It thrives in lower altitudes and is more resistant to
                      pests and diseases, making it a popular choice for
                      cultivation in various regions.
                    </div>
                  </div>
                </div>
                <div className="d-flex mt-4 align-items-center">
                  <img
                    src={tree}
                    className="img-fluid"
                    height={30}
                    width={30}
                    alt="Tree"
                  />
                  <div className=" d-flex ">
                    <div className="text-muted">
                      {" "}
                      <span className="font-weight-bold"> Black Pepper:</span>It
                      thrives in lower altitudes and is more resistant to pests
                      and diseases, making it a popular choice for cultivation
                      in various regions.
                    </div>
                  </div>
                </div>
                <div className="d-flex mt-4 align-items-center">
                  <img
                    src={tree}
                    className="img-fluid"
                    height={30}
                    width={30}
                    alt="Tree"
                  />
                  <div className=" d-flex ">
                    <div className="text-muted">
                      {" "}
                      <span className="font-weight-bold">Areca Nut:</span>It
                      thrives in lower altitudes and is more resistant to pests
                      and diseases, making it a popular choice for cultivation
                      in various regions.
                    </div>
                  </div>
                </div>
                <div className="d-flex mt-4 align-items-center">
                  <img
                    src={tree}
                    className="img-fluid"
                    height={30}
                    width={30}
                    alt="Tree"
                  />
                  <div className=" d-flex ">
                    <div className="text-muted">
                      {" "}
                      <span className="font-weight-bold">Cardamom:</span>It
                      thrives in lower altitudes and is more resistant to pests
                      and diseases, making it a popular choice for cultivation
                      in various regions.
                    </div>
                  </div>
                </div>
                <div className="mt-2 text-start invest_invest_content_text">
                  Join us at Hillsong Coffee Estate, where every cup tells a
                  story of Sakleshpur's tranquil hills and the robust character
                  of its coffee. Invest in the rhapsody of robusta, and let the
                  journey begin.
                </div>
              </div>
            </div>

            <h4 className="invest_invest_title">Similar Products:</h4>
            <div className="fullslick px-5 ">
              <Slider {...settings}>
                <div className="iteml px-5" data-position="0">
                  <img
                    src="https://amyrafarms.com/cdn/shop/articles/completion_1080_x_900_px.png?v=1687898941&width=720"
                    alt="coming-soon"
                    className="img-fluid"
                  />
                </div>

                <div className="iteml px-5" data-position="1">
                  <img
                    src="https://amyrafarms.com/cdn/shop/articles/completion_1080_x_900_px.png?v=1687898941&width=720"
                    alt="coming-soon"
                    className="img-fluid"
                  />
                </div>

                <div className="iteml px-5" data-position="2">
                  <img
                    src="https://amyrafarms.com/cdn/shop/articles/completion_1080_x_900_px.png?v=1687898941&width=720"
                    alt="coming-soon"
                    className="img-fluid"
                  />
                </div>

                <div className="iteml px-5" data-position="3">
                  <img
                    src="https://amyrafarms.com/cdn/shop/articles/completion_1080_x_900_px.png?v=1687898941&width=720"
                    alt="coming-soon"
                    className="img-fluid"
                  />
                </div>

                <div className="iteml px-5" data-position="4">
                  <img
                    src="https://amyrafarms.com/cdn/shop/articles/completion_1080_x_900_px.png?v=1687898941&width=720"
                    alt="coming-soon"
                    className="img-fluid"
                  />
                </div>

                <div className="iteml px-5" data-position="5">
                  <img
                    src="https://amyrafarms.com/cdn/shop/articles/completion_1080_x_900_px.png?v=1687898941&width=720"
                    alt="coming-soon"
                    className="img-fluid"
                  />
                </div>

                <div className="iteml px-5" data-position="6">
                  <img
                    src="https://amyrafarms.com/cdn/shop/articles/completion_1080_x_900_px.png?v=1687898941&width=720"
                    alt="coming-soon"
                    className="img-fluid"
                  />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </body>

      <Footer />
    </div>
  );
}
export default Invest;
