import React, { useRef } from "react";
import Sortable from "sortablejs";

function SortableComponents() {
  const listRef = useRef(null);

  React.useEffect(() => {
    Sortable.create(listRef.current, {
      animation: 150,
    });
  }, []);

  return (
    <>
      <div className="container-fluid mb-5">
        <div className="container p-3 shadow">
        <div className="row">
          <div className="col-lg-6 ">
            <h1 className="fw-bold text-black">
              We do the heavy-lifting for{" "}
              <span className="text-success">you to make investment easy</span>{" "}
            </h1>
            <p className="text-muted small">
              Get access to curated, risk-assessed opportunities designed for
              retail investors.
            </p>
          </div>
          <div className="col-lg-6  ">
            <ul
              className="bg-dark fw-bold text-white p-3  fs-1 "
              ref={listRef}
              style={{ listStyle: "none" }}
            >
              <li>
                <h6>
                  Risk Assessed: The deals available on our platform undergo our
                  rigorous and thorough internal credit and risk framework.
                </h6>
                <hr />
              </li>

              <li>
                <h6>
                  Diversified Investments: The deals available on our platform
                  undergo our rigorous and thorough internal credit and risk
                  framework.
                </h6>
                <hr />
              </li>

              <li>
                <h6>
                  Ease of Investing: The deals available on our platform undergo
                  our rigorous and thorough internal credit and risk framework.
                </h6>
                <hr />
              </li>
            </ul>
          </div>
        </div>
        </div>

        
      </div>
    </>
  );
}

export default SortableComponents;
