import React from "react";
import "./OurExperts.css";
import { FaSolarPanel } from "react-icons/fa";
import { GiKaleidoscopePearls } from "react-icons/gi";
import { MdNotListedLocation } from "react-icons/md";
import Header from './../../../Components/Header/Header';
import Footer from './../../../Components/Footer/Footer';

const OurExperts = () => {
  const experts = [
    {
      id: 1,
      name: "Abhishek",
      profile:
        "https://images.pexels.com/photos/697509/pexels-photo-697509.jpeg?auto=compress&cs=tinysrgb&w=400",
      icon: <FaSolarPanel />,
      iconname: "Solar Energy",
    },
    {
      id: 2,
      name: "Achal singh",
      profile:
        "https://images.pexels.com/photos/1542085/pexels-photo-1542085.jpeg?auto=compress&cs=tinysrgb&w=600",
      icon: <GiKaleidoscopePearls />,
      iconname: "Pearl Farming",
    },
    {
      id: 3,
      name: "Ajay Tyagi",
      profile:
        "https://images.pexels.com/photos/681637/pexels-photo-681637.jpeg?auto=compress&cs=tinysrgb&w=600",
      icon: <MdNotListedLocation />,
      iconname: "Organic",
    },
    {
      id: 4,
      name: "Altaf Khan",
      profile:
        "https://images.pexels.com/photos/1194806/pexels-photo-1194806.jpeg?auto=compress&cs=tinysrgb&w=600",
      icon: <FaSolarPanel />,
      iconname: "Goatry",
    },
    {
      id: 5,
      name: "Amandeep",
      profile:
        "https://images.pexels.com/photos/762020/pexels-photo-762020.jpeg?auto=compress&cs=tinysrgb&w=600",
      icon: <FaSolarPanel />,
      iconname: "Dragon Fruit",
    },
    {
      id: 6,
      name: "Amanjit",
      profile:
        "https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&w=600",
      icon: <FaSolarPanel />,
      iconname: "Wheat",
    },
    {
      id: 7,
      name: "Amanpreet",
      profile:
        "https://images.pexels.com/photos/3785079/pexels-photo-3785079.jpeg?auto=compress&cs=tinysrgb&w=600",
      icon: <FaSolarPanel />,
      iconname: "Floriculture",
    },
    {
      id: 8,
      name: "Amarjit",
      profile:
        "https://images.pexels.com/photos/3779760/pexels-photo-3779760.jpeg?auto=compress&cs=tinysrgb&w=600",
      icon: <FaSolarPanel />,
      iconname: "Organic",
    },
    {
      id: 9,
      name: "Amit Kumar",
      profile:
        "https://images.pexels.com/photos/1462980/pexels-photo-1462980.jpeg?auto=compress&cs=tinysrgb&w=600",
      icon: <FaSolarPanel />,
      iconname: "Pearl Farming",
    },
    {
      id: 10,
      name: "Adithya",
      profile:
        "https://images.pexels.com/photos/428361/pexels-photo-428361.jpeg?auto=compress&cs=tinysrgb&w=600",
      icon: <FaSolarPanel />,
      iconname: "Solar Energy",
    },
    {
      id: 11,
      name: "Among Singh",
      profile:
        "https://images.pexels.com/photos/343717/pexels-photo-343717.jpeg?auto=compress&cs=tinysrgb&w=600",
      icon: <FaSolarPanel />,
      iconname: "Solar Energy",
    },
    {
      id: 12,
      name: "Aman Kumar",
      profile:
        "https://images.pexels.com/photos/3763188/pexels-photo-3763188.jpeg?auto=compress&cs=tinysrgb&w=600",
      icon: <FaSolarPanel />,
      iconname: "Solar Energy",
    },
  ];
  return (
    <>
    <Header />
      <div className="our_experts mt-5 pt-5 FONT_1_POPPINS">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Our Expert</h1>
            </div>
            {experts.map((expert) => (
              <div className="col-md-2 text-center">
                <div className="position-relative d-flex flex-column align-items-center">
                  {" "}
                  {/* Changed to flex-column and added align-items-center */}
                  <img
                    src={expert.profile}
                    alt={expert.profile}
                    className="img-fluid"
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="mt-5 pt-4 position-absolute">
                    {" "}
                    {/* Added margin-top for spacing */}
                    <span
                      className="text-white bg-dark rounded-pill p-1 text-center"
                      style={{ fontSize: "13px", opacity: "0.7" }}
                    >
                      <span className="fs-5">{expert.icon}</span>{" "}
                      {expert.iconname}
                    </span>
                  </div>
                </div>
                <div>
                  <p className="mx-3 py-2">{expert.name}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default OurExperts;
