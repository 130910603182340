import React from "react";
import "./GoGreen.css";

const GoGreen = () => {
  const cardData = [
    {
      id: 1,
      title: "10 Billion",
      description: "The World population By 2050",
      buttonText: "Invest Now",
      buttonLink: "/enquiry",
      iconClass: "bi bi-people",
    },
    {
      id: 1,
      title: "70%",
      description: "More Food Will Need  Produced",
      buttonText: "Invest Now",
      buttonLink: "/enquiry",
      iconClass: "bi bi-flower1",
    },

    {
      id: 1,
      title: "5,00,000",
      description: "The Amount of U.S.FarmLand ",
      buttonText: "Invest Now",
      buttonLink: "/enquiry",
      iconClass: "bi bi-coin",
    },

    {
      id: 1,
      title: "11.5%",
      description: "The Average Annual return",
      buttonText: "Invest Now",
      buttonLink: "/enquiry",
      iconClass: "bi bi-coin",
    },
  ];
  return (
    <div className="container-fluid mt-5 mb-3 shadow Go_background_image ">
      <div className="container mb-5 mt-5  ">
        <div className="row  ">
          {cardData.map((card) => (
            <div className="col-lg-3 d-flex justify-content-center  col-12   mt-5 pt-5" key={card.id}>
              <div
                className="card "
                style={{
                  width: "17rem",
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                  border: "2px solid #ccc",
                  borderRadius: "10px",
                  boxShadow: "0 10px 8px rgba(0, 0, 0, 0.2)",
                  textAlign:"center"
                }}
              >
                <i className={`${card.iconClass} iconstyle`}></i>
                <div className="card-body text-center">
                  <h1>{card.title}</h1>
                  <p className="small fw-bold">{card.description}</p>
                  <div>
                    <a href={card.buttonLink} className="btn btn-attractive">
                      {card.buttonText}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GoGreen;
