import React from "react";
import "./How_To_Participate.css";

const How_To_Participate = () => {
  return (
    <>
      <body className="py-5 TC_BG_3">
        <div className="container FONT_1_SANS_SERIF">
          <div className="row text-center">
            <div className="col-12 TC_COL_1">
              <h1>
                <span className="">How to&nbsp;</span>

                <span className="">Participate?</span>
              </h1>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-3 HOW_TO_PARTICIPATE_HOVER p-3 text-center">
              <a href="/Login">
                <img
                  src="https://sssprematharu.org/assets/img/premth-login-icon.svg"
                  alt=""
                  className="img-fluid w-50"
                />
                <p className="TC_COL_1 fw-bold mt-5">Login To Your Account</p>
              </a>
            </div>
            <div className="col-3 HOW_TO_PARTICIPATE_HOVER p-3 text-center">
              <a href="/Login">
                <img
                  src="https://sssprematharu.org/assets/img/premth-capture-icon.svg"
                  alt=""
                  className="img-fluid w-50"
                />
                <p className="TC_COL_1 fw-bold mt-5">Capture Your Tree Photo</p>{" "}
              </a>
            </div>
            <div className="col-3 HOW_TO_PARTICIPATE_HOVER p-3 text-center">
              <a href="/Login">
                <img
                  src="https://sssprematharu.org/assets/img/premth-review-icon.svg"
                  alt=""
                  className="img-fluid w-50"
                />
                <p className="TC_COL_1 fw-bold mt-5">Submit Your Photo</p>
              </a>
            </div>
            <div className="col-3 HOW_TO_PARTICIPATE_HOVER p-3 text-center">
              <a href="/Login">
                <img
                  src="https://sssprematharu.org/assets/img/premth-share-icon.svg"
                  alt=""
                  className="img-fluid w-50"
                />
                <p className="TC_COL_1 fw-bold mt-5">Share On Social Media</p>
              </a>
            </div>
          </div>
        </div>
{/* 
        <div className="container pt-5 FONT_1_SANS_SERIF">
          <div className="row">
            <div className="col-12 text-center">
              <p className="fs-1 TC_COL_1">User Access</p>
            </div>
          </div>
          <div className="row text-black">
            <div className="col-12">
              <ul className="fs-3">
                <li>
                  Participants can capture only photos of a newly planted tree,
                  or sapling.
                </li>
                <li>
                  Long lasting varieties of trees are preferred and they must be
                  planted in Mother Earth, not in pots.
                </li>
                <li>
                  Selfies or photos with human faces will not be verified.
                </li>
                <li>
                  Participants are required to give geolocation and camera
                  permission to take the photos.
                </li>
                <li>
                  Your location and camera will be used only when you take
                  photos of the plant.
                </li>
                <li>
                  Your photos will be verified after uploading. Any improper
                  visuals will be removed.
                </li>
                <li>
                  Any kind of spamming will lead to the disabling of your
                  account.
                </li>
              </ul>
            </div>
          </div>
        </div> */}
      </body>
    </>
  );
};

export default How_To_Participate;
