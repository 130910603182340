import React from "react";
import "./Agri_Investments.css";

const Agri_Investments = () => {
  return (
    <div>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-12">
            <h1 className="text-center fw-bold TC_COL_4 change_ss">
              Agri Investment <br /> Made Easy
            </h1>
            <p className="text-center text-secondary fs-4 ">
              First -to-market , Feature rich agricultural investment platform{" "}
              <br />
              That allow you to invest in exciting opportunities securly
            </p>
          </div>
        </div>
        <div className="row  mt-5  ">
          <div className="col-lg-4 col-sm-12 pt-5 mt-5">
            <div>
              <div className="text-end py-2">
                <span className="TC_BG_6 p-2 rounded px-3 text-white fw-bold">
                  1
                </span>
              </div>
              <h3 className="flex-row-end font-weight-bold TC_COL_1 text-end">
                Signup e-browser
              </h3>
              <p className="text-end">
                through exciting opportunities as and when <br /> they are
                available
              </p>
            </div>
            <div className="pt-5 mt-5">
              <div className="text-end py-2">
                <span className="TC_BG_6 p-2 rounded px-3 text-white fw-bold">
                  2
                </span>
              </div>
              <h3 className="flex-row-end fw-bold TC_COL_1 text-end">
                Choose A Project
              </h3>
              <p className="text-end">
                and invest as low as $20,000 to become a co-owner of the fund
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12 overflow-hidden">
            <div className="text-center">
              <img
                src="https://faab.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FHand_Mobile.78a54c4b.png&w=1920&q=75"
                className="w-75"
                alt=""
              />
            </div>{" "}
          </div>
          <div className="col-lg-4 col-sm-12  pt-5 mt-5">
            <div className="">
              <div className="text-start py-2">
                <span className="TC_BG_6 p-2 rounded px-3 text-white fw-bold">
                  3
                </span>
              </div>
              <h3 className="flex-row-end fw-bold TC_COL_1">
                Watch Your Portfolio
              </h3>
              <p className="">
                grow through our app and track your fund performance
              </p>
            </div>
            <div className="pt-5 mt-5">
              <div className="text-start py-2">
                <span className="TC_BG_6 p-2 rounded px-3 text-white fw-bold">
                  4
                </span>
              </div>
              <h3 className="flex-row-end fw-bold TC_COL_1">Earn Returns</h3>
              <p className="">
                that are exceptional and tax-free, and choose where you want
                them
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row d-flex flex-wrap">
        <div className="col-4">
          {" "}
          <h3 className="flex-row-end  font-weight-bold text-secondary text-end">
            <button className="btn btn-secondary">1</button>
          </h3>
        </div>
        <div className="col-4">img</div>
        <div className="col-4">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem,
          dolorem. A, cum. Quidem, accusantium earum libero, fugit suscipit quod
          molestias laboriosam harum maiores totam aliquid dolorem deserunt
          nihil corrupti perspiciatis?
        </div>
      </div> */}
    </div>
  );
};

export default Agri_Investments;
