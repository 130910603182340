import React, { useRef } from "react";
import "./CreatePlan.css";
import { BsThreeDots } from "react-icons/bs";
import { createPlanApi } from "../../api/createplan";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { uploadFile } from "../../Utils/FileUpload";
import { getconsumerId } from "../../Utils/Storage";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const CreatePlan = () => {
  const cardData = [
    {
      id: "1",
      profile:
        "https://amyrafarms.com/cdn/shop/products/7.png?v=1681059291&width=713",
      name: "kailash",
      icon: <BsThreeDots />,
      from: "farmer, New Delhi",
      time: "7 hours ago",
      description:
        "Wheat: Sustaining civilizations for millennia, wheat stands tall as a staple crop, feeding billions worldwide.",
      img: "https://images.pexels.com/photos/326082/pexels-photo-326082.jpeg?auto=compress&cs=tinysrgb&w=600",
    },
    {
      id: "2",
      profile:
        "https://amyrafarms.com/cdn/shop/products/7.png?v=1681059291&width=713",
      name: "kailash",
      icon: <BsThreeDots />,
      from: "farmer, New Delhi",
      time: "7 hours ago",
      description:
        "Rice: Sustaining civilizations for millennia, wheat stands tall as a staple crop, feeding billions worldwide.",
      img: "https://images.pexels.com/photos/326082/pexels-photo-326082.jpeg?auto=compress&cs=tinysrgb&w=600",
    },
  ];

  const initialState = {
    consumerId: getconsumerId(),
    name: "",
    startDate: "",
    endDate: "",
    exp_IrrFrom: "",
    // exp_IrrTo: "",
    description: "",
    category: "",
    location: "",
    sortDescription: "",
    dailyEarnigs: "",
    minInvesment: "",
    totalInvesment: "",
    tenure: "",
    limitedUser: "",
    imageUrl: "",
    expectedRevenue: "",
  };

  const initialStateErrors = {
    name: { required: false },
    startDate: { required: false },
    endDate: { required: false },
    exp_IrrFrom: { required: false },
    // exp_IrrTo: { required: false },
    description: { required: false },
    category: { required: false },
    location: { required: false },
    sortDescription: { required: false },
    dailyEarnigs: { required: false },
    minInvesment: { required: false },
    totalInvesment: { required: false },
    tenure: { required: false },
    imageUrl: { required: false },
    limitedUser: { required: false },
    expectedRevenue: { required: false },
  };

  const [inputs, setInputs] = React.useState(initialState);
  const [errors, setErrors] = React.useState(initialStateErrors);
  const [submitted, setSubmitted] = React.useState(false);

  const handleValidation = (data) => {
    const error = { ...initialStateErrors };
    if (data.name === "") {
      error.name.required = true;
    }
    if (data.startDate === "") {
      error.startDate.required = true;
    }
    if (data.endDate === "") {
      error.endDate.required = true;
    }
    if (data.exp_IrrFrom === "") {
      error.exp_IrrFrom.required = true;
    }
    // if (data.exp_IrrTo === "") {
    //   error.exp_IrrTo.required = true;
    // }
    if (data.description === "") {
      error.description.required = true;
    }
    if (data.category === "") {
      error.category.required = true;
    }
    if (data.location === "") {
      error.location.required = true;
    }
    if (data.sortDescription === "") {
      error.sortDescription.required = true;
    }
    if (data.dailyEarnigs === "") {
      error.dailyEarnigs.required = true;
    }
    if (data.minInvesment === "") {
      error.minInvesment.required = true;
    }
    if (data.totalInvesment === "") {
      error.totalInvesment.required = true;
    }
    if (data.tenure === "") {
      error.tenure.required = true;
    }
    if (data.imageUrl === "") {
      error.imageUrl.required = true;
    }
    if (data.limitedUser === "") {
      error.limitedUser.required = true;
    }
    if (data.expectedRevenue === "") {
      error.expectedRevenue.required = true;
    }
    return error;
  };
  const handleErrors = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const prop = obj[key];
        if (prop.required === true || prop.valid === true) {
          return false;
        }
      }
    }
    return true;
  };

  const handleInputs = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
    if (submitted) {
      const newError = handleValidation({
        ...inputs,
        [e.target.name]: e.target.value,
      });
      setErrors(newError);
    }
  };
  var navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("date", inputs.startDate, inputs.endDate);
    const newError = handleValidation(inputs);
    setErrors(newError);
    setSubmitted(true);

    if (handleErrors(newError)) {
      createPlanApi(inputs)
        .then((res) => {
          if (res?.data?.message === "Saved Successfully") {
            toast.success(res?.data?.message);
            navigate("/Investment_Collections");
            //console.log("eniyaan", res);
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err?.res?.data?.message);
        });
    }
  };

  const fileInputRef = useRef(null);

  const handleImageUpload = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (e) => {
    const file = e?.target?.files[0];
    const folder = "createplan/imageUrl/";
    if (file) {
      uploadFile(file, folder)
        .then((res) => {
          const image = res?.Location;
          setInputs({ ...inputs, imageUrl: image });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <Header />
      <div className="create_plan FONT_1_POPPINS mt-5 pt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="TC_COL_1">Create Plan</h1>
            </div>
            <div className="col-md-8 planimg">
              <img
                src={inputs.imageUrl || "https://via.placeholder.com/600"}
                alt="Plan"
                name="imageUrl"
                style={{ width: "100%", height: "75%" }}
              />
              {errors.imageUrl?.required ? (
                <span className="text-danger">This field is required</span>
              ) : null}
            </div>

            <div className="col-md-4">
              <div
                className="addimg p-4 rounded-4 text-center"
                onClick={handleImageUpload}
              >
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={handleFileInputChange}
                />
                <span className="fs-4">+</span>
                <br />
                <span>Add image</span>
              </div>
            </div>
            <div className="col-md-8 crete_plan_inputs">
              <label className="fw-bold">Name of the Farms</label>
              <input
                className="form-control "
                type="text"
                name="name"
                onChange={handleInputs}
                value={inputs.name}
                placeholder="Enter the name of the farms"
              />
              {errors.name?.required ? (
                <span className="text-danger">This field is required</span>
              ) : null}
              <br />
              <label className="fw-bold">Location</label>
              <input
                className="form-control "
                type="text"
                name="location"
                onChange={handleInputs}
                value={inputs.location}
                placeholder="Enter your location"
              />
              {errors.location?.required ? (
                <span className="text-danger">This field is required</span>
              ) : null}
              <br />
              <label className="fw-bold">Description</label>
              <textarea
                className="form-control"
                placeholder="Enter Description"
                onChange={handleInputs}
                value={inputs.description}
                name="description"
              ></textarea>
              {errors.description.required ? (
                <span className="text-danger ">This field is required.</span>
              ) : errors.description.valid ? (
                <span className="text-danger">Enter a description</span>
              ) : null}
              <br />
              <label className="fw-bold">Category</label>
              <select
                name="category"
                className="form-select"
                onChange={(e) => handleInputs(e)}
                value={inputs.category}
                aria-label="Default select example"
              >
                <option selected>select your crop Category</option>
                <option value="Farming">Farming</option>
                <option value="Breeding">Breeding</option>
                <option value="Planting">Planting</option>
              </select>
              {errors.category?.required ? (
                <span className="text-danger">This field is required</span>
              ) : null}
              <br />
              <label className="fw-bold">Tenure</label>
              <input
                className="form-control "
                type="text"
                name="tenure"
                value={inputs.tenure}
                onChange={handleInputs}
                placeholder="Enter the name of the farms"
              />
              {errors.tenure?.required ? (
                <span className="text-danger">This field is required</span>
              ) : null}
              <br />
              <label className="fw-bold">Minimum investment</label>
              <input
                className="form-control "
                type="text"
                name="minInvesment"
                value={inputs.minInvesment}
                onChange={handleInputs}
                placeholder="Enter the Minimum investment"
              />
              {errors.minInvesment?.required ? (
                <span className="text-danger">This field is required</span>
              ) : null}
              <br />
              <label className="fw-bold">Total Investment</label>
              <input
                className="form-control "
                type="text"
                name="totalInvesment"
                value={inputs.totalInvesment}
                onChange={handleInputs}
                placeholder="Enter the Total Investment"
              />
              {errors.totalInvesment?.required ? (
                <span className="text-danger">This field is required</span>
              ) : null}
              <br />
              <div className="row">
                <div className="col-md-4">
                  <label className="fw-bold">Start date</label>
                  <input
                    className="form-control"
                    type="date"
                    name="startDate"
                    onChange={handleInputs}
                    value={inputs.startDate}
                    placeholder="Enter the name of the farms"
                  />
                  {errors.startDate?.required ? (
                    <span className="text-danger">This field is required</span>
                  ) : null}
                </div>

                <div className="col-md-4">
                  <label className="fw-bold">End date</label>
                  <input
                    className="form-control"
                    type="date"
                    name="endDate"
                    onChange={handleInputs}
                    value={inputs.endDate}
                    placeholder="Enter the name of the farms"
                  />
                  {errors.endDate?.required ? (
                    <span className="text-danger">This field is required</span>
                  ) : null}
                </div>
              </div>
              <br />
              <label className="fw-bold">No of People</label>
              <span className="text-muted">(Set Limited Users)</span>
              <input
                className="form-control"
                type="number"
                name="limitedUser"
                value={inputs.limitedUser}
                onChange={handleInputs}
                placeholder="Enter the number of People"
              />
              {errors.limitedUser?.required ? (
                <span className="text-danger">This field is required</span>
              ) : null}
              <br />
              <label className="fw-bold">Expected Revenue</label>{" "}
              <span className="text-muted">(Vaule Should be 18% to 25%)</span>
              <input
                className="form-control"
                type="number"
                name="expectedRevenue"
                value={inputs.expectedRevenue}
                onChange={handleInputs}
                placeholder="Enter the number of People"
              />
              {errors.expectedRevenue?.required ? (
                <span className="text-danger">This field is required</span>
              ) : null}
              <br />
            </div>
            <div className="col-md-4 ">
              <label className="fw-bold">Your Recent Plan </label>
              {cardData.map((item) => (
                <div key={item.id} className="rounded-3 shadow p-3 ">
                  <div className="d-flex">
                    <div className="d-flex gap-2">
                      <div className="profile">
                        <img
                          src={item.profile}
                          alt="Profile"
                          className="img-fluid"
                        />
                      </div>
                      <span className="profile_details d-flex flex-column  me-5 ">
                        <span className="TC_COL_1 fw-bold">{item.name}</span>
                        <span>{item.from} </span>
                      </span>
                    </div>
                    <span className="profile_details d-flex flex-column ">
                      <span className="TC_COL_1 fw-bold fs-5 text-end">
                        {item.icon}
                      </span>
                      <span style={{ fontSize: "10px" }}>{item.time} </span>
                    </span>
                  </div>
                  <p>{item.description}</p>
                  <img src={item.img} className="img-fluid rounded-2" />
                  <button className="btn btn-success btn-block mt-2">
                    View Details
                  </button>
                </div>
              ))}
            </div>
            <div className="col-md-8">
              <span className="px-3">
                <input
                  type="checkbox"
                  className="form-check-input text-success"
                />
                Make it private
              </span>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.9179663074456!2d77.6680843748179!3d12.848574817564112!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1589d7163475%3A0xeb85ca7b7efa7262!2sPixalive!5e0!3m2!1sen!2sin!4v1714035606384!5m2!1sen!2sin"
                width="100%"
                height="350"
                name="location"
                allowfullscreen=""
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>

              <button
                className="btn btn-success btn-block mt-4 mb-3"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CreatePlan;
