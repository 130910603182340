import React, { useState } from 'react';
import "./Accordion_FAQ.css";
import Accordion from "react-bootstrap/Accordion";


const Accordion_FAQ = () => {
  // accordion
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setActiveAccordion((prevIndex) => (prevIndex === index ? null : index));
  };
  return (
    <div className="accordion_faq">
      <div className="containe-fluid">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p className="display-4">Organic FAQs</p>
            </div>
          </div>
        </div>
        <div className="row accordion_bg ">
        <div className='col-lg-6'></div>
         <div className='col-lg-6 col-sm-12 '>
          <p classNam=''>Frequently Asked Questions About <br/> <span>Organic Farming</span></p>
          
         </div>
        </div><br/>
        <div className="container ">
          <div className="row">
            <div className="col-md-12">
            <section className="accordion ">
      <ul className="accordion-list">
        {[1].map((index) => (
          <li key={index} className={`accordion-item ${activeAccordion === index ? 'open' : ''}`}>
            <div className="accordion-header" onClick={() => toggleAccordion(index)}>
             <b>1 - What is organic farming and why is it important?</b>
             <p className='fw-bold'>  </p>
              <p className="icon">{activeAccordion === index ? '-' : '+'}</p>
            </div>

            <section className={`content ${activeAccordion === index ? 'show' : ''}`}>
              {[1].slice(0, index).map((subIndex) => (
                <p key={subIndex}> <p>
                      Organic farming is agriculture that makes healthy food,
                      healthy soils, healthy plants, and healthy environments a
                      priority, along with crop productivity. Organic farmers
                      use biological fertilizer inputs and management practices
                      such as cover cropping and crop rotation to improve soil
                      quality and build organic soil matter. By increasing the
                      amount of organic matter in the soil, organic farmers
                      enhance the soil’s ability to absorb water, reducing the
                      impacts of drought and flooding. Improving soil organic
                      matter also helps it to absorb and store carbon and other
                      nutrients need to grow healthy crops, which, in turn, are
                      better able to resist insects and diseases.
                    </p>

                    <p>
                      Organic production systems do not use genetically modified
                      (GM) seed, synthetic pesticides or fertilizers. Some of
                      the essential characteristics of organic systems include
                      design and implementation of an organic system plan that
                      describes the practices used in producing crops and
                      livestock products; a detailed recordkeeping system that
                      tracks all products from the field to point of sale; and
                      maintenance of buffer zones to prevent inadvertent
                      contamination by synthetic farm chemicals from adjacent
                      conventional fields.
                    </p> </p>
              ))}
            </section>
          </li>
        ))}
      </ul>
  
      <ul className="accordion-list">
        {[2].map((index) => (
          <li key={index} className={`accordion-item ${activeAccordion === index ? 'open' : ''}`}>
            <div className="accordion-header" onClick={() => toggleAccordion(index)}>
            <b>2 - What does certified organic mean and how is
                    certification regulated?</b>
              <p className='fw-bold'> </p>
              <p className="icon">{activeAccordion === index ? '-' : '+'}</p>
            </div>

            <section className={`content ${activeAccordion === index ? 'show' : ''}`}>
              {[1].slice(0, index).map((subIndex) => (
                <p key={subIndex}><p>The National Organic Program (NOP) develops the rules and regulations for the production, handling, labeling, and enforcement of all USDA organic products. This process, referred to as rulemaking, involves input from the National Organic Standards Board (a Federal Advisory Committee made up of fifteen members of the public) and the public.</p>
               
                <p>The national standard states that organic food must be produced without the use of conventional pesticides, petroleum-based fertilizers, sewage-sludge-based fertilizers, herbicides, genetic enginerring (biotechnology), antibiotics, growth hormones, or irradiation. Animals raised on an organic operation must meet animal health welfare standards, not be fed antibiotics or growth hormones, be fed 100% organic feed, and must be provided access to the outdoors.* Land must have no prohibited substances applied to it for at least three years before the harvest of an organic crop. The NOP states that all farms, ranches, and handling operations that display the “USDA Organic” seal must be certified organic by the state or by a private agency, accredited by the USDA, to ensure the NOP standards are followed.</p>
                <p>To gain organic certification, a farmer (of cropland, pasture or livestock) submits an organic system plan to an accredited certifier each year. This documents how the farmer adheres to NOP standards. Certified organic farms and processing facilities undergo annual inspections to verify that they are meeting the standards. Organic inspectors examine all elements of a farm operation for adherence to the standards and verfiy that the farm is being managed according to the farmer’s organic system plan.</p> </p>
                

                
              ))}
            </section>
          </li>
        ))}
      </ul>
      <ul className="accordion-list">
        {[3].map((index) => (
          <li key={index} className={`accordion-item ${activeAccordion === index ? 'open' : ''}`}>
            <div className="accordion-header" onClick={() => toggleAccordion(index)}>
            <b> 3 - Can GMOs be used in organic products?</b>
              <p></p>
              <p className="icon">{activeAccordion === index ? '-' : '+'}</p>
            </div>

            <section className={`content ${activeAccordion === index ? 'show' : ''}`}>
              {[1].slice(0, index).map((subIndex) => (
                <p key={subIndex}><p>The use of genetic engineering, or genetically modified organisms (GMOs) is prohibited in organic products. This means an organic farmer can’t plant GMO seeds, an organic cow can’t eat GMO alfalfa or corn, and an organic soup producer can’t use any GMO ingredients. To meet the USDA organic regulations, farmers and processors must show they aren’t using GMOs and that they are protecting their products from contact with prohibited substances from farm to table.</p>
                <p>Organic farms use production practices with environmental benefits such as water management practices, no-till or minimum tillage, habitat maintenance for beneficial insects and vertebrates, and biological pest control. These ecologically protective practices contribute to enhanced ecosystem services and benefit water quality, soil health, and biodiversity.</p></p>
              ))}
            </section>
          </li>
        ))}
      </ul>
      <ul className="accordion-list">
        {[4].map((index) => (
          <li key={index} className={`accordion-item ${activeAccordion === index ? 'open' : ''}`}>
            <div className="accordion-header" onClick={() => toggleAccordion(index)}>
            <b> 4 - How does organic farming help the environment?</b>
              <p></p>
              <p className="icon">{activeAccordion === index ? '-' : '+'}</p>
            </div>

            <section className={`content ${activeAccordion === index ? 'show' : ''}`}>
              {[1].slice(0, index).map((subIndex) => (
                <p key={subIndex}><p>Organic farms use production practices with environmental benefits such as water management practices, no-till or minimum tillage, habitat maintenance for beneficial insects and vertebrates, and biological pest control. These ecologically protective practices contribute to enhanced ecosystem services and benefit water quality, soil health, and biodiversity.</p>
                <p>Conventional farming typically uses minimal crop rotations, growing the same single crop year after year on the same land. This practice, known as mono cropping causes the depletion of nutrients and minerals. In order to continue growing crops in this depleted soil, nutrients and minerals must be added back in the form of hydrocarbon based fertilizers and mined minerals such as phosphate. Conventional GM farming is dependent on earth-based non-renewable resources. Monocultures and the resulting poor health open the way for infestations of insects, diseases and weeds. Healthy bio-diverse soil keeps these infestations in check. The lack of biodiversity requires synthetic pesticides and herbicides to be used, further destroying the national soil biology.</p></p>
              ))}
            </section>
          </li>
        ))}
      </ul>
      <ul className="accordion-list">
        {[5].map((index) => (
          <li key={index} className={`accordion-item ${activeAccordion === index ? 'open' : ''}`}>
            <div className="accordion-header" onClick={() => toggleAccordion(index)}>
            <b>5 - How does organic farming improve soil and water quality?</b>
              <p></p>
              <p className="icon">{activeAccordion === index ? '-' : '+'}</p>
            </div>

            <section className={`content ${activeAccordion === index ? 'show' : ''}`}>
              {[1].slice(0, index).map((subIndex) => (
                <p key={subIndex}>
                <p>Using biological forms of fertilizer such as compost, animal manures, and legume cover crops, builds soil organic matter, even when routine tillage is used for weed control. Building soil organic matter increases soil water retention and nurtures more active soil microbial communities that retain nitrogen in the soil longer and transform it into non-leachable gaseous forms. There is a small but telling body of research in the US that suggests that improved soil quality influences the ability of crops to withstand or repel insect attack and plant disease.</p> 
                <p>Organic biological fertilizer sources release their nutrients slowly over time, providing more opportunity for the nitrogen to be digested by soil organisms and taken up by crops before leaching below the root zone. Increased soil organic matter in the soil leads to tighter nutrient cycling and greater water holding capability in organically managed soils, with the result that nitrate leaching from groundwater is about half that of conventionally farmed soils. Recent data from a 12-year study shows that fields under organic management had half the annual nitrate leaching losses than fields under conventional management.</p>
<p>For additional information on this topic, download OFRF’s educational guides.</p>
                    
<img src='https:ofrf.org/wp-content/uploads/2019/10/graphic-how-healthy-soil-reduces-risks-crop-nutrients-400x301.png' className='img-fluid'/>

                </p>
              ))}
            </section>
          </li>
        ))}
      </ul>
      <ul className="accordion-list">
        {[6].map((index) => (
          <li key={index} className={`accordion-item ${activeAccordion === index ? 'open' : ''}`}>
            <div className="accordion-header" onClick={() => toggleAccordion(index)}>
            <b> 6 - What is the current state of certified organic
                    agriculture in the U.S.?</b>
              <p></p>
              <p className="icon">{activeAccordion === index ? '-' : '+'}</p>
            </div>

            <section className={`content ${activeAccordion === index ? 'show' : ''}`}>
              {[1].slice(0, index).map((subIndex) => (
                <p key={subIndex}>
                <p>The results of the 2016 Certified Organic Survey, conducted by the U.S. Department of Agriculture’s (USDA) National Agricultural Statistics Service (NASS), show that sales of certified organic production continued to increase in 2016. U.S. farms and ranches produced and sold $7.6B in certified organic commodities, up 23% from 2015, demonstrating that there is increased demand for organic products and opportunities for growth.</p>
                <p>The number of organic farms increased 11% to 14,217 and the number of certified organic acres increased 15% to 5.0M. California led in both categories, with 2,713 certified farms and 1.1M acres, 21% of total U.S. certified organic land.</p>
                <img src='https://ofrf.org/wp-content/uploads/2019/10/top-states-certified-organic-sales.png' className='img-fluid'/>
                <p>Ten states accounted for three fourths (77%) of 2016 sales. With $2.9B in certified organic commodity sales, California continued to lead the nation, accounting for 38% of the U.S. total.</p>
                <img src='https://ofrf.org/wp-content/uploads/2019/10/top-states-certified-organic-acres.png' className='img-fluid'/>
                <p>Some states with lower volume of sales in 2015 experienced significant increases in 2016.</p>
                <img src='https://ofrf.org/wp-content/uploads/2019/10/number-certified-organic-farms-by-state-600x349.png' className='img-fluid'/>
                <p>Of 2016 sales, 56% was for crops ($4.2B), and 44% for livestock, poultry and related products ($3.4B). Milk and eggs were the top two certified commodities sold in 2016. Milk, valued at $1.4B, was up 18% from 2015, and eggs, at $816M, increase 11% and $0.7B, respectively, followed by broiler chickens valued at $0.4B. Among crops, the top selling commodities were apples, lettuce, and strawberries.</p>
                <a href='#' className='text-decoration-none' >USDA– NASS, Census of Agriculture – 2016 Organic Survey </a>
                </p>
              ))}
            </section>
          </li>
        ))}
      </ul>
      <ul className="accordion-list">
        {[7].map((index) => (
          <li key={index} className={`accordion-item ${activeAccordion === index ? 'open' : ''}`}>
            <div className="accordion-header" onClick={() => toggleAccordion(index)}>
            <b>7 - Are organic yields lower? </b>
              <p></p>
              <p className="icon">{activeAccordion === index ? '-' : '+'}</p>
            </div>

            <section className={`content ${activeAccordion === index ? 'show' : ''}`}>
              {[1].slice(0, index).map((subIndex) => (
                <p key={subIndex}><p>Farm data from USDA producer surveys show organic crop yields may be lower than those of conventional production. The yield differences estimated from USDA farm data are similar to those estimated by comparing USDA’s 2011 Certified Organic Production Survey with USDA’s 2011 Crop Production Report. The yield differences revealed by survey data may be due to the unique problems encountered by organic systems outside of the experimental setting, such as effective weed control.</p>
                <p>However, while organic agriculture may produce lower yields when compared to conventional agriculture, organic farming is often more profitable, delivers more environmental benefits, and is healthier in terms of increased nutritional benefits and reduced dietary pesticide exposure.</p>
                
                </p>
              ))}
            </section>
          </li>
        ))}
      </ul>
      <ul className="accordion-list">
        {[8].map((index) => (
          <li key={index} className={`accordion-item ${activeAccordion === index ? 'open' : ''}`}>
            <div className="accordion-header" onClick={() => toggleAccordion(index)}>
            <b> 8 - Why is it important to invest in organic research?</b>
              <p></p>
              <p className="icon">{activeAccordion === index ? '-' : '+'}</p>
            </div>

            <section className={`content ${activeAccordion === index ? 'show' : ''}`}>
              {[1].slice(0, index).map((subIndex) => (
                <p key={subIndex}>
                  <p>Public funding such as USDA’s Organic Agriculture Research and Extension Initiative (OREI) and private funding from organizations such as OFRF, is critical to training the next generation of scientists and developing new talent. Research delivers valuable information, tools, and resources that help all farmers—both organic and non-organic—increase the environmental and economic sustainability of their operations. The continued growth of organic agriculture requires investment in research, education, and extension programs that provide sound information and assistance to America’s farmers. The results from OFRF’s evaluation of USDA research and 2016 National Organic Research Agenda provide a thorough analysis of organic research to date and current priorities for research based on a national survey of organic farmers.</p>
                </p>
              ))}
            </section>
          </li>
        ))}
      </ul>
      <ul className="accordion-list">
        {[9].map((index) => (
          <li key={index} className={`accordion-item ${activeAccordion === index ? 'open' : ''}`}>
            <div className="accordion-header" onClick={() => toggleAccordion(index)}>
           <b>9 - What is the Organic Agriculture Research Act?</b> 
              <p><b></b></p>
              <p className="icon">{activeAccordion === index ? '-' : '+'}</p>
            </div>

            <section className={`content ${activeAccordion === index ? 'show' : ''}`}>
              {[1].slice(0, index).map((subIndex) => (
                <p key={subIndex}><p>H.R. 2436, the Organic Agriculture Research Act of 2017, was introduced by Representative Chellie Pingree (D-ME) with cosponsors Representative Dan Newhouse (R-WA), and Jimmy Panetta (D-CA). This bipartisan legislation was approved in the 2018 Farm Bill and will provide $395 million for organic agriculture research and education over the next ten years. This milestone is the biggest win for organic farming in the Farm Bill in decades, securing permanent funding for organic research at USDA.</p>
                </p>
              ))}
            </section>
          </li>
        ))}
      </ul>
      <ul className="accordion-list">
        {[10].map((index) => (
          <li key={index} className={`accordion-item ${activeAccordion === index ? 'open' : ''}`}>
            <div className="accordion-header" onClick={() => toggleAccordion(index)}>
            <b>10 - What organic provisions were approved in the 2018 Farm
                    Bill?</b>
              <p> </p>
              <p className="icon">{activeAccordion === index ? '-' : '+'}</p>
            </div>

            <section className={`content ${activeAccordion === index ? 'show' : ''}`}>
              {[1].slice(0, index).map((subIndex) => (
                <p key={subIndex}>
                <img src='https://ofrf.org/wp-content/uploads/2019/10/2018-farm-bill-graphic.jpg' className='img-fluid'/>
                </p>
              ))}
            </section>
          </li>
        ))}
      </ul>
      <ul className="accordion-list">
        {[11].map((index) => (
          <li key={index} className={`accordion-item ${activeAccordion === index ? 'open' : ''}`}>
            <div className="accordion-header" onClick={() => toggleAccordion(index)}>
            <b>11 - Why does organic cost more?</b>
              <p></p>
              <p className="icon">{activeAccordion === index ? '-' : '+'}</p>
            </div>

            <section className={`content ${activeAccordion === index ? 'show' : ''}`}>
              {[1].slice(0, index).map((subIndex) => (
                <p key={subIndex}>
                <p>The cost of organic food is higher than that of conventional food because the organic price tag more closely reflects the true cost of growing the food: substituting labor and intensive management for chemicals. These costs may include cleanup of polluted water and remediation of pesticide contamination.
                </p>
                </p>
              ))}
            </section>
          </li>
        ))}
      </ul>
      <ul className="accordion-list">
        {[12].map((index) => (
          <li key={index} className={`accordion-item ${activeAccordion === index ? 'open' : ''}`}>
            <div className="accordion-header" onClick={() => toggleAccordion(index)}>
            <b> 12 - Where can beginning organic farmers and farmers
                    transitioning to organic get assistance?</b>
              <p></p>
              <p className="icon">{activeAccordion === index ? '-' : '+'}</p>
            </div>

            <section className={`content ${activeAccordion === index ? 'show' : ''}`}>
              {[1].slice(0, index).map((subIndex) => (
                <p key={subIndex}>USDA offers several programs and tools to support the success of organic farmers. The Environmental Quality Incentives Program (EQIP) from USDA Natural Resources Conservation Service (NRCS) helps producers plan and implement conservation practices to support the environmental sustainability of their organic operations.
                </p>
              ))}
            </section>
          </li>
        ))}
      </ul>
      <ul className="accordion-list">
        {[13].map((index) => (
          <li key={index} className={`accordion-item ${activeAccordion === index ? 'open' : ''}`}>
            <div className="accordion-header" onClick={() => toggleAccordion(index)}>
            <b> 13 - How does organic farming help mitigate climate change?</b>
              <p></p>
              <p className="icon">{activeAccordion === index ? '-' : '+'}</p>
            </div>

            <section className={`content ${activeAccordion === index ? 'show' : ''}`}>
              {[1].slice(0, index).map((subIndex) => (
                <p key={subIndex}>
                <p>Climate change poses critical risks for farmers and ranchers and endangers the soil, water, and other resources on which food production depends. Rising temperatures have already intensified droughts, heat waves, and storms, making it harder to grow crops and raise livestock.</p>
                <p>The good news is that organic systems that emphasize soil health help farmers and ranchers increase resilience to the impacts of climate change. There is also extensive research demonstrating the potential of organic systems to reduce agriculture’s contribution to climate change (i.e., mitigate climate change).</p>
                <p>Organic systems do this by capturing and storing more carbon (CO2) in the soil (carbon sequestration).</p>
                <p>While organic systems require some level of physical disturbance to control weeds, they eliminate synthetic inputs and can significantly reduce tillage. Reduced tillage, crop diversification, cover cropping, organic amendments, and sound nutrient management can enhance carbon sequestration and build climate resiliency in organic agricultural systems.</p>
                <p>They also release fewer greenhouse gases.</p>
                <p>Organic farmers do not use synthetic pesticides and fertilizers, one of the primary contributors of greenhouse gases. Healthy soils help crops obtain nitrogen, phosphorus, and other nutrients from organic soil organic matter. This reduces the need for fertilizers that can threaten water quality and minimizes the release of greenhouse gases from soils.</p>
                
                </p>
              ))}
            </section>
          </li>
        ))}
      </ul>
      <ul className="accordion-list">
        {[14].map((index) => (
          <li key={index} className={`accordion-item ${activeAccordion === index ? 'open' : ''}`}>
            <div className="accordion-header" onClick={() => toggleAccordion(index)}>
            <b>14 - How does organic farming increase resilience to climate
                    change?</b>
              <p></p>
              <p className="icon">{activeAccordion === index ? '-' : '+'}</p>
            </div>

            <section className={`content ${activeAccordion === index ? 'show' : ''}`}>
              {[1].slice(0, index).map((subIndex) => (
                <p key={subIndex}>
                <p>Healthy soils form the foundation of organic production. Healthy soils have good structure (tilth), which allows them to absorb and hold moisture, drain well, maintain adequate aeration, and foster deep, healthy crop root systems. Such soils sustain crops through dry spells, require less irrigation water, and undergo less ponding, runoff, and erosion during heavy rains.</p>
              
                <p>The USDA National Organic Standards require certified producers to implement crop rotation, cover cropping, tillage, nutrient management, and other practices that improve and maintain the physical, chemical, and biological condition of the soil.</p>
                </p>
              ))}
            </section>
          </li>
        ))}
      </ul>
    </section>
            
            </div>
          </div>
        </div>
        <center>
        <div className="row accordion_bottom_bg ">
          <div className="col-md-5 ">
            <button className="  ">
              Donate to support Organic Farming
            </button>
          </div>
          <div className="col-md-7">
            <button className="">
              Sign Up to Receive feature News Updates & Action Alerts
            </button>
          </div>
        </div>
        </center>
      </div>
    </div>
  );
};

export default Accordion_FAQ;
