import React, { useEffect } from "react";
import "./Feed.css";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { Link } from "react-router-dom";
import {
  BsSearch,
} from "react-icons/bs";
import { useState } from "react";
import { timeCal } from "../../Utils/DateFormat";
import { isValidHashtag } from "../../Utils/Validation";
import { uploadFile } from "../../Utils/FileUpload";
import { getSingleUser } from "../../api/consumer.js";
import { getconsumerId } from "../../Utils/Storage";
import { createPost, getFilterPost, getAllPost } from "../../api/PostApi";
import { ToastContainer, toast } from "react-toastify";
import OwlCarousel from 'react-owl-carousel';
import { FaEdit, FaEye, FaTrashAlt } from 'react-icons/fa';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { getCategory } from "../../api/category";
const Feed = () => {
  let initialState = {
    consumerId: getconsumerId(),
    modelType: "Seller",
    title: "",
    description: "",
    hashTag: "",
    image: "",
  };
 
  let initialStateErrors = {
    consumerId: {
      required: false,
    },
    modelType: {
      required: false,
    },
    title: {
      required: false,
    },
    description: {
      required: false,
    },
    hashTag: {
      required: false,
      valid: false,
    },
    image: {
      required: false,
    },
  };
  const [inputs, setInputs] = useState(initialState);
  const [errors, setErrors] = useState(initialStateErrors);
  const [submitted, setSubmitted] = useState(false);
  const [consumerId, setconsumerId] = useState();
  const [user, setUser] = useState();
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [post, setPost] = useState([]);
  const [postNext, setPostNext] = useState(0);
  const [modify, setModify] = useState(false);
  const [postDelete, setPostDelete] = useState(false);
  const [count, setCount] = useState(0);
  const [options, setOptions] = useState([]);
  const [scroll, setScroll] = useState(false);
  const [category, setCategory] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const user = getconsumerId();
    setconsumerId(user);
    getUserDetails();
    window.addEventListener("scroll", handleScroll);
    getAllUsersList();
  }, []);
 
  const getUserDetails = () => {
    const data = getconsumerId();
    getSingleUser(data)
      .then((res) => {
        const result = res?.data?.result;
        setUser(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllUsersList = async () => {
    try {
      const postData = await getAllPost();
      const postResult = postData.data.result;
      console.log("Merged data:",postResult);
      setPost(postResult);
      const categoryData = await getCategory();
      const categoryResult = categoryData.data.result;
   console.log("categoryResult",categoryResult)
     setCategory(categoryResult); 
      
     
    } catch (error) {
      console.log(error);
    }
  };
 
  const handleScroll = () => {
    const value =
      window.innerHeight + window.scrollY >=
      document.documentElement.scrollHeight - 200;
    setScroll(value);
  };
  useEffect(() => {
    if (scroll) {
      loadMorePost();
    }
  }, [scroll]);
 
  const loadMorePost = () => {
    let nextPost = postNext;
    if (postDelete) {
      nextPost = nextPost + 4;
      setPostDelete(false);
    } else {
      nextPost = nextPost + 5;
    }
    if (count <= nextPost) {
      setReload(true);
    } else {
      setPostNext(nextPost);
    }
  };
  const handleInputs = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
    if (submitted) {
      const newError = handleValidation({
        ...inputs,
        [event.target.name]: event.target.value,
      });
      setErrors(newError);
    }
  };
 
  const handleValidation = (data) => {
    let error = initialStateErrors;
    if (data.consumerId === "") {
      error.consumerId.required = true;
    }
    if (data.modelType === "") {
      error.modelType.required = true;
    }
    if (data.image === "") {
      error.image.required = true;
    }
 
    if (data.title === "") {
      error.title.required = true;
    }
    if (data.description === "") {
      error.description.required = true;
    }
    if (data?.hashTag !== "" && !isValidHashtag(data.hashTag)) {
      error.hashTag.valid = true;
    }
 
    return error;
  };
 
  const handleErrors = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const prop = obj[key];
        if (prop.required === true || prop.valid === true) {
          return false;
        }
      }
    }
    return true;
  };
  const handleFileChange = (event) => {
    const file = event?.target?.files[0];
    const folder = "pixclick/post";
    if (file) {
      uploadFile(file, folder)
        .then((res) => {
          const image = res?.Location;
          setInputs({ ...inputs, image: image });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const newError = handleValidation(inputs);
    setErrors(newError);
    setSubmitted(true);

    if (handleErrors(newError)) {
      createPost(inputs)
        .then((res) => {
          toast.success(res?.data?.message);
          if(res?.data?.message === "Saved Successfully"){
            navigate("/");  
          }
          
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };

const owloptions = {
  loop: true,
  margin: 50,
  nav: false, // Set nav to false to remove navigation buttons
   
  responsive: {
    0: { items: 1 },
    600: { items: 1 },
    1000: { items: 3 }
  }
};
  return (
    <>
      <div>
        <body className="mt-4 pt-5 TC_BG_4 FONT_1_POPPINS">
                  
          <Header showCreatePostButton={true} />
          <div className="container-fluid px-4 py-3">
         <div className='row'>
               
         </div>
            <div className="row">

              <div className="col-12 col-md-2 SIDE_BAR mt-3 p-3 shadow">
                <p>
 
                  <div
                    className="modal fade "
                    id="exampleModalToggleOne"
                    aria-hidden="true"
                    aria-labelledby="exampleModalToggleLabel"
                    tabIndex="-1"
                  >
                    <div className="modal-dialog modal-md modal-dialog-centered  modal-dialog-scrollable">
                      <div className="modal-content border-0 shadow-lg rounded m-3">
                        <form >
                          <div className="card w-100 border-0 shadow">
                            <div className="container">
                              {/* Profile Image */}
                              <div className="mt-2 card rounded-3">
                                <div className="position-relative">
                                  <label
                                    htmlFor="profileImageInput"
                                    className="profile-image-label align-items-center justify-content-center d-flex"
                                  >
                                    <img
                                      id="fileInput"
                                      src={
                                        inputs?.image
                                          ? inputs?.image
                                          : "https://s3.ap-south-1.amazonaws.com/pixalive.me/empty_profile.png"
                                      }
                                      className="img-fluid card-img-top rounded-3 " // Add card-img-top class here
                                      alt="media"
                                      style={{
                                        cursor: "pointer",
                                        width: "150px",
                                        height: "150px",
                                      }}
                                    />
                                  </label>
                                  <input
                                    type="file"
                                    id="profileImageInput"
                                    onChange={handleFileChange}
                                    style={{ display: "none" }}
                                    accept="image/*"
                                  />
                                  {errors.image?.required ? (
                                    <span className="text-danger form-text profile_error">
                                      This field is required.
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div
                              className="modal-body"
                              style={{ overflowY: "auto" }}
                            >
                              <div className="container-fluid">
                                <div className="mb-3">
                                  <label
                                    htmlFor="exampleInputName"
                                    className="form-label"
                                  >
                                    Title
                                  </label>
                                  <input
                                    type="text"
                                    onChange={handleInputs}
                                    value={inputs?.title}
                                    className="form-control"
                                    id="exampleInputName"
                                    name="title"
                                  />
                                  {errors.title?.required ? (
                                    <span className="text-danger form-text profile_error">
                                      This field is required.
                                    </span>
                                  ) : null}
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="exampleInputName"
                                    className="form-label"
                                  >
                                    Description
                                  </label>
                                  <input
                                    type="text"
                                    onChange={handleInputs}
                                    value={inputs?.description}
                                    className="form-control"
                                    id="exampleInputName"
                                    name="description"
                                  />
                                  {errors.description?.required ? (
                                    <span className="text-danger form-text profile_error">
                                      This field is required.
                                    </span>
                                  ) : null}
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="exampleInputTag"
                                    className="form-label"
                                  >
                                    Hashtag
                                  </label>
                                  <input
                                    type="text"
                                    onChange={handleInputs}
                                    name="hashTag"
                                    value={inputs?.hashTag}
                                    className="form-control"
                                    id="exampleInputTag"
                                  />
                                  {errors?.hashTag?.valid ? (
                                    <span className="text-danger text-left form-text profile_error">
                                      Enter valid hashtag.
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              <div className="d-flex justify-content-end gap-2">
                                <button
                                  className="btn btn-success"
                                  type="submit"
                                  onClick={handleSubmit}
                                >
                                  Save
                                </button>
                                <button
                                  className="btn btn-danger"
                                  type="button"
                                  data-bs-dismiss="modal"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
 
                  {/* product post model +  inputs */}
                  <div
                    className="modal fade "
                    id="exampleModalToggleOne1"
                    aria-hidden="true"
                    aria-labelledby="exampleModalToggleLabel"
                    tabIndex="-1"
                  >
                    <div className="modal-dialog modal-md modal-dialog-centered ">
                      <div className="modal-content border-0 shadow-lg rounded m-3">
                        <form>
                          <div className="card w-100 border-0 shadow">
                            <div className="container">
                              {/* Product Image */}
                              <div className="mt-2 card rounded-3 ">
                                <div className="position-relative">
                                  <label
                                    htmlFor="profileImageInput"
                                    className="profile-image-label align-items-center justify-content-center d-flex"
                                  >
                                    <img
                                      id="fileInput"
                                      src={
                                        inputs?.image
                                          ? inputs?.image
                                          : "https://s3.ap-south-1.amazonaws.com/pixalive.me/empty_profile.png"
                                      }
                                      className="img-fluid card-img-top rounded-5 "
                                      alt="media"
                                      style={{
                                        cursor: "pointer",
                                        width: "150px",
                                        height: "150px",
                                      }}
                                    />
                                  </label>
                                  <input
                                    type="file"
                                    id="profileImageInput"
                                    style={{ display: "none" }}
                                    accept="image/*"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className="modal-body"
                              style={{ overflowY: "auto" }}
                            >
                              <div className="container-fluid">
                                <div className="">
                                  <label
                                    htmlFor="exampleInputName"
                                    className="form-label"
                                  >
                                    Location <i class="bi bi-geo-alt"></i>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="location"
                                  />
                                </div>
                                <div className="">
                                  <label
                                    htmlFor="exampleInputName"
                                    className="form-label mt-3"
                                  >
                                    Title
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="title"
                                  />
                                </div>
 
                                <div className="">
                                  <label
                                    htmlFor="exampleInputName"
                                    className="form-label mt-3"
                                  >
                                    Return Rate
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="Return rate"
                                  />
                                </div>
                                <div className="">
                                  <label
                                    htmlFor="exampleInputName"
                                    className="form-label mt-3"
                                  >
                                    Payout
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="Payout"
                                  />
                                </div>
                                <div className="">
                                  <label
                                    htmlFor="exampleInputName"
                                    className="form-label mt-3"
                                  >
                                    Lock-in
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="Lock-in"
                                  />
                                </div>
                                <div className="">
                                  <label
                                    htmlFor="exampleInputName"
                                    className="form-label mt-3"
                                  >
                                    Investment
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="investment"
                                  />
                                </div>
                              </div>
                              <div className="d-flex justify-content-end gap-2 mt-3">
                                <button
                                  className="btn btn-success"
                                  type="submit"
                                  onClick={handleSubmit}
                                >
                                  Save Product
                                </button>
                                <button
                                  className="btn btn-danger"
                                  type="button"
                                  data-bs-dismiss="modal"
                                >
                                  Cancel Product
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </p>
                <p>
                  <div className="modal-btn d-flex gap-3">
                    <button
                      type="button"
                      className="btn btn-outline-success w-100"
                    >
                      <i class="bi bi-house-door"></i> Feed
                    </button>
                  </div>
                </p>
                <p>
                  <div className="modal-btn d-flex gap-3">
                    <button
                      type="button"
                      className="btn btn-outline-success w-100"
                    >
                      <i class="bi bi-search"></i>Search
                    </button>
                  </div>
                </p>
              </div>
              {/* Feed Area */}
              <div className='col-md-7'>

      {post.map((data, index) => (
        <div key={index} className='POST_AREA mt-3 p-3 shadow'>
        {index % 5 === 0 && (
  
    <><div className='d-flex justify-content-between p-1'>
              <h4>Category</h4>
              <a href='/Home'>See all</a>
            </div><div className='ol-12 d-flex justify-content-evenly py-4 w-100'>
                <OwlCarousel options={owloptions}>
                  {category.map((data) => (
                    <div className='bg-white rounded-3 shadow card card-light p-3' style={{ height: "auto", width: "15rem" }}>
                      <div className='d-flex'>
                        {/* <img src={data.sellerId.profileUrl} className='img-fluid rounded-circle' style={{ height: "3rem", width: "3rem" }} /> */}
                        {/* <span>{data.sellerId.name}</span> */}
                      </div>
                      <p className='pt-1'>Title: {data.title}</p>
                      <img src={data.photo1} className='img-fluid' style={{ height: "10rem", width: "15rem" }} />
                      <p className='mt-1'>Price: {data.price}</p>
                      <p>Description: {data.Description}</p>
                    </div>
                  ))}
                </OwlCarousel>
              </div></>
 
)}

          <span className='d-flex justify-content-between'>
            <div className='d-flex gap-2'>
              <div className='PROFILE_PIC'>
                <img
                  src={data?.consumerId?.profileUrl}
                  alt='Profile'
                  className='img-fluid'
                />
              </div>

              <span className='PROFILE_DETAILS d-flex flex-column'>
                <span className='TC_COL_1 fw-bold'>
                  {data?.consumerId?.name}
                </span>
                <span>{data?.location}{ data?.city} </span>
                <span className='text-secondary'>
                  <i class='bi bi-stopwatch'>
                    {timeCal(data?.createdOn)}
                  </i>
                </span>
              </span>
            </div>

            <div className='dropdown dropdown-action'>
              <span
                className='fs-3 action-icon'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                <i class='bi bi-three-dots-vertical'></i>
              </span>
              <div className='dropdown-menu dropdown-menu-right'>
                <Link
                  className='dropdown-item'
                >
                  <FaEdit className='me-2' />Edit
                </Link>
                <Link
                  className='dropdown-item'
                >
                  <FaEye className='me-2' />View
                </Link>
                <Link
                  className='dropdown-item'
                >
                  <FaTrashAlt className='me-2' />
                  Delete
                </Link>
              </div>
            </div>
          </span>
          <p className='POST_IMG_WIDTH mt-2'>
            <img
              className='feedImage img-fluid'
              src={data?.image}
              alt='FeedImage'
              style={{ width: '100%' }}
            />
          </p>
          <p className='TC_COL_1 fw-bold'>{data?.title}</p>
          <p className='text-secondary'>{data?.hashTag}</p>
          <p className='TC_COL_1 fw-bold'>{data?.description}</p>
        </div>
      ))}
  
  {reload ? (
    <div className='form-text text-danger text-center mb-5'>
      <div class='spinner-border' role='status'>
        <span class='visually-hidden'>Loading...</span>
      </div>
    </div>
  ) : null}
</div>

              {/* upcoming events */}  
              <div className="col-12 col-md-3 EVENTS  p-3 shadow">
                <p className="TC_COL_1 fw-bold">Upcoming Events</p>
                <div className=" p-3 TC_BG_3 rounded-5 ">
                  <span className="TC_COL_1 d-flex fw-bold">Design Talks</span>
                  <span className=" FONT_SIZE_12 text-secondary">
                    12 Oct, 13.00 IST
                  </span>
                  <span className="EVENTS_IMG">
                    <iframe

                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/xFqecEtdGZ0?si=0cWd5RqBq55A1LT4"
                    ></iframe>
                  </span>
                  <span className="TC_COL_1 d-flex mt-2">
                    About 10,000 years ago, humans began to farm. This
                    agricultural revolution was a turning point in our history
                    and enabled the existence of civilization. Today, nearly 40%
                    of our planet is farmland. Spread all over the world, these
                    lands are the pieces to a global puzzle we’re all facing: in
                    the future, how can we feed every member of a growing
                    population a healthy diet? Brent Loken investigates.
                  </span>
                </div>
                <div className="mt-3 p-3 TC_BG_3 rounded-5 ">
                  <span className="TC_COL_1 d-flex fw-bold">Design Talks</span>
                  <span className=" FONT_SIZE_12 text-secondary">
                    12 Oct, 13.00 IST
                  </span>
                  <span className="EVENTS_IMG">
                    <img
                      src="https://www.researchgate.net/publication/335082528/figure/fig5/AS:790037377609729@1565370997979/Photographs-of-the-farms-and-events-from-the-current-Urban-Farming-activities-in.jpg"
                      alt=""
                    />
                  </span>
                  <span className="TC_COL_1 d-flex mt-2">
                    Harvest agricultural products are perishable. If they cannot
                    be sold, there will be serious grain loss and food waste. It
                    is an important issue related to human sustainable
                    development and urgent to address.
                  </span>
                </div>
                <div className="mt-3 p-3 TC_BG_3 rounded-5 ">
                  <span className="TC_COL_1 d-flex fw-bold">Design Talks</span>
                  <span className=" FONT_SIZE_12 text-secondary">
                    12 Oct, 13.00 IST
                  </span>
                  <span className="EVENTS_IMG">
                    <img
                      src="https://www.researchgate.net/publication/335082528/figure/fig5/AS:790037377609729@1565370997979/Photographs-of-the-farms-and-events-from-the-current-Urban-Farming-activities-in.jpg"
                      alt=""
                    />
                  </span>
                  <span className="TC_COL_1 d-flex mt-2">
                    A General talk about design with Sr.Designer of Logitech
                    Michael Spunfix.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </body>
      </div>
    </>
  );
};
 
export default Feed;