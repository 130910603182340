import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";

import 'react-toastify/dist/ReactToastify.css';

import store from './State Mangement/store';
import { Provider } from 'react-redux';
import  {ToastContainer}  from "react-toastify";
ReactDOM.render(
  <Provider store={store}>
    <App />
    <ToastContainer/>
  </Provider>,
  document.getElementById('root')
);
