import React from "react";
import "./Investment_Heading_1.css";
import RIGHT_IMG from "../../Assets/Right_Side_Image.webp";
import Typed from "react-typed";

const Investment_Heading_1 = () => {
  return (
    <>
      <div className="Investment_Heading_1 TC_BG_5">
        <div className="container ">
          <div className="row d-flex align-items-center">
            {/* Left_Container */}
            <div className="col-12 col-lg-6">
              <div className="LEFT_CONTAINER FS_50 mt-3 ">
                <span className="INVEST_TITLE">
                  <span className="TC_COL_1">Your </span>
                  <span className="TC_COL_4">Green </span>
                  <span className="TC_COL_1">Investment Partner</span>
                  <br />
                  <span className="TC_COL_2">
                    #
                    <Typed
                      strings={["Precision Farming", "Agri-Investment"]}
                      typeSpeed={100}
                      loop
                    />
                  </span>{" "}
                </span>
              </div>

              <div className="LEFT_CONTAINER_DESC mt-2">
                <span className="small">
                  Diversify your portfolio with agricultural investments that
                  are safe, reliable and tax-free.
                </span>
              </div>

              <div className="mt-4">
                <a href="/enquiry"
                 className="btn btn-success px-4 p-2 fw-bold"
                >
                  Know More
                </a>
              </div>
            </div>

            {/* Right_Container */}

            <div className="col-12 col-lg-6">
              <div className="RIGHT_DESC_CONTAINER">
                <div className="RIGHT_IMG_CONTAINER">
                  <img src={RIGHT_IMG} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Investment_Heading_1;
