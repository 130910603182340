import React, { useState } from "react";
import "./Cultivation_Process_List.css";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";
import Whats_App_Stick from "../../../Components/Common/Whats_App_Stick/Whats_App_Stick";

const Cultivation_Process_List = () => {
  const [activeTab, setActiveTab] = useState("Tab1");

  const openTab = (tabName) => {
    const tabcontent = document.getElementsByClassName("tabcontent");
    for (let i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    const tablinks = document.getElementsByClassName("tablinks");
    for (let i = 0; i < tablinks.length; i++) {
      tablinks[i].classList.remove("active");
    }
    document.getElementById(tabName).style.display = "block";
    setActiveTab(tabName);
    document.getElementById(tabName + "-tab").classList.add("active");
  };

  // This useEffect ensures that when the component mounts,
  // Tab1 is displayed and marked as active.
  React.useEffect(() => {
    openTab("Tab1");
  }, []);
  return (
    <>
      <Header />
      <div className="cultivation_Process">
        <div className="container mt-4 pt-5">
          <div className="row">
            <div className="col-sm-12 col-lg-12 d-flex justify-content-center  mt-5">
              <h1 className="TC_COL_1 FONT_1_SANS_SERIF TC_BG_3 px-3 rounded-1 text-center">
                Cultivation Process Solution
              </h1>
            </div>
            <div className="col-12 mt-5">
              <div>
                <div className="col-12 change_scroll_content pt-3">
                  <div className="wholecontainertab d-flex justify-content-between ">
                    <span className="mytab_123">
                      <button
                        id="Tab1-tab"
                        className={`tablinks ${
                          activeTab === "Tab1" ? "active" : ""
                        }`}
                        onClick={() => openTab("Tab1")}
                        style={{
                          backgroundColor:
                            activeTab === "Tab1" ? "#024846" : "grey",
                        }}
                      >
                        <span className="text-white  px-2 "> Crops</span>
                      </button>
                    </span>

                    <span className="mytab_123">
                      <button
                        id="Tab2-tab"
                        className={`tablinks ${
                          activeTab === "Tab2" ? "active" : ""
                        }`}
                        onClick={() => openTab("Tab2")}
                        style={{
                          backgroundColor:
                            activeTab === "Tab2" ? "#024846" : "grey",
                        }}
                      >
                        <span className="text-white  ">Vegetables</span>
                      </button>
                    </span>

                    <span className="mytab_123">
                      <button
                        id="Tab3-tab"
                        className={`tablinks ${
                          activeTab === "Tab3" ? "active" : ""
                        }`}
                        onClick={() => openTab("Tab3")}
                        style={{
                          backgroundColor:
                            activeTab === "Tab3" ? "#024846" : "grey",
                        }}
                      >
                        <span className="text-white  ">Flowers</span>
                      </button>
                    </span>

                    <span className="mytab_123">
                      <button
                        id="Tab4-tab"
                        className={`tablinks ${
                          activeTab === "Tab4" ? "active" : ""
                        }`}
                        onClick={() => openTab("Tab4")}
                        style={{
                          backgroundColor:
                            activeTab === "Tab4" ? "#024846" : "grey",
                        }}
                      >
                        <span className="text-white  "> Fruits</span>
                      </button>
                    </span>
                  </div>
                </div>

                {/* start of tab contents */}
                <div className="container">
                  {/* tabs content 1 */}
                  <div id="Tab1" className="tabcontent ">
                    <div class="container ">
                     

                      <div class="row  mx-auto mb-3">
                      <div class="col-lg-3 col-6 button_response  d-flex justify-content-between mb-4">
                          <div
                            class="card mt-5 mx-auto  p-0 d-flex align-items-stretch flex-column"
                            style={{ width: "15rem" }}
                          >
                            <img
                              src="https://images.pexels.com/photos/2589457/pexels-photo-2589457.jpeg?auto=compress&cs=tinysrgb&w=600"
                              class="card-img-top img-fluid"
                              alt="..."
                            />
                            <div class="card-body">
                            <h5 class="card-title">Rice Cultivation</h5>
                              <p class="card-text">
                                Rice, (Oryza sativa), edible starchy cereal
                                grain and the grass plant (family Poaceae) by
                                which it is produced.
                              </p>
                             
                            </div>
                            <div className="m-2">
                            <a href="#" className="btn w-100 btn-success text-wrap button_response">
                                Read Details
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-6 button_response  d-flex justify-content-between mb-4">
                          <div
                            class="card mt-5 mx-auto  p-0 d-flex align-items-stretch flex-column"
                            style={{ width: "15rem" }}
                          >
                            <img
                              src="https://images.pexels.com/photos/265216/pexels-photo-265216.jpeg?auto=compress&cs=tinysrgb&w=600"
                              class="card-img-top img-fluid"
                              alt="..."
                            />
                            <div class="card-body">
                              <h5 class="card-title">Wheat Cultivation</h5>
                              <p class="card-text">
                                Wheat was one of the very first crops
                                domesticated by human beings in the Neolithic
                                Age.
                              </p>
                             
                            </div>
                            <div className="m-2">
                            <a href="#" className="btn w-100 btn-success text-wrap">
                                Read Details
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 mb-4 col-6  button_response d-flex justify-content-between">
                          <div
                            class="card mt-5 mx-auto  p-0 d-flex align-items-stretch flex-column"
                            style={{ width: "15rem" }}
                          >
                            <img
                              src="https://images.pexels.com/photos/17362930/pexels-photo-17362930/free-photo-of-landscape-image-of-jutes.jpeg?auto=compress&cs=tinysrgb&w=600"
                              class="card-img-top img-fluid"
                              alt="..."
                            />
                            <div class="card-body d-flex align-items-stretch flex-column">
                              <h5 class="card-title">Jute Cultivation</h5>
                              <p class="card-text">
                                Jute is one of the most important natural fibers
                                after cotton in terms of cultivation and usage.
                              </p>
                             
                            </div>
                            <div className="m-2">
                              <a href="#" className="btn w-100 btn-success text-wrap py-2">
                                Read Details
                              </a>
                              </div>
                          </div>
                        </div>
                        <div class="col-lg-3 mb-4 col-6  button_response d-flex justify-content-between">
                          <div
                            class="card mt-5 mx-auto  p-0 d-flex align-items-stretch flex-column"
                            style={{ width: "15rem" }}
                          >
                            <img
                              src="https://images.pexels.com/photos/5029646/pexels-photo-5029646.jpeg?auto=compress&cs=tinysrgb&w=600"
                              class="card-img-top img-fluid"
                              alt="..."
                            />
                            <div class="card-body d-flex align-items-stretch flex-column">
                              <h5 class="card-title">Maize Cultivation</h5>
                              <p class="card-text">
                                Maize is the most versatile emerging crop
                                shaving wider adaptability under varied
                                agro-climatic conditions.
                              </p>
                             
                            </div>
                            <div className="m-2">
                              <a href="#" className="btn w-100 btn-success text-wrap py-2">
                                Read Details
                              </a>
                              </div>
                          </div>
                        </div>
                      </div>
                      <div className="row  mx-auto mb-3">
                        <div class="col-lg-3 mb-4 col-6 button_response d-flex justify-content-between">
                          <div
                            class="card mt-5 mx-auto  p-0"
                            style={{ width: "15rem" }}
                          >
                            <img
                              src="https://images.pexels.com/photos/18259648/pexels-photo-18259648/free-photo-of-pea-leaves-in-garden.jpeg?auto=compress&cs=tinysrgb&w=600"
                              class="card-img-top img-fluid"
                              alt="..."
                            />
                            <div class="card-body">
                              <h5 class="card-title">Peas Cultivation</h5>
                              <p class="card-text">
                                In India it is cultivated in Himachal Pradesh,
                                Madhya Pradesh, Rajasthan, Punjab, Haryana and
                                Bihar.
                              </p>
                             
                            </div>
                            <div className="m-2">
                            <a href="#" className="btn btn-block btn-success text-wrap ">
                                Read Details
                              </a>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* tabs content 2 */}

                  <div id="Tab2" className="tabcontent">
                    <div className="container">
                      <div className="row mx-auto">
                        <div className="col-md-3 col-6 button_response d-flex justify-content-between ">
                          <div
                            class="card mt-5 mx-auto  p-0 d-flex align-items-stretch flex-column"
                            style={{ width: "15rem" }}
                          >
                            <img
                              src="https://images.pexels.com/photos/7745029/pexels-photo-7745029.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
                              class=" img-fluid"
                              alt="..."
                            />
                            <div class="card-body">
                              <h5 class="card-title">Tomato Cultivation</h5>
                              <p class="card-text">
                                Tomato is a warm season crop. The best fruit
                                colour and quality is obtained at a temperature
                                of 21-24°C.
                              </p>
                             
                            </div>
                            <div className="m-2">
                            <a href="#" className="btn w-100 btn-success text-wrap button_response">
                                Read Details
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 col-6 button_response d-flex justify-content-between ">
                          <div
                            class="card mt-5 mx-auto  p-0"
                            style={{ width: "15rem" }}
                          >
                            <img
                              src="https://images.pexels.com/photos/10041322/pexels-photo-10041322.jpeg?auto=compress&cs=tinysrgb&w=600"
                              class="card-img-top img-fluid"
                              alt="..."
                            />
                            <div class="card-body">
                              <h5 class="card-title">Onion Cultivation</h5>
                              <p class="card-text">
                                It is mainly grown irrigated crop. Frequency
                                irrigation depends upon climatic conditions and
                                soil type.
                              </p>
                             
                            </div>
                            <div className="m-2">
                            <a href="#" className="btn w-100 btn-success text-wrap button_response">
                                Read Details
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 col-6 button_response d-flex justify-content-between ">
                          <div
                            class="card mt-5 mx-auto  p-0 d-flex align-items-stretch flex-column"
                            style={{ width: "15rem" }}
                          >
                            <img
                              src="https://images.pexels.com/photos/209414/pexels-photo-209414.jpeg?auto=compress&cs=tinysrgb&w=600"
                              class="card-img-top img-fluid"
                              alt="..."
                            />
                            <div class="card-body">
                              <h5 class="card-title">Pumpkin Cultivation</h5>
                              <p class="card-text">
                                Pumpkin is rich in vitamins. Check complete
                                information for its farming in Punjab such as
                                plant protection.
                              </p>
                             
                            </div>
                            <div className="m-2">
                            <a href="#" className="btn w-100 btn-success text-wrap button_response">
                                Read Details
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 col-6 button_response d-flex justify-content-between">
                          <div
                            class="card mt-5 mx-auto  p-0 d-flex align-items-stretch flex-column"
                            style={{ width: "15rem" }}
                          >
                            <img
                              src="https://images.pexels.com/photos/10584533/pexels-photo-10584533.jpeg?auto=compress&cs=tinysrgb&w=600"
                              class="card-img-top img-fluid"
                              alt="..."
                            />
                            <div class="card-body">
                              <h5 class="card-title">Cabbage Cultivation</h5>
                              <p class="card-text">
                                Cultivation of cabbage is done mainly on sandy
                                to heavy soils rich in organic matter.
                              </p>
                             
                            </div>
                            <div className="m-2">
                            <a href="#" className="btn w-100 btn-success text-wrap button_response">
                                Read Details
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* second row */}
                      <div className="row mx-auto mb-3">
                        <div className="col-md-3 col-6 button_response d-flex justify-content-between  ">
                          <div
                            class="card mt-5 mx-auto  p-0 d-flex align-items-stretch flex-column"
                            style={{ width: "15rem" }}
                          >
                            <img
                              src="https://images.pexels.com/photos/10041306/pexels-photo-10041306.jpeg?auto=compress&cs=tinysrgb&w=600"
                              class="card-img-top img-fluid"
                              alt="..."
                            />
                            <div class="card-body">
                              <h5 class="card-title">Carrot Cultivation</h5>
                              <p class="card-text">
                                Carrot is a cool season crop and will develop a
                                good colour when grown at 15°C to 20°C.
                              </p>
                             
                            </div>
                            <div className="m-2">
                            <a href="#" className="btn w-100 btn-success text-wrap button_response">
                                Read Details
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* tabs content 3 */}
                  <div id="Tab3" className="tabcontent">
                    <div className="container">
                      <div className="row mx-auto">
                        <div className="col-md-3 col-6 button_response d-flex justify-content-between">
                          <div
                            class="card mt-5 mx-auto  p-0 d-flex align-items-stretch flex-column"
                            style={{ width: "15rem" }}
                          >
                            <img
                              src="https://images.pexels.com/photos/244796/pexels-photo-244796.jpeg?auto=compress&cs=tinysrgb&w=600"
                              class=" img-fluid"
                              alt="..."
                            />
                            <div class="card-body">
                              <h5 class="card-title">Hibiscus Cultivation</h5>
                              <p class="card-text">
                                Small-growing varieties of rose hibiscus are
                                suitable for cultivation in pots. it can also be
                                placed in a sunny location
                              </p>
                             
                            </div>
                            <div className="m-2">
                            <a href="#" className="btn w-100 btn-success text-wrap button_response">
                                Read Details
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 col-6 button_response d-flex justify-content-between">
                          <div
                            class="card mt-5 mx-auto  p-0 d-flex align-items-stretch flex-column"
                            style={{ width: "15rem" }}
                          >
                            <img
                              src="https://images.pexels.com/photos/6166676/pexels-photo-6166676.jpeg?auto=compress&cs=tinysrgb&w=600"
                              class="card-img-top img-fluid"
                              alt="..."
                            />
                            <div class="card-body">
                              <h5 class="card-title">Jasmine Cultivation</h5>
                              <p class="card-text">
                                Jasmine can be planted on a wide range of soils.
                                Well-drained sandy loams and red loams under
                                tropical...
                              </p>
                             
                            </div>
                            <div className="m-2">
                            <a href="#" className="btn w-100 btn-success text-wrap button_response">
                                Read Details
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 col-6 button_response d-flex justify-content-between">
                          <div
                            class="card mt-5 mx-auto  p-0 d-flex align-items-stretch flex-column"
                            style={{ width: "15rem" }}
                          >
                            <img
                              src="https://images.pexels.com/photos/3524048/pexels-photo-3524048.jpeg?auto=compress&cs=tinysrgb&w=600"
                              class="card-img-top img-fluid"
                              alt="..."
                            />
                            <div class="card-body">
                              <h5 class="card-title">Marigold Cultivation</h5>
                              <p class="card-text">
                                Marigold is a common grown flower of India. It
                                is very important flower, as it extensively...
                              </p>
                              
                            </div>
                            <div className="m-2">
                            <a href="#" className="btn w-100 btn-success text-wrap button_response">
                                Read Details
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 col-6 button_response d-flex justify-content-between">
                          <div
                            class="card mt-5 mx-auto  p-0 d-flex align-items-stretch flex-column"
                            style={{ width: "15rem" }}
                          >
                            <img
                              src="https://images.pexels.com/photos/1831489/pexels-photo-1831489.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                              class="card-img-top img-fluid"
                              alt="..."
                            />
                            <div class="card-body">
                              <h5 class="card-title">Rose Cultivation</h5>
                              <p class="card-text">
                                Rose cultivation on How to grow good quality
                                rose flowers. Learn about various varieties...
                              </p>
                             
                            </div>
                            <div className="m-2">
                            <a href="#" className="btn w-100 btn-success text-wrap button_response">
                                Read Details
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* second row */}
                      <div className="row mx-auto mb-3">
                        <div className="col-md-3 col-6 button_response d-flex justify-content-between ">
                          <div
                            class="card mt-5 mx-auto  p-0 d-flex align-items-stretch flex-column"
                            style={{ width: "15rem" }}
                          >
                            <img
                              src="https://images.pexels.com/photos/63609/plumeria-flower-frangipani-plant-63609.jpeg?auto=compress&cs=tinysrgb&w=600"
                              class="card-img-top img-fluid"
                              alt="..."
                            />
                            <div class="card-body">
                              <h5 class="card-title">Frangipani Cultivation</h5>
                              <p class="card-text">
                                Frangipanis thrive in well-drained soil, plenty
                                of sun and free conditions.love growing by beach
                                in soils.
                              </p>
                             
                            </div>
                            <div className="m-2">
                            <a href="#" className="btn w-100 btn-success text-wrap button_response">
                                Read Details
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* tabs content 4 */}
                  <div id="Tab4" className="tabcontent">
                    <div className="container">
                      <div className="row mx-auto">
                        <div className="col-md-3 col-6 button_response d-flex justify-content-between ">
                          <div
                            class="card mt-5 mx-auto  p-0 d-flex align-items-stretch flex-column"
                            style={{ width: "15rem" }}
                          >
                            <img
                              src="https://images.pexels.com/photos/15914440/pexels-photo-15914440/free-photo-of-photo-of-a-green-banana-plant.jpeg?auto=compress&cs=tinysrgb&w=600"
                              class=" img-fluid"
                              alt="..."
                            />
                            <div class="card-body">
                              <h5 class="card-title">Banana Cultivation</h5>
                              <p class="card-text">
                                Banana being a shallow rooted crop requires
                                proper propping with bamboo or casurina poles to
                                avoid
                              </p>
                              
                            </div>
                            <div className="m-2">
                            <a href="#" className="btn w-100 btn-success text-wrap button_response">
                                Read Details
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 col-6 button_response d-flex justify-content-between">
                          <div
                            class="card mt-5 mx-auto  p-0 d-flex align-items-stretch flex-column"
                            style={{ width: "15rem" }}
                          >
                            <img
                              src="https://images.pexels.com/photos/6166835/pexels-photo-6166835.jpeg?auto=compress&cs=tinysrgb&w=600"
                              class="card-img-top img-fluid"
                              alt="..."
                            />
                            <div class="card-body">
                              <h5 class="card-title">Mango Cultivation</h5>
                              <p class="card-text">
                                Mango fruit is utilised at all stages of its
                                development both in its immature and mature
                                state.
                              </p>
                             
                            </div>
                            <div className="m-2">
                            <a href="#" className="btn w-100 btn-success text-wrap button_response">
                                Read Details
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 col-6 button_response d-flex justify-content-between">
                          <div
                            class="card mt-5 mx-auto  p-0 d-flex align-items-stretch flex-column"
                            style={{ width: "15rem" }}
                          >
                            <img
                              src="https://images.pexels.com/photos/12628702/pexels-photo-12628702.jpeg?auto=compress&cs=tinysrgb&w=600"
                              class="card-img-top img-fluid"
                              alt="..."
                            />
                            <div class="card-body">
                              <h5 class="card-title">Pineapple Cultivation</h5>
                              <p class="card-text">
                                Pineapple (Ananas comosus) is one of the
                                commercially important fruit crops of India.
                              </p>
                             
                            </div>
                            <div className="m-2">
                            <a href="#" className="btn w-100 btn-success text-wrap button_response">
                                Read Details
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 col-6 button_response d-flex justify-content-between">
                          <div
                            class="card mt-5 mx-auto  p-0 d-flex align-items-stretch flex-column"
                            style={{ width: "15rem" }}
                          >
                            <img
                              src="https://images.pexels.com/photos/6828150/pexels-photo-6828150.jpeg?auto=compress&cs=tinysrgb&w=600"
                              class="card-img-top img-fluid"
                              alt="..."
                            />
                            <div class="card-body">
                              <h5 class="card-title">Grapes Cultivation</h5>
                              <p class="card-text">
                                Grape is usually propagated by hard wood
                                cuttings, though propagation by seed..
                              </p>
                            
                            </div>
                            <div className="m-2">
                            <a href="#" className="btn w-100 btn-success text-wrap button_response">
                                Read Details
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* second row */}
                      <div className="row mx-auto mb-3">
                        <div className="col-md-3 col-6 button_response d-flex justify-content-between">
                          <div
                            class="card mt-5 mx-auto  p-0 d-flex align-items-stretch flex-column"
                            style={{ width: "15rem" }}
                          >
                            <img
                              src="https://images.pexels.com/photos/10450721/pexels-photo-10450721.jpeg?auto=compress&cs=tinysrgb&w=600"
                              class="card-img-top img-fluid"
                              alt="..."
                            />
                            <div class="card-body">
                              <h5 class="card-title">Guava Cultivation</h5>
                              <p class="card-text">
                                Guava is a common tropical fruit. Check complete
                                information on cultivation, irrigation,
                                harvesting ...
                              </p>
                             
                            </div>
                            <div className="m-2">
                            <a href="#" className="btn w-100 btn-success text-wrap button_response">
                                Read Details
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* end of tab contents */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Whats_App_Stick />
      <Footer />
    </>
  );
};

export default Cultivation_Process_List;
